import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
// Global services
import { RequestService } from '../../request/request.service';
@Injectable({
  providedIn: 'root'
})
@Injectable()
export class SessionService {
  token: string;
  role: string;
  userroles: string;
  users: string;
  usersstations: string;
  userId: string;
  userName: string;
  stationId: string;
  refreshtoken: string;
  accesscontrol: string;
  userdata: string;
  constructor(private router: Router, private requestService: RequestService) {
    this.token = localStorage.getItem('token');
    this.userId = localStorage.getItem('userId');
    this.refreshtoken = localStorage.getItem('refreshtoken');
  }

  public  inSession(): boolean {
    
    if(localStorage.getItem('token') === null) {
      this.destroy();
      return false;
    }
    if(this.token !== 'null' && this.token !== null && this.token !== undefined&& localStorage.getItem("token") !== null) {
      return true;
    }
    return false;
    
  }

 public  getuser(){
    var iduser= this.userId;
    let relation={
      "include": [
        {
          "relation": "userRoles",
          "scope": {
            "include": [
              {"relation": "role"}
            
            ]
        }
      }, {
        "relation": "userStations"
      }
    ]
  };
    if(this.token!=""&&iduser!=""){
      try{
      const urltoken='Bearer '+this.token;
      var collection="users";
      this.requestService.updateHeaders('Authorization',urltoken);
      
      return new Promise((resolve,reject)=>{
      this.get(iduser,collection,relation).subscribe( response => {
        if(response.success === undefined){
          return resolve(response);
        }
        
      })
        }).then(function(data){
          if(data["username"]){
            data["success"]=true;
          }
        return data;
        });
    }catch(e){
      this.destroy();
      return false;
    }
    }
  }
  public async create(credentials: any): Promise<void> {   
    // Set token
    localStorage.setItem('token', credentials.token);
    localStorage.setItem('userId', credentials.id);
    localStorage.setItem('refreshtoken', credentials.refreshToken);
    this.token = credentials.token;
     const urltoken='Bearer '+this.token;
     this.requestService.updateHeaders('Authorization',urltoken);
     var rol=[];
     for(var i in credentials.roles){
      if(!rol.includes(credentials.roles[i].name)){
        rol.push(credentials.roles[i].name);
      }
    }
    this.userId = credentials.id;
    this.refreshtoken = credentials.refreshToken;
    if(credentials.accessControl==false&&!rol.includes("SUPERADMIN")){
      this.router.navigate(['/dashboard/chagepassword']);
    }else{
    this.router.navigate(['/dashboard']);  
    }
    
  }

  public destroy(): void {
    this.token = null;
    this.refreshtoken = '';
    this.role = '';
    this.userId = '';
    this.userName = '';
    this.stationId = '';
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('stationId');
    localStorage.removeItem('refreshtoken');
    localStorage.removeItem('accessControl');
    localStorage.removeItem('datauser');
    localStorage.removeItem('allRoles');
    // Remove authentication
    this.requestService.removeHeader('Authorization');
    this.requestService.removeHeader('x-app');
    //- 
    this.router.navigate(['/login']);
  }

  public getToken(): string {
    return this.token;
  }
  getDataFormatear(data){
    var roles=[];
    for(var i in data.userRoles){
      roles.push(data.userRoles[i].role);
    }
    data.userRoles=roles;
    return data;
  }
    get(id: string, action = '', filter = undefined): Observable<any> {
      action = action+`/${id}`;
    if (filter) {
      filter = JSON.stringify(filter);
      filter = encodeURIComponent(filter);
      action = `${action}?filter=${filter}`;
    }
    return this.requestService.execute(action, {}, 'GET');
  }
  getUserData(id,collection){
  
    return new Promise((resolve,reject)=>{
      let relation={
        "include": [
          {
            "relation": "userRoles",
            "scope": {
              "include": [
                {"relation": "role"}
              
              ]
          }
        }, {
          "relation": "userStations"
        }
      ]
    };
    this.get(id,collection,relation).subscribe( response => {
      if(response.success === undefined){
        return resolve(response);
      }
      
    })
  }).then(function(data){
   return data;
  })
  }
}
