<div>
  <a  class="plus" title="Agregar Usuarios" routerLink="/dashboard/users/add">
    <i class="icon-plus"></i>
  </a>
</div>
<table #table>
  <thead>
    <tr>
      <th class="text-center">Nombre</th>
      <th class="text-center">Email</th>
      <th class="text-center">Rol o roles</th>
      <th class="text-center">Estaciones Asociadas</th>
      <th class="text-center">Fecha de Alta</th>
      <th class="text-center" >Grupos privados</th>
      <th class="text-center">Editar</th>
      <th class="text-center">Habilitar / Deshabilitar</th>
    </tr>
  </thead>
  <tbody>
    <tr  *ngFor="let user of listUsers;let indice=index">
      <td class="text-center"><p>{{user.username}}</p></td>
      <td class="text-center">{{user.email}}</td>
      <td><cms-gulf-roles [listrole]="user.rol"></cms-gulf-roles></td>
      <td class="text-center edit " *ngIf="user.stations&&user.stations.length>0; else nostations" (click)="liststations(user.stations)"> <a class="w-100 link">
        <p >Estaciones</p>
      </a></td>
      <ng-template #nostations><td  class="text-center data-none">Sin estaciones</td></ng-template>
      <td class="text-center">{{user.registrationDate}}</td>
      <td class="text-center edit" *ngIf="userId==user.id&&userrole==true&&listgruopuser.length>0; else elsegroup"><a class="w-100 link" routerLink="/dashboard/users/groups/list/">
        <p  >Ver Grupos</p>
      </a>
    </td>
      <ng-template #elsegroup ><td  class="text-center edit" *ngIf="userId==user.id;else nonegroup"><a class="w-100 link" routerLink="/dashboard/users/group">Crear grupo</a></td></ng-template>
      <ng-template #nonegroup ><td  class="text-center data-none">Sin grupo</td></ng-template>
      <td class="text-center">
        <div class="edit d-flex">
          <a  class="icon-edit m-auto"  routerLink="/dashboard/users/update/{{user.id}}">
          </a>
        </div>
      </td>
      <td class="text-center">
        <div class="form-group">
          <input type="button" *ngIf="user.status==true; else elsestatus;" id="users-{{indice}}" readonly="readonly" (click)="chagestatus(user.id,user.status)" class="status-inactive" value="Deshabilitar">
          <ng-template #elsestatus><input type="button"  id="users-{{indice}}" readonly="readonly" (click)="chagestatus(user.id,user.status)" class="status-active" value="Habilitar"> </ng-template>
        </div>
        
      </td>
    </tr>
  </tbody>
</table>
