
  <div class="filter" [ngClass]="{ show: status, close: close }">
    <form action="" [formGroup]="frmFilters" autocomplete="off" (ngSubmit)="searchvalue()">
      <fieldset>
        <legend>Búsqueda por nombre de sucursal</legend>
        <ul class="row">
          <li class="col-6">
            <label for="txtName">Nombre de la sucursal</label>
            <input type="text" name="name" id="txtName" formControlName="name">
          </li>
          <li class="col-6">
            <p>
              <a class="btn-default clear" (click)="clearsearch()">Limpiar</a>
              <span [class.loading]="sending">
                <button type="submit" class="btn-default">
                  Buscar
                </button>
              </span>
            </p>
          </li>
        </ul>
      </fieldset>
    </form>
  </div>
  
  <table #table>
    <thead>
      <tr >
        <th class="text-center">Sucursal</th>
        <th class="text-center">Estaciones</th>
        <th class="text-center">Puntos de venta</th>
        <th class="text-center">Sincronizar</th>
        <th class="text-center">Acciones</th>
      </tr>
    </thead>
    <tbody *ngIf="stores.length>0">
      <tr  *ngFor="let item of stores | paginate: { itemsPerPage: 10, currentPage: page }">
        <td class="text-center" *ngIf="item.details; else withoutStore">{{item.details.name}}</td>
        <ng-template #withoutStore>
          <td class="text-center data-none" > 
            Sin sucursal
          </td>
        </ng-template>
        <td class="text-center">{{item.station.name}}</td>        
        <td class="text-center data-none" *ngIf="!item.store.posMercadoPagos; else showpos" >Sin caja asignada</td>
        <ng-template #showpos>
          <td class="text-center edit">
            <a class="w-100 link" routerLink="/dashboard/store/details-pos/{{item.store.id}}">
              <p >Ver cajas</p>
            </a>
          </td>
        </ng-template>        
        <td class="text-center" *ngIf="!item.sync; else syncronizado" >
          <span   [class.loading]="sendingSync">
            <button   (click)="syncStation(item.store.id)" class="status-active"  >Sincronizar empleados</button>   
          </span>
        </td>
        <ng-template #syncronizado>
          <td class="text-center">
            <p>Sincronizado</p>
          </td>
        </ng-template>
        
        <td class="text-center">
          <div class="edit d-flex-discount" >
            <a  class="icon-edit " routerLink="/dashboard/store/add-edit/{{item.store.id}}" > </a>
            <a  class="icon-delete " (click)="deletestore(item.store.id,item.store.posMercadoPagos,item.details.name)"> </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
<pagination-template #p="paginationApi"(pageChange)="page = $event" *ngIf="stores.length>0">
    <nav class="pagination" aria-label="Paginación de la tabla">
      <ul>
        <li class="prev" > 
          <a *ngIf="!p.isFirstPage()"  (click)="p.previous()">
            <span class="icon-prev" [class.disabled]="p.isFirstPage()"></span>
          </a>
        </li>
        <li class="page">
          <a class="disabled" >{{page}}</a>
        </li>
        <li class="next">
          <a *ngIf="!p.isLastPage(); else showlast"  (click)="p.next()">
            <span class="icon-next" [class.disabled]="p.isLastPage()"></span>
          </a>
          <ng-template #showlast>
            <a class="link-off">
              <span class="icon-next " ></span>
            </a>
          </ng-template>
        </li>
      </ul>
    </nav>
</pagination-template>