import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Access guard
import { AccessGuard } from '../../utils/access/access.guard';
// Componentes
import { DashboardComponent } from './dashboard.component';
import { HomeComponent } from './home/home.component';

// Users
import { UsersComponent } from './users/users.component';
import { AddEditComponent } from './users/add-edit/add-edit.component';
import { AddEditStationsComponent } from './group-stations/add-edit-stations/add-edit-stations.component';
import { ListGroupComponent } from './users/list-group/list-group.component';

// Groups Stations
import { GroupStationsComponent } from './group-stations/group-stations.component';

// Groups Discounts
import { GroupDiscountComponent } from './group-discount/group-discount.component';
import { AddEditDiscountComponent } from './group-discount/add-edit-discount/add-edit-discount.component';

//changepassword
import {ChangepasswordComponent} from './changepassword/changepassword.component';
//groups-user
import {GroupComponent} from './users/group/group.component';
import {EditGroupComponent} from './users/edit-group/edit-group.component';
import { FilterUserb2cComponent } from './group-discount/filter-userb2c/filter-userb2c.component';
import { ListUserb2cComponent } from './group-discount/list-userb2c/list-userb2c.component';
import { UploadUserb2cFileComponent } from './group-discount/upload-userb2c-file/upload-userb2c-file.component';
import { RemoveruserstationsComponent } from './group-stations/removeruserstations/removeruserstations.component';
import { StoreComponent } from './store/store.component';
import { AddEditStoreComponent } from './store/add-edit-store/add-edit-store.component';
import { ListStationsComponent } from './store/list-stations/list-stations.component';
import { ListRegisterComponent } from './store/list-register/list-register.component';
import { StoreEstationsComponent } from './store-estations/store-estations.component'; 
import { PaymentsOrdersComponent } from './payments-orders/payments-orders.component'; 
import { DetailPaymentsComponent } from './detail-payments/detail-payments.component'; 
import {DetailsPosComponent  } from './details-pos/details-pos.component';
import {ListEmployeesComponent} from './list-employees/list-employees.component';
const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivateChild: [AccessGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'chagepassword',
        component: ChangepasswordComponent
      },
      {
        path: 'users/add',
        component: AddEditComponent
      },
      {
        path: 'users/update/:id',
        component: AddEditComponent
      },
      {
        path: 'users/group/:id',
        component: GroupComponent
      },
      {
        path: 'users/group',
        component: GroupComponent
      },
      {
        path: 'users/groups/list',
        component: ListGroupComponent
      },
      {
        path: 'users/edit/groups/:id',
        component: EditGroupComponent
      },
      {
        path: 'group-stations',
        component: GroupStationsComponent
      },
      {
        path: 'group-stations/update/:id',
        component: AddEditStationsComponent
      },
      {
        path: 'group-stations/deleteuser/:id',
        component: RemoveruserstationsComponent
      },
      {
        path: 'group-stations/add',
        component: AddEditStationsComponent
      },
      {
        path: 'group-discount',
        component: GroupDiscountComponent
      },
      {
        path: 'group-discount/add',
        component: AddEditDiscountComponent
      },
      {
        path: 'group-discount/update/:id',
        component: AddEditDiscountComponent
      },
      {
        path: 'group-userb2c/:id',
        component: FilterUserb2cComponent
      },
      {
        path: 'group-discount/list-userb2c/:id',
        component: ListUserb2cComponent
      },
      {
        path: 'group-discount/upload-userb2c/:id',
        component: UploadUserb2cFileComponent
      },
      {
        path: 'stations',
        children: [
          { path: '', component: StoreEstationsComponent},
          { path: 'stations-available', component: ListStationsComponent},
          { path: 'register', component: ListRegisterComponent},
          { path: 'add', component: AddEditStoreComponent},
          { path: 'add/:id', component: AddEditStoreComponent},
        ]
      },
      {
        path: 'store',
        component: StoreComponent,
      },
      {
        path: 'store/add-edit/:id',
        component: AddEditStoreComponent,
      },
      {
        path: 'store/details-pos/:id',
        component: DetailsPosComponent,
      },
      {
        path: 'orders',
        component: PaymentsOrdersComponent,
      },
      {
        path: 'orders/detail-order/:id',
        component: DetailPaymentsComponent,
      },
      {
        path: 'employees',
        component: ListEmployeesComponent,
      },
    
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
