<section class="dashboard-info">
  <a class='dashboard-control mr-5 ' routerLink="/dashboard/group-stations">
    <div class='control-icon bg-green'>
      <i class="icon-home"></i>
    </div>
    <div class='control-label color-grey'>Grupo de Estaciones(GE)</div>
    <span class='txt-number color-green mt-lap'>{{groupsstations}}</span>
  </a>

  <a class='dashboard-control mr-5' routerLink="/dashboard/group-discount">
    <div class='control-icon bg-rosa'>
      <i class="icon-money"></i>
    </div>
    <div class='control-label color-grey '>Grupo de Descuentos(GD)</div>
    <span class='txt-number color-rosa mt-lap'>{{groupsdiscounts}}</span>
  </a>

  <a class='dashboard-control' routerLink="/dashboard/users">
    <div class='control-icon bg-brand'>
      <i class="icon-employees"></i>
    </div>
    <div class='control-label color-grey '>Usuarios suscritos a GD</div>
    <span class='txt-number color-brand mt-lap'>{{users}}</span>
  </a>
</section>
