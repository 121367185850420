import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseListComponent } from '../../../base/list/list.component';
import * as moment from 'moment';

@Component({
  selector: 'cms-gulf-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent extends BaseListComponent implements OnInit {
public sendingSync=false;
stores=[];
stations=[];
pos=[];
page:number=1;
@Input() id: string;
@Input() maxSize: number;
@Output() pageChange: EventEmitter<number>;
@Output() pageBoundsCorrection: EventEmitter<number>;
  ngOnInit(): void {
    this.getStores();
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de Sucursales'}
        ],
        title: 'Listado de Sucursales'
      }
    );
    this.frmFilters = new FormGroup({
      name: new FormControl(''),
      data: new FormControl(''),
      userslist: new FormControl('')
    });
  }
  getUserStations(){
    this.service.collection = 'stations';
      this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        
        this.stations=response;
        this.getStores();
      }
    });
  }
  searchvalue(){
    var data= this.frmFilters.controls.data.value;
    var valueseach=this.frmFilters.controls.name.value;  
   let newlist= []
    if(valueseach!=""&&valueseach!=null){
              for(var i in data){
                if(data[i].details&&data[i].details.name.includes(valueseach)){
                  newlist.push(data[i]);
                }
              }
       this.stores=newlist;       
    }
  }
  clearsearch(){
    this.frmFilters.controls.name.setValue('');
    if(typeof(this.frmFilters.controls.data)!="undefined"){
      this.stores=this.frmFilters.controls.data.value;
    }
  }

  getStores(){
    let liststore=[];
    moment.locale('es');
    this.service.collection = 'mercado-pago/stores';
    this.service.getAll().subscribe(response => {
      if(response.length>0){
      response.map(store=>{
        if(store.store.posMercadoPagos){
          store.store.posMercadoPagos.map(pos=>{
            if(pos.provider=='cloud'){
              store.sync=true;
            }
          });
        }
      });
      this.frmFilters.controls.data.setValue(response);
      this.stores=response;
    }else{
      this.stores=[];
    }
    });
  }
  syncStation(id){
    this.sendingSync=true;
    this.service.collection = `/mercado-pago/store/${id}/sync/employees`;
    this.service.getAlltxt().subscribe(response => {
      if(!response.hasOwnProperty("success")){
        try{
          this.sendingSync=false;
          const error=JSON.parse(response);
          this.notification.show(error.code,"error");   
        }catch(e){
          this.sendingSync=false;
          this.getStores();
          this.notification.show(response,"success");
          this.getStores();
        }
      }
      
    });
  }
  getPos(liststore){
    moment.locale('es');
    this.service.collection = 'mercado-pago/pos';
    this.service.getAll().subscribe(response => {
        let res=response.map(result=>{
          result.pos.date_created= moment(result.pos.date_created).format("LL");
          return result;
        })
        this.setNewListStore(res,liststore);
    });
  }
  setNewListStore(res,liststore){
    let newlistore=[];
    let listpos=[];
    liststore.map(station=>{
      res.filter((terminal,index)=>{
        if(station.details&&terminal.details.store_id==station.details.id){
          listpos.push(terminal);
          station.pos=listpos;
        }
      });
      newlistore.push(station)
    });
    this.frmFilters.controls.data.setValue(newlistore);
    this.stores=newlistore;
  }
  configPos(data){
      let listpos=[];
    if(data.length>0){
    data.map(pos=>{
      let obj={
        "employee":pos.employeeId,
        "pos":pos.externalPosId 
      }
      listpos.push(obj);
    });
  }
    listpos["pos"]=true;
    this.listPos(listpos);
  }
  listPos(data){
    this.modallist.show("success","Listado de puntos de ventas",data,"show");
  }
  deletestore(id,datos,name){
    if(datos&&datos!=null&&datos!=undefined){
      // this.modallist.show("error","Debe eliminar primero todas las cajas",datos,"show");
      this.notification.show("Debe eliminar primero todas las cajas","error");
      return false;
      
    }else{
      this.modal.show(
        id,
        'warning',
      'Estatus',
      '¿Esta seguro que desea eliminar la sucursal '+name+'?',
      'show',
      id => {
        this.removestore(id);
      }
      );
   }
}
removestore(id)
{
this.service.collection = 'mercado-pago/stores';
this.service.remove(id).subscribe(response => {
  if(response==null||!response.hasOwnProperty('success')){
    this.modal.hide();
    this.getUserStations();
    this.notification.show("La sucursal se eliminó correctamente","success");
  }else{
    this.notification.show(response,"error");
  }
});
}
}
