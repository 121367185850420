import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { BaseFormComponent } from '../../../../base/form/form.component';
@Component({
  selector: 'cms-gulf-list-group',
  templateUrl: './list-group.component.html',
  styleUrls: ['./list-group.component.scss']
})

export class ListGroupComponent extends BaseFormComponent implements OnInit {
  listgruopuser=[];
  StationSelected=[];
  stationsgroups:FormGroup;
  ngOnInit(): void {
    this.getUsergroups();
    this.frmElement = new FormGroup({
      group: new FormGroup({
        name: new FormControl('',[]),
        registrationDate: new FormControl(),
      }),
      userStations: new FormControl([ ]),
      stations: new FormControl('',[]),
    });

    this.breadcrumb.links({
      links: [
        { link: '/dashboard/users', label: "Listado de usuarios"},
        { link: '', label: 'Listado de grupos del usuario'}
      ],
      title: 'Listado de grupos del usuario'
    });
  }


  getUsergroups(){
    this.service.collection = `users`;
    var urlcomplement = `user-stations/groups`;
    if(this.session.userId !== undefined){
      var group=[];
      this.service.getspecific(this.session.userId,urlcomplement).subscribe(response => {
        if (!response.hasOwnProperty('success')) {
            for(var i in response){
              group[i]= new Array();
              group[i]["group"]=response[i].name;
              group[i]["idgroup"]=response[i].id;
              
              group[i]["stations"]= new Array();
              for(var o in response[i].station){
                
                group[i]["stations"][o]= new Array();
                group[i]["stations"][o]=response[i].station[o]; 

              }
              
            }
            this.listgruopuser=group;

        }
      });
    }
  }
  GetStationSelected($event){

    let arraystation=[];
    let stations={};
    for(var i in $event.selected){
      if($event.selected[i].id!=""&&typeof($event.selected[i].id)!="undefined"){
          arraystation[i]=new Object();
          arraystation[i]["id"]=$event.selected[i].idg;
          arraystation[i]["stationId"]=$event.selected[i].id;
        
      }
    }
    this.StationSelected=arraystation;
    this.frmElement.controls.userStations.setValue(this.StationSelected);
    this.frmElement.controls.stations.setValue($event.selected.map(s => s.id));

  }

 

  deleteStationGroup(id,idgroup){
    var getstation=this.listgruopuser.filter(group=>group.idgroup==idgroup)
    .map(stationsgroup=>stationsgroup.stations).reduce(station=>station);
    if(getstation.length>1){
    var data = new Object();
    data["groupId"]=null;
    this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿desea eliminar la estación del grupo?',
    'show',
    id => {
      this.service.collection = `user-stations`;
      this.service.update(id,data).subscribe(response => {
          if (response === null) {
            this.getUsergroups();
            this.notification.show("Se elimino de forma correcta la estación","success")
          }
          this.modal.hide();
        });
    }
    );
  }else{
    var data = new Object();
    data["groupId"]=null;
    this.modal.show(
      id,
      'warning',
    'Estatus',
    'Se eliminará el grupo por completo, ¿desea continuar?',
    'show',
    id => {
      this.service.collection = `user-stations`;
      this.service.update(id,data).subscribe(response => {
          if (response === null) {
            this.service.collection = `groups`;
            this.service.remove(idgroup).subscribe(response => {
              if (response === null) {
                this.getUsergroups();
                this.notification.show("Se elimino de forma correcta el grupo","success");
              }
            });
          }
          this.modal.hide();
        });
    }
    );
  }
    
  }
}
