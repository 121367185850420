import { Component, Directive, Injectable, OnInit, OnChanges } from '@angular/core';

import { BreadcrumbService } from '../../common/breadcrumb/service/breadcrumb.service';
import { BaseFormComponent } from '../../../base/form/form.component';
import { __param } from 'tslib';
//Moment JS
import * as moment from 'moment';

@Component({
  selector: 'cms-gulf-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})


export class UsersComponent extends BaseFormComponent implements OnInit {
  listUsers=[];
  users= [];
  listgruopuser=[];
  userId:string;
  userrole:boolean;

  ngOnInit(): void {
  this.getUsergroups();
  this.GetUsers();
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de Usuarios'}
        ],
        title: 'Listado de Usuarios'
      }
    );
  }
  
  private GetUsers(){
    let relation={
      "include": [
        {
          "relation": "userGroups",
          "scope": {
            "include": [
              {"relation": "StationGroups"}
            
            ]
        }
      }, 
    ]
  };
    this.service.collection = 'users';
    this.service.getAll(relation).subscribe(response => {
      if(!response.hasOwnProperty('success')){
        let arrayuser=[];
        let roluser=[];
        for(var a in response){
          if(response[a].email!="api_discount@gulf.mx"){
            arrayuser.push(response[a])
          }
          if(response[a].id==this.session.userId){
            for(var l in response[a].userRoles){
                if(!roluser.includes(response[a].userRoles[l].role.name)){
                      roluser.push(response[a].userRoles[l].role.name)
                }
            }
          }
        }
        this.users =arrayuser;
        let users=this.users;
        this.userId=this.session.userId;
        if(!roluser.includes("SUPERADMIN")||roluser.includes("ADMIN")){
          this.userrole=true;
        }
          if(users.length>0){
          for(var o in users){
              
            this.listUsers[o]=new Array();
            this.listUsers[o]["username"]=users[o].username;
            this.listUsers[o]["email"]=users[o].email;
            this.listUsers[o]["id"]=users[o].id;
            this.listUsers[o]["status"]=users[o].status;
            moment.locale('es');
            users[o].registrationDate= moment(users[o].registrationDate).format("LL");

            this.listUsers[o]["registrationDate"]=users[o].registrationDate;
          if(typeof(users[o].userRoles)!="undefined"&&users[o].userRoles.length>0){
            this.listUsers[o]["rol"]= new Array();
            for(var i in users[o].userRoles){
                if(users[o].id==users[o].userRoles[i].userId){
                    this.listUsers[o]["rol"].push(users[o].userRoles[i].role.name);
                }
            }
          }
          if(typeof(users[o].userStations)!="undefined"&&users[o].userStations.length>0){
            this.listUsers[o]["stations"]= new Array();
            for(var u in users[o].userStations){
                if(users[o].id==users[o].userStations[u].userId){
                    this.listUsers[o]["stations"].push(users[o].userStations[u].name);
                }
            }
          }
        }
      }
      }
    });
  }
  getUsergroups(){
    this.service.collection = `users`;
    var urlcomplement = `user-stations/groups`;
    if(this.session.userId !== undefined){
      var group=[];
      this.service.getspecific(this.session.userId,urlcomplement).subscribe(response => {
        if (!response.hasOwnProperty('success')) {
            for(var i in response){
              group[i]= new Array();
              group[i]["group"]=response[i].name;
              
              for(var o in response[i].station){
                
                group[i]["stations"]= new Array();
                group[i]["stations"][o]= new Array();
                group[i]["stations"][o]=response[i].station[o]; 

              }

            }
            this.listgruopuser=group;

        }
      });
    }
  }
  status(){
   return false;
}
chagestatus(id,status){
  var estatus;
  if(status==false){
    estatus=true;
  }else{
    estatus=false;
  }
  this.modal.show(
    id,
    'warning',
  'Estatus',
  '¿desea cambiar el estatus del usuario?',
  'show',
  id => {
    this.service.collection = `users`;
    var action="status?status="+estatus; 
    this.service.getspecific(id,action).subscribe(response => {
        if (response === null || response.count === 1) {
          this.getUsergroups();
          this.GetUsers();
        }
        this.modal.hide();
      });
  }
  );
  
}
liststations(data){
  this.modallist.show("success","Listado de estaciones",data,"show");
}
listgroups(data){
  this.modallist.show("success","Listado de grupos",data,"show");
}

}
