<form action="" class="form-content" autocomplete="off" [formGroup]="frmElement" (submit)="onSubmit()" >
  <div class="user"  formGroupName="user" >
    <div class="form-group">
      <label for="name">Ingrese el nombre del usuario</label>
      <input type="text" name="username" id="username" formControlName="username">
      <!-- <cms-gulf-validate [control]="frmElement.controls.username"></cms-gulf-validate> -->
    </div>
    <div class="form-group">
      <label for="email">Ingrese el correo electrónico</label>
      <input type="text" name="email" id="email" formControlName="email">
    </div>
    <div class="add-pass" *ngIf="idedituser==0; else changepass">
      <div class="form-group show-password-txt"  >
        <label for="password">Ingrese su contraseña</label>
        <input type="password" name="generatedPassword" id="generatedPassword" value="" formControlName="generatedPassword" readonly>
        <span *ngIf="frmElement.controls.user.value.generatedPassword !== ''"  class="icon-show" (click)="showPassword($event)"></span>
      </div>
      <div class="form-group" >
        <div class="generate-password" (click)="generatePassword()">
          <div class="txt-password">Generar Contraseña</div>
        </div>
      </div>
    </div>
    <ng-template #changepass>
      <p class="link-collapse p-10 " [collapse]="changepassword" *ngIf="iduser==true">
        Cambiar contraseña
       </p>
      <div class="change-pass " collapseContent #changepassword="collapseContent">
        <div class="form-group show-password-txt" >
        <label for="password">Ingrese su contraseña</label>
        <input type="password" name="generatedPassword" id="generatedPassword" value="" formControlName="generatedPassword" readonly>
        <span *ngIf="frmElement.controls.user.value.generatedPassword !== ''"  class="icon-show" (click)="showPassword($event)"></span>
      </div>
      <div class="form-group" >
        <div class="generate-password" (click)="generatePassword()">
          <div class="txt-password">Generar Contraseña</div>
        </div>
        <p class="link-save p-10 ml-2" (click)="updatepassword()">Guardar</p>
      </div>
      </div>
    </ng-template>
  </div>
  <div class="form-group">
    <label for="rol">Rol o roles</label>
    <admin-gulf-multiselect  [items]="roles" [settings]="{property: 'name',selected: frmElement.controls.roles.value,propertySelected: 'id', placeholder: 'Seleccionar roles'}" (update)="GetRolesSelected($event)"></admin-gulf-multiselect>
    <input type="hidden" value="" formControlName="roles" name="roles" id="roles" >
  </div>
  <div class="form-group">
    <admin-gulf-multiselect-default [items]="stations" [settings]="{property: 'name', selected: frmElement.controls.stationsselected.value,propertySelected: 'stationId',placeholder: 'Asociar estaciones de servicio'}" (stations)="GetStationSelected($event)"></admin-gulf-multiselect-default>
  <input type="hidden" name="stationsselected" id="stationsselected" formControlName="stationsselected">
  </div>
  <div id="days" class="pl-3 d-flex mt-4">
    <div class="check-box"  >
      <input type="checkbox"  id="chkStatus" (change)="changeStatus( )" [checked]="active" >
      <label for="chkStatus">
        <span></span>
      </label>
      <span class="status-active ml-2 text-blue">Activo</span>
    </div>
  </div>
  <div class="form-actions text-right">
    <span [class.loading]="sending" class="mr-2">
      <input type="submit" value="{{button}}" [disabled]="frmElement.invalid">
    </span>
    <a class="btn-default cancel" routerLink="/dashboard/users">Cancelar</a>
  </div>
</form>
