import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { LogoComponent } from './logo/logo.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { AsideComponent } from './aside.component';
import { NgxCollapseModule } from 'ngx-collapse';
// Router
import { DashboardRoutingModule } from '../../dashboard/dashboard-routing.module';

@NgModule({
  declarations: [AsideComponent, LogoComponent, MenuComponent, FooterComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    NgxCollapseModule
  ],
  exports: [AsideComponent]
})
export class AsideModule { }
