import { Component, OnInit } from '@angular/core';
//- Service
import { ModalService } from './modal.service';
@Component({
  selector: 'admin-gulf-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  // Identificador del elemento a eliminar
  idSource: number;
  // Clase CSS de estado para mostrar/ocultar la modal
  show = 'show';
  // Clase CSS para indicar a la UI que se está procesando
  // una acción desde la modal
  executing = false;
  title = '';
  message = '';
  action = '';
  handler: any;
  userb2c='';
  msjuserb2c='';

  constructor(
    private modal: ModalService
  ) { }

  ngOnInit() {
    this.modal.currentMessage.subscribe((response) => {
      this.idSource = response.idSource;
      this.show = response.show;
      this.title = response.title;
      this.message = response.message;
      this.action = response.action;
      this.handler = response.handler;
      this.userb2c = response.userb2c;
    });

    this.modal.currentAction.subscribe((response) => {
      this.show = response.show;
      this.executing = response.executing;
    });
  }

  close(event: Event): void {
    event.preventDefault();
    this.modal.elementBody.className = '';
    this.modal.actionSource.next({ action: 'cancel' });
    this.show = '';
  }
  execute(): void {
    this.modal.setMessage(this.msjuserb2c);
    this.message = '';
    this.executing = true;
    this.handler(this.idSource);
  }

}
