<div class="actions">
  <a class="link-a" title="Agregar Estaciones" routerLink="/dashboard/group-stations/add">
    <i class="icon-plus"></i>
  </a>
</div>
<div class="filter" [ngClass]="{ show: status, close: close }">
  <form action="" [formGroup]="frmFilters" autocomplete="off" (ngSubmit)="searchvalue()">
    <fieldset>
      <legend>Búsqueda por Nombre</legend>
      <ul class="row">
        <li class="col-6">
          <label for="txtName">Nombre</label>
          <input type="text" name="name" id="txtName" formControlName="name">
        </li>
        <li class="col-6">
          <p>
            <a class="btn-default clear" (click)="clearsearch()">Limpiar</a>
            <span [class.loading]="sending">
              <button type="submit" class="btn-default">
                Buscar
              </button>
            </span>
          </p>
        </li>
      </ul>
    </fieldset>
  </form>
</div>
<table #table>
  <thead>
    <tr >
      <th class="text-center">Nombre</th>
      <th class="text-center">Estaciones</th>
      <th class="text-center">Fecha de Registro</th>
      <th class="text-center">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of listgroups">
      <td class="text-center">{{item.name}}</td>
      <td class="text-center edit " *ngIf="item.stations&&item.stations.length>0; else nostations" (click)="listgroupstations(item.stations)"> 
        <a class="w-100 link">
          <p>Estaciones </p>
        </a></td>
      <ng-template #nostations> <td class="text-center data-none">Sin estaciones</td> </ng-template>
      <!-- <td class="text-center" *ngIf="item.user!=''; else nousers;">{{item.user}}</td>
      <ng-template #nousers> <td class="text-center data-none">Sin usuarios</td> </ng-template> -->
      <td class="text-center">{{item.registrationDate}}</td>
      <td class="text-center " *ngIf="item.default==true||item.associated!=true||item.owner!=iduser; else groupadmin">
        <div class="edit d-flex">
        </div>
      </td>
      <ng-template #groupadmin> 
        <td class="text-center " >
          <div class="edit d-flex-discount">
            <a  class="icon-edit "  routerLink="/dashboard/group-stations/update/{{item.id}}"> </a>
            <a  class="icon-delete " (click)="validategroup(item.id)"> </a>
          </div>
        </td>  
      </ng-template>
    </tr>
  </tbody>
</table>

