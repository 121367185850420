import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseFormComponent } from 'src/app/base/form/form.component';

@Component({
  selector: 'cms-gulf-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseFormComponent implements OnInit {
roles=[];
data;
  ngOnInit(): void {
    if (this.router.url === '/dashboard'){
            this.verifydatauser();
      
      
    }
  }

  verifydatauser(){
    var iduser= this.session.userId;
    let relation={
      "include": [
        {
          "relation": "userRoles",
          "scope": {
            "include": [
              {"relation": "role"}
            
            ]
        }
      }, {
        "relation": "userStations"
      }
    ]
  };
  
    if(this.session.token!=""&&iduser!=""){
      var collection="users";
      this.service.collection = 'users';
      this.service.get(iduser,collection,relation).subscribe( response => {
        if(response.success === undefined){
          this.data=response;
            if(response["userRoles"]){
                var rol=[];
              for(var i in response["userRoles"]){
                      if(!rol.includes(response["userRoles"][i].role.name)){
                        rol.push(response["userRoles"][i].role.name);
                      }
                    }
                    if(rol.length==1){
                            if(rol.includes("GROUPDISCOUNT")){
                              this.router.navigate(['/dashboard/group-discount']);
                            }
                            if(rol.includes("ADMIN")){
                              this.router.navigate(['/dashboard/users']);
                            }
                        }
                        else if(rol.length>1){
                          this.router.navigate(['/dashboard/home']);
                        }
                      else{
                        this.session.destroy();
                      }
                
            }else{
              this.roles=null;
            }
        }
        
      })
    
  }
}
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
      // this.session.destroy();
  }
}
