import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
import { DashboardRoutingModule } from './dashboard-routing.module';

// Modules
import { AsideModule } from '../layouts/aside/aside.module';
import { ValidateModule } from '../../utils/validate/validate.module';

// Services
import { BreadcrumbService } from '../common/breadcrumb/service/breadcrumb.service';
// Componentes
import { DashboardComponent } from './dashboard.component';
import { PaginationComponent } from '../../common/notification/pagination/pagination.component';
import { BreadcrumbComponent } from '../common/breadcrumb/breadcrumb.component';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { AddEditComponent } from './users/add-edit/add-edit.component';
import { GroupStationsComponent } from './group-stations/group-stations.component';
import { GroupDiscountComponent } from './group-discount/group-discount.component';
import { AddEditStationsComponent } from './group-stations/add-edit-stations/add-edit-stations.component';
import { AddEditDiscountComponent } from './group-discount/add-edit-discount/add-edit-discount.component';
import { BaseService } from 'src/app/base/base.service';
import { PaginationService } from 'src/app/common/notification/pagination/pagination.service';
import { RolesComponent } from './users/roles/roles.component';
import { MultiselectComponent } from '../../common/notification/multiselect/multiselect.component';
import { Multiselect_gulfComponent } from '../../common/notification/multiselect_gulf/multiselect_gulf.component';
import { Multiselect_discountComponent } from '../../common/notification/multiselect_discount/multiselect_discount.component';
import { StationsComponent } from './users/stations/stations.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { GroupComponent } from './users/group/group.component';
import { NgxCollapseModule } from 'ngx-collapse';
import { ListGroupComponent } from './users/list-group/list-group.component';
import { EditGroupComponent } from './users/edit-group/edit-group.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { FilterUserb2cComponent } from './group-discount/filter-userb2c/filter-userb2c.component';
import { ListUserb2cComponent } from './group-discount/list-userb2c/list-userb2c.component';
import { UploadUserb2cFileComponent } from './group-discount/upload-userb2c-file/upload-userb2c-file.component';
import { RemoveruserstationsComponent } from './group-stations/removeruserstations/removeruserstations.component';
import { StoreComponent } from './store/store.component';
import { AddEditStoreComponent } from './store/add-edit-store/add-edit-store.component';
import { ListStationsComponent } from './store/list-stations/list-stations.component';
import { ListRegisterComponent } from './store/list-register/list-register.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { StoreEstationsComponent } from './store-estations/store-estations.component';
import { PaymentsOrdersComponent } from './payments-orders/payments-orders.component';
import { DetailPaymentsComponent } from './detail-payments/detail-payments.component';
import { DetailsPosComponent } from './details-pos/details-pos.component';
import { ListEmployeesComponent } from './list-employees/list-employees.component'; 

@NgModule({
  declarations: [
    DashboardComponent,
    BreadcrumbComponent,
    HomeComponent,
    UsersComponent,
    AddEditComponent,
    GroupStationsComponent,
    GroupDiscountComponent,
    AddEditStationsComponent,
    AddEditDiscountComponent,
    PaginationComponent,
    RolesComponent,
    StationsComponent,
    MultiselectComponent,
    Multiselect_gulfComponent,
    ChangepasswordComponent,
    GroupComponent,
    ListGroupComponent,
    EditGroupComponent,
    Multiselect_discountComponent,
    FilterUserb2cComponent,
    ListUserb2cComponent,
    UploadUserb2cFileComponent,
    RemoveruserstationsComponent,
    StoreComponent,
    AddEditStoreComponent,
    ListStationsComponent,
    ListRegisterComponent,
    StoreEstationsComponent,
    PaymentsOrdersComponent,
    DetailPaymentsComponent,
    DetailsPosComponent,
    ListEmployeesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardRoutingModule,
    AsideModule,
    ValidateModule,
    NgxCollapseModule,
    AngularMyDatePickerModule,
    NgxPaginationModule
  ],
  providers: [BreadcrumbService,BaseService,PaginationService]
})
export class DashboardModule { }
