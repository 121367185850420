/**
 * @author Santiago Marcelino Flores <santiago.marcelino@gigigo.com.mx>
 * @copyright 2019 Gigigo México.
 * @lastmodifiedDate 10.05.2019 14:10
 */
import { Component, OnInit, OnDestroy, Injectable, ViewChild, ElementRef, Directive } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';
// Base CRUD Service
import { BaseService } from '../base.service';
// Global Services
import { PaginationService } from '../../common/notification/pagination/pagination.service';
import { ModalService } from '../../common/notification/modal/modal.service';
import { ModaListService } from '../../common/notification/modal-list/modal-list.service';
import { ResponsiveService } from '../../utils/responsive/responsive.service';
import { BreadcrumbService } from '../../components/common/breadcrumb/service/breadcrumb.service';
import { NotificationService } from '../../common/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../utils/session/session.service';
// Global Config
import { CONFIG } from '../../gulf';
@Injectable()
@Directive({selector: '[BaseListComponent]'})
export abstract class BaseListComponent{
  /**
  * @property {ElementRef} Reference to the table in the UI
  */
  @ViewChild('table') table: ElementRef;
  /**
  * @property {FormGroup}  Reference to Angular's FormGroup Class
  * @public
  */
  frmFilters: FormGroup;
  /**
   * @property {boolean} Flag status for panel filters (OPEN | CLOSE)
   * @public
   */
  updateMessage = '';
  createMessage= '';
  status = false;
  /**
   * @property {boolean} Flag status for list loading
   * @public
   */
  loadingTable = true;
  /**
   * @property {boolean} Flag close for panel add animation to the filter panel (Animation)
   * @public
   */
  close = false;
  /**
   * @property {boolean} Flag status for list loading
   * @public
   */
  loading = true;
  /**
  * @property {boolean} Form status during a API's request
  * @public
  */
  sending = false;
  /**
   * @property {string} Reference to ID generic element
   * @public
   */
  idElement: string;
  /**
   * @property {array} Table rows elements
   * @public
   * @default Empty array
   */
  elements = [];
  /**
   * @property {object} Config object for pagination component
   * @public
   */
  config = {
    limit: false,
    page: 1,
    total: 0
  }
  /**
   * @property {string} Endpoint API to request
   * @public
   */
  collection = '';
  /**
   * @property {object} Object for querystring params
   * @public
   */
  filters = {};
  /**
   * @property {string} Search term for generic filtering
   * @public
   */
  term = '';
  /**
   * @property {string} Error message for the request
   * @public
   */
  message = '';
  /**
   * @property {class} Reference to Utils Class
   * @public
   */
  //utils = new Utils();
  /**
  * @property {boolean} Form status for many fields filters
  * @public
  */
  invalid = true;
  /**
  * @property {string} Message for delete action
  * @public
  */
  deleteMessage = 'Desea eliminar';
  /**
  * @property {string} Active index list
  * @public
  */
  currentIndex = '';
  /**
   * @property {string} Reference to ID User Admin
   * @public
   */
  idUser = '';
  /**
   * @property {string} Reference to ID Station
   * @public
   */
  idStation = '';
  /**
   * @property {string} Reference to credential id
   * @public
   */
  token = '';
  /**
   * @property {boolean} Object configuration pagination section reports
   * @public
   */
  statusReports: boolean = false;
  /**
   * @property {boolean} Object configuration pagination with filters in section reports
   * @public
   */
  paginationReports: boolean = false;
    /**
 * @param service Reference to Base Service CRUD handler Class
 * @param pagination Reference to Pagination Service Class global handler
 * @param modal Reference to Modal Service Class global handler
 * @param breadcrumb Reference to Breadcrumb service class global handler
 * @param route Reference to Route Angular class
 * @param responsive Reference to Responsive service class global handler
 * @param notification Reference to Notification service class global handler
 * @class BaseListComponent
 * @constructor
 */
  constructor(
    protected service: BaseService,
    protected pagination: PaginationService,
    protected modal: ModalService,
    protected modallist: ModaListService,
    protected session: SessionService,
    protected router: Router,
    protected route?: ActivatedRoute,
    protected breadcrumb?: BreadcrumbService,
    protected responsive?: ResponsiveService,
    protected notification?: NotificationService
  ) {
    this.init();
    this.idElement = this.route.snapshot.paramMap.getAll('id')[0];
    this.idStation = this.session.stationId;
    this.idUser = this.session.userId;
    this.token = this.session.token;
  }
  private isDirty(): boolean {
    const fields = Object.keys(this.frmFilters.controls);
    for (let i = 0; i < fields.length; i++) {
      if (this.frmFilters.controls[fields[i]].value !== '') {
        return true;
      }
    }
    return false;
  }
  protected init(): void {
    // Set pagination setup
    this.pagination.currentPage
      .subscribe(response => {
        if (response.page !== undefined) {
          if (response.page === 1){
            this.filters = { page: response.page, "filter[skip]": 0, "filter[limit]": 10 };
          }else{
            this.filters = { page: response.page , "filter[skip]": `${response.page-1}${0}`, "filter[limit]": 10 };
          }
          if(this.statusReports){
            this.filters = { page: response.page-1, limit: 10};
            if(this.paginationReports){
              this.filters = Object.assign(this.frmFilters.value, { page: response.page - 1, limit: 10 });
            }
          }
          this.getAll();
        }
      });
    this.modal.currentAction.subscribe(response => {
      if (response['action'] === 'cancel') {
        this.currentIndex = '';
      }
    });
  }
  /**
     * Clear pagination
     */
  ngOnDestroy() {
    this.pagination.clearPages();
  }
  /**
     * Get all records by filters
     */
  getAll(): void {
    this.service.collection = this.collection;
    this.service
      .getAll(this.filters)
      .subscribe(response => {
        if (this.sending && response.length === 0) {
          this.elements = [];
        }
        if (!response.hasOwnProperty('success')) {
          const changes = {
            limit: true,
            page: this.filters['page'],
            total: 0
          };
          if(this.statusReports){
            changes.page = this.filters['page'] + 1;
          }
          if (response.length > 0) {
            this.elements = response;
            changes.total = this.elements.length;
            if (this.elements.length >= 10) {
              changes.limit = false;
            }
            //Table responsive
            if (this.responsive.width <= CONFIG.BREAKPOINTS.MOBILE.VALUE) {
              this.scrollTable();
            }
          }else{
            this.elements = [];
          }
          this.config = changes;
        } else if (response.status === 403) {
          this.message = response.message;
        }
        this.sending = false;
        this.loading = false;
      });
  }
  /**
   * Controller for lnkFilter UI control, open/close filter's panel
   */
  controllerFilters(): boolean {
    let id;
    if (this.status) {
      this.close = true;
      id = setTimeout(() => {
        this.close = false;
        this.status = false;
      }, 400);
      return false;
    }
    this.status = true;
  }
  /**
     * Controller for lnkClear UI control, set ID empty and
     * get first page query for elements collection
     */
  clear(): void {
    if (this.isDirty()) {
      const fields = Object.keys(this.frmFilters.controls);
      fields.map(field => {
        this.frmFilters.controls[field].setValue('');
        this.frmFilters.controls[field].markAsUntouched();
        this.filters = { page: 1 };
      });
      this.getAll();
    }
  }
  /**
   * Controller submit event for frmFilters form, set the querystring filter
   */
  search(): void {
    this.sending = true;
    this.filters = Object.assign(this.frmFilters.value, { page: 1 });
    this.getAll();
  }
  /**
     * Delete row from the table list
     */
  remove(id: string, name = ''): void {
    this.modal.show(
      id,
      'warning',
      'Eliminar',
      `¿${this.deleteMessage}<b>${name}</b>?`,
      'show',
      id => {
        this.service.collection = this.collection;
        this.service
          .remove(id)
          .subscribe(response => {
            if (response === null || response.count === 1) {
              this.filters = { page: 1, "filter[skip]": 0, "filter[limit]": 10 };
              this.sending = true;
              this.getAll();
            }
            this.modal.hide();
          });
      });
  }
  /**
    * Move table responsive
    */
  scrollTable() {
    let id;
    if (this.table !== undefined) {
      id = setTimeout(() => {
        this.table.nativeElement.lastChild.scrollLeft = + 0;
      });
    }
  }
}
