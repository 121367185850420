import { Component, OnInit, Input, OnChanges, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'admin-gulf-multiselect-default',
  templateUrl: './multiselect_gulf.component.html',
  styleUrls: ['./multiselect_gulf.component.scss']
})
export class Multiselect_gulfComponent implements OnInit {
  /**
   * @property {Input} Input for items for multiselect list
   */
  @Input() items: any[] = [];
  /**
   * @property {Input} Input for settings options
   */
  @Input() settings: any;
  /**
   * @property {Output} Output for emit objects selected
   */
  @Output() stations: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @property {Array} all options available for select
   */
  options = [];
  /**
   * @property {Array} options available after search
   */
  optionsShow = [];
  userslist = [];
  type:string;
  iduser:string;
  /**
   * @property {Array} items selected by user
   */
  selecteds = [];
  /**
   * @property {Boolean} flag for open list of options
   */
  openList = false;

  /**
   * @property {FormControl} property for control search input
   */
  search = new FormControl();

  constructor(
    private elementRef: ElementRef
  ) { }

  /**
   * Lifecycle hook
   */
  ngOnInit() {
    this.search.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.optionsShow = this.options.filter(o => o.item[this.settings.property].toLowerCase().includes(value.toLowerCase()));
      this.userslist = this.options.filter(o => o.item[this.settings.property].toLowerCase().includes(value.toLowerCase()));
    });
  }

  /**
   * Lifecycle hook
   */
  ngOnChanges() {
    let useravailable=[];
    this.options = this.items.map(i => {
      return {
        checked: false,
        item: i
      };
    });
    
    this.optionsShow = this.options;
    this.userslist = this.options;
      this.type=this.settings.type ? this.settings.type:'';
      this.iduser=this.settings.iduser ? this.settings.iduser:'';
    if(this.settings.idgroup&&this.settings.idgroup!=undefined&&this.settings.idgroup!=""){
      useravailable = this.items.filter(newlist=>!newlist.userGroups);
      let validateuser = this.items.filter(lisuser=>lisuser.userGroups);
     for(var i in validateuser){
       let groupuser=[];
       for(var e in validateuser[i].userGroups){
         groupuser.push(validateuser[i].userGroups[e].groupId)
        }
        if(!groupuser.includes(+this.settings.idgroup)){
          useravailable.push(validateuser[i]);
       }

     }
     this.userslist=useravailable;
     let options = this.userslist.map(i => {
      return {
        checked: false,
        item: i
      };
    });
    this.userslist = options;
    if (this.settings.selected && this.settings.propertySelected) {
      this.userslist  =  this.userslist .map(o => {
       if (this.settings.selected.includes(o.item[this.settings.propertySelected])) {
         o.checked = true;
        }
        return o;
      });
    }
    }
    if (this.settings.selected && this.settings.propertySelected) {
       this.optionsShow  =  this.optionsShow .map(o => {
        if (this.settings.selected.includes(o.item[this.settings.propertySelected])) {
          o.checked = true;
          if (!this.selecteds.find(s => s[this.settings.propertySelected] === o.item[this.settings.propertySelected])) {
            this.selecteds.push(o.item);
          }
        }
        
        return o;
      });

      this.selecteds = this.selecteds
        .filter(s => this.optionsShow.find(o => o.item[this.settings.propertySelected] === s[this.settings.propertySelected]));
    }
  }

  /**
   * Controller for select item
   * @param option
   */
  select(option) {
    if (option.checked) {
      this.selecteds.push(option.item);
    } else {
      this.selecteds = this.selecteds.filter(s => s[this.settings.property] !== option.item[this.settings.property]);
    }

    this.stations.emit({ selected: this.selecteds });
  }

  /**
   * Remove item from selected list
   * @param e
   * @param item
   */
  remove(e, item) {
    e.stopPropagation();

    if (this.settings.disabled) { return; }
    this.selecteds = this.selecteds.filter(s => s[this.settings.property] !== item[this.settings.property]);
    this.options = this.options.map(o => {
      if (o.item[this.settings.property] === item[this.settings.property]) {
        o.checked = false;
      }
      return o;
    });

    this.stations.emit({ selected: this.selecteds });
  }

  openMultiselect() {
    if (this.settings.disabled) { return true; }
    this.openList = !this.openList;
  }


  /**
   * Controller for listen click event out of the component
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.openList = false;
    }
  }
}
