
  <div class="filter" [ngClass]="{ show: status, close: close }">
    <form action="" [formGroup]="frmFilters" autocomplete="off" (ngSubmit)="searchvalue()">
      <fieldset>
        <legend>Búsqueda por nombre de empleado</legend>
        <ul class="row">
          <li class="col-6">
            <label for="txtName">Nombre del empleado</label>
            <input type="text" name="name" id="txtName" formControlName="name">
          </li>
          <li class="col-6">
            <p>
              <a class="btn-default clear" (click)="clearsearch()">Limpiar</a>
              <span [class.loading]="sending">
                <button type="submit" class="btn-default">
                  Buscar
                </button>
              </span>
            </p>
          </li>
        </ul>
      </fieldset>
    </form>
  </div>

<table #table>
    <thead>
      <tr >
        <th class="text-center">Id empleado</th>
        <th class="text-center">Nombre</th>
        <th class="text-center">Estatus</th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr *ngFor="let item of stores | paginate: { itemsPerPage: 10, currentPage: page }">
        <td class="text-center" *ngIf="item.details; else withoutStore">{{item.details.name}}</td>
        <ng-template #withoutStore>
          <td class="text-center data-none" > 
            Sin sucursal
          </td>
        </ng-template>
        <td class="text-center">{{item.station.name}}</td>        
          
        <td class="text-center" >
         
        </td>
       
        
      </tr> -->
    </tbody>
  </table>