<nav class="pagination" aria-label="Paginación de la tabla">
  <ul>
    <li class="prev">
      <a [class.disabled]="prev" href="#" (click)="changePage($event, active - 1)">
        <span class="icon-prev" [class.disabled]="prev"></span>
      </a>
    </li>
    <li class="page">
      <a href="#" class="disabled" (click)="changePage($event, active)">{{active}}</a>
    </li>
    <li class="next">
      <a href="#" [class.disabled]="next" (click)="changePage($event, active + 1)">
        <span class="icon-next" [class.disabled]="next"></span>
      </a>
    </li>
  </ul>
</nav>