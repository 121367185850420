import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/Observable';
// Handler session
import { SessionService } from '../session/session.service';
@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate,CanActivateChild  {
  constructor(
    private router: Router,
    private session: SessionService){}
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.session.inSession()){
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.session.inSession()){
        return true;
      }
      this.router.navigate(['/login']);
      return false;
  }
  
}
