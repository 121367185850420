import { Component, OnInit, Input, OnChanges, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'admin-gulf-multiselect-discount',
  templateUrl: './multiselect_discount.component.html',
  styleUrls: ['./multiselect_discount.component.scss']
})
export class Multiselect_discountComponent implements OnInit {
  /**
   * @property {Input} Input for items for multiselect list
   */
  @Input() items: any[] = [];
  /**
   * @property {Input} Input for settings options
   */
  @Input() settings: any;
  /**
   * @property {Output} Output for emit objects selected
   */
  @Output() stations: EventEmitter<any> = new EventEmitter<any>();
  discount;
  /**
   * @property {Array} all options available for select
   */
  options = [];
  /**
   * @property {Array} options available after search
   */
  optionsShow = [];
  /**
   * @property {Array} items selected by user
   */
  selecteds = [];
  /**
   * @property {Boolean} flag for open list of options
   */
  openList = false;

  /**
   * @property {FormControl} property for control search input
   */
  search = new FormControl();

  constructor(
    private elementRef: ElementRef
  ) { }

  /**
   * Lifecycle hook
   */
  ngOnInit() {
    this.search.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.optionsShow = this.options.filter(o => o.item[this.settings.property].toLowerCase().includes(value.toLowerCase()));
    });
  }

  /**
   * Lifecycle hook
   */
  getDiscount(item){
    this.stations.emit({ selected: this.selecteds });
  }
  ngOnChanges() {
    
    this.options = this.items.map(i => {
      return {
        checked: false,
        item: i
      };
    });
    this.optionsShow = this.options;
    if (this.settings.selected && this.settings.propertySelected) {
      this.optionsShow = this.optionsShow.map(o => {
        if (this.settings.selected.includes(o.item[this.settings.propertySelected])) {
          o.checked = true;
          if (!this.selecteds.find(s => s[this.settings.propertySelected] === o.item[this.settings.propertySelected])) {
            this.settings.selecteddiscount.forEach(function (value) {
              if(o.item.id==value.stationId){
                o.item.discount=value.discountByFuelType.discount;
              }
            }); 
            this.selecteds.push(o.item);
          }
        }

        return o;
      });
      this.selecteds = this.selecteds
        .filter(s => this.optionsShow.find(o => o.item[this.settings.propertySelected] === s[this.settings.propertySelected]));
    }
  }

  /**
   * Controller for select item
   * @param option
   */
  select(option) {
    if (option.checked) {
      this.selecteds.push(option.item);
    } else {
      this.selecteds = this.selecteds.filter(s => s[this.settings.property] !== option.item[this.settings.property]);
    }

    this.stations.emit({ selected: this.selecteds });
  }

  /**
   * Remove item from selected list
   * @param e
   * @param item
   */
  remove(e, item) {
    e.stopPropagation();

    if (this.settings.disabled) { return; }
    this.selecteds = this.selecteds.filter(s => s[this.settings.property] !== item[this.settings.property]);
    this.options = this.options.map(o => {
      if (o.item[this.settings.property] === item[this.settings.property]) {
        o.checked = false;
      }
      return o;
    });

    this.stations.emit({ selected: this.selecteds });
  }

  openMultiselect() {
    if (this.settings.disabled) { return true; }
    this.openList = !this.openList;
  }


  /**
   * Controller for listen click event out of the component
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.openList = false;
    }
  }
}
