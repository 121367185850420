import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private messageSource = new BehaviorSubject<any>({ message: '', type: '' });
  currentMessage = this.messageSource.asObservable();
  constructor() { }

  show(message: string, type: string) {
    this.messageSource.next({ message: message, type: type });
  }
}
