/**
 * @author Santiago Marcelino Flores <santiago.marcelino@gigigo.com.mx>
 * @copyright 2019 Gigigo México.
 * @lastmodifiedDate 10.05.2019 13:35
 */
import { Component, OnInit, Injectable, Directive } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//Base CRUD service
import { BaseService } from '../base.service';
//Global Services
import { NotificationService } from '../../common/notification/notification.service';
import { BreadcrumbService } from '../../components/common/breadcrumb/service/breadcrumb.service';
import { SessionService } from '../../utils/session/session.service';
import { ModalService } from '../../common/notification/modal/modal.service';
import { ModaListService } from '../../common/notification/modal-list/modal-list.service';
// Base Class Utils
import { Utils } from '../../utils/utils';
@Directive({selector: '[BaseFormComponent]'})
export abstract class BaseFormComponent {
  /**
   * @property {FormGroup} Reference to Angular's FormGroup Class
   * @public
   */
  frmElement: FormGroup;
  /**
   * @property {boolean} Form status during a API's request
   * @public
   */
  sending = false;
  /**
   * @property {string} Reference to ID generic element
   * @public
   */
  idElement: string;
  /**
   * @property {string} Endpoint API to request
   * @public
   */
  collection = '';
  /**
   * @property {string} Message for the PUT|PATCH request
   * @public
   */
  protected utils = new Utils();
  updateMessage = '';
  /**
   * @property {string} Message for the POST request
   * @public
   */
  createMessage = '';
  /**
   * @property {string[]} Routes paths for the redirect
   * @public
   */
  routes: string[];
  /**
   * @property {string} Request method for the updating mode
   * @default POST Method
   */
  methodVerb = 'PATCH';
  /**
     * @property {class} Reference to Utils Class
     * @private
     */
  // protected utils = new Utils();
  /**
   * @property {string} Reference to ID User Admin
   * @public
   */
  idUser = '';
  /**
   * @property {string} Reference to ID Station
   * @public
   */
  idStation = '';
  /**
   * @property {string} Reference to credential id
   * @public
   */
  token = '';
  /**
    *
    * @param service Reference to Base Service CRUD handler Class
    * @param notification Reference to Notification Service Class global handler
    * @param route Reference to Angular's ActivatedRoute Class
    * @param router Reference to Angular's Router Class
    * @class BaseFormComponent
    * @constructor
    */
  constructor(
    protected service: BaseService,
    protected notification: NotificationService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected breadcrumb: BreadcrumbService,
    protected session: SessionService,
    protected modal?: ModalService,
    protected modallist?: ModaListService
    ) {
      this.idElement = this.route.snapshot.paramMap.getAll('id')[0];
      this.idStation = this.session.stationId;
      this.idUser = this.session.userId;
      this.token = this.session.token;
    }

  /**
   * Get a record from the API selected
   */
  getElement():void {
    if(this.idElement !== undefined){
      this.service.collection = this.collection;
      this.service.get(this.idElement, `?access_token=${this.token}`).subscribe( response => {
        if (!response.hasOwnProperty('success')) {
          this.frmElement.patchValue(response);
        }
      });
    }
  }
  /**
    * Controller action(CREATE|UPDATE) request to API
    * @returns {{boolean}} A flag
    */
  onSubmit(): boolean {
    this.service.collection = this.collection;
    this.sending = true;
    if (this.idElement === undefined) {
      this.service
        .create(this.frmElement.value, `?access_token=${this.token}`)
        .subscribe(response => {
          if (response === null || !response.hasOwnProperty('success')) {
            this.notification.show(this.createMessage, 'success');
            this.router.navigate(this.routes);
          }
          this.sending = false;
        });
      return false;
    }
    let data = this.frmElement.value;
    this.service
      .update(this.idElement, data, `?access_token=${this.token}` , this.methodVerb)
      .subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
          this.notification.show(this.updateMessage, 'success');
          this.router.navigate(this.routes);
        }
        this.sending = false;
      });
  }
}
