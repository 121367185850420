import { Component, OnInit } from '@angular/core';
import { BaseListComponent } from '../../../base/list/list.component';
@Component({
  selector: 'cms-gulf-list-employees',
  templateUrl: './list-employees.component.html',
  styleUrls: ['./list-employees.component.scss']
})
export class ListEmployeesComponent extends BaseListComponent implements OnInit {
employees;
  ngOnInit(): void {
    // this.getEmployee();
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de empleados'}
        ],
        title: 'Listado de empleados'
      }
    );
  }
  getEmployee(){
    this.service.collection='employees';
    this.service.getAll().subscribe(response=>{
    });
  }
  searchvalue(){
    var data= this.frmFilters.controls.data.value;
    var valueseach=this.frmFilters.controls.name.value;  
   let newlist= []
    if(valueseach!=""&&valueseach!=null){
              for(var i in data){
                if(data[i].store&&data[i].store.store.name.includes(valueseach)){
                  newlist.push(data[i]);
                }
              }
       this.employees=newlist;       
    }
  }
  clearsearch(){
    this.frmFilters.controls.name.setValue('');
    if(typeof(this.frmFilters.controls.data)!="undefined"){
      this.employees=this.frmFilters.controls.data.value;
    }
  }
}
