<form action="" class="form-content" autocomplete="off" [formGroup]="frmElement" (submit)="validateStores()"  >
  <div class="form-group"  formGroupName="group">
    <label for="name">Ingrese el nombre del grupo</label>
    <input type="text" name="name" id="name"  formControlName="name" >
  </div>
  <div class="form-group">
    <div class="row">
     <div class="col-6">
      <div class="form-group p-15">
        <admin-gulf-multiselect-default [items]="stations" [settings]="{property: 'name', selected: frmElement.controls.stations.value,propertySelected: 'stationId',placeholder: 'Asociar estaciones de servicio',type:'service',iduser:iduser}" (stations)="GetStationSelected($event)" ></admin-gulf-multiselect-default>          <input type="hidden" value="" formControlName="stations" name="stations" id="stations" >
        </div>
     </div>
     <div class="col-6">
      <div class="form-group p-15">
        <admin-gulf-multiselect-default [items]="users" [settings]="{property: 'username', selected: frmElement.controls.user.value,propertySelected: 'id',placeholder: 'Asociar usuarios',idgroup:idgroup}" (stations)="GetUsersSelected($event)" ></admin-gulf-multiselect-default>
        <input type="hidden" value="" formControlName="user" name="user" id="user" >  
      </div>
     </div>
    </div>
   
  </div>
  <div class="form-actions text-right">
    <span  class="mr-2">
      <input type="submit" value="{{button}}" [disabled]="frmElement.invalid">
    </span>
    <a class="btn-default cancel" routerLink="/dashboard/group-stations">Cancelar</a>
  </div>
</form>
