<div class="row p-relative">
  <i class="icon icon-user"></i>
  <p class="text-user-name" *ngIf="datauser">{{datauser.username}}</p>
  <p class="text-user-rol color-grey" *ngIf="datauser">{{datauser.userRoles}}</p>
</div>
<h2 *ngIf="(datauser&&datauser.accessControl!=false)||user=='SUPERADMIN'">{{title}}</h2>
<ul class="breadcrumb" *ngIf="(datauser&&datauser.accessControl!=false)||user=='SUPERADMIN'">
  <li *ngIf="groupdiscount!=true&&admin!=true; else templatediscount;">
    <a routerLink="/dashboard/home">
      <span class="icon-home"></span>
    </a>
  </li>
  <ng-template #templatediscount >
    <li *ngIf="admin!=true; else templateadmin;">
      <a routerLink="/dashboard/group-discount">
        <span class="icon-home"></span>
      </a>
    </li>
    </ng-template>
    <ng-template #templateadmin >
      <li >
        <a routerLink="/dashboard/users">
          <span class="icon-home"></span>
        </a>
      </li>
      </ng-template>
  <!-- {{ links }} -->
  <li *ngFor="let option of links">
    <a
      *ngIf="option.link; else label"
      routerLink="{{option.link}}"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}">
      {{option.label}}
    </a>
    <ng-template #label>
      <span> {{option.label}} </span>
    </ng-template>
  </li>
</ul>
