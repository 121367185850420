import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class ModaListService {

  private messageSource = new BehaviorSubject<any>({
    action: '',
    title: '',
    message: '',
    show: '',
    callback: null
  });

  actionSource = new BehaviorSubject<any>({ action: '' });

  currentMessage = this.messageSource.asObservable();

  currentAction = this.actionSource.asObservable();

  elementBody = document.getElementsByTagName('body')[0];

  constructor() { }

  show( action: string, title: string, message: string, show: string) {
    this.elementBody.className = 'open-modal';
    this.messageSource.next({
      action: action,
      title: title,
      message: message,
      show: show,
    });
  }

  hide(className = '') {
    this.elementBody.className = '';
    this.actionSource.next({ action: className, executing: false });
  }
}
