import { Component, OnInit } from '@angular/core';
import { BaseListComponent } from '../../../base/list/list.component';
import * as moment from 'moment';
@Component({
  selector: 'cms-gulf-detail-payments',
  templateUrl: './detail-payments.component.html',
  styleUrls: ['./detail-payments.component.scss']
})
export class DetailPaymentsComponent extends BaseListComponent implements OnInit {
order:[];
total_amount:number;
  ngOnInit(): void {
    this.total_amount=0;
    this.getOrder();
    this.breadcrumb.links(
      {
        links: [
          { link: '/dashboard/orders', label: 'Listado de órdenes'},
          { link: '', label:'Detalle de orden'}
        ],
        title: 'Detalle de orden'
      }
    );

  }
  getOrder(){
    if(this.idElement!=undefined){
      this.service.collection = 'mercado-pago/order';
      this.service.get(this.idElement).subscribe(response => {
        let price_unit=[];
        if(!response.code){
          if(response.details&&response.details.items){
            response.details.items.map(producto=>{
              price_unit.push(producto.quantity*producto.unit_price);
            });
          }
          if(price_unit.length>0){
            price_unit.map(unit=>{
              this.total_amount=this.total_amount+unit;
            });
          }
          if(response.details.total_amount==this.total_amount){
            this.total_amount=this.total_amount;
          }else{
            this.total_amount=0;
          }
         this.order=response;
          moment.locale('es');
          this.order["order"]["dateCreate"]=moment(this.order["order"]["createdAt"]).format('LLL'); 
      }
      else if(response.code=="ENTITY_NOT_FOUND"){
        this.notification.show("No existe una orden con ese número","error");
        this.router.navigate(["/dashboard/orders"])
      }
      });
    }
  }

}
