import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseListComponent } from '../../../base/list/list.component';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import * as CONFIG from '../../../../../parameters.json'; 
@Component({
  selector: 'cms-gulf-store-estations',
  templateUrl: './store-estations.component.html',
  styleUrls: ['./store-estations.component.scss']
})
export class StoreEstationsComponent extends BaseListComponent implements OnInit {

  stores=[];
stations=[];
pos=[];
page:number=1;
@Input() id: string;
@Input() maxSize: number;
@Output() pageChange: EventEmitter<number>;
@Output() pageBoundsCorrection: EventEmitter<number>;
  ngOnInit(): void {
    this.getUserStations();
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de estaciones'}
        ],
        title: 'Listado de estaciones'
      }
    );
    this.frmFilters = new FormGroup({
      name: new FormControl(''),
      data: new FormControl(''),
      userslist: new FormControl('')
    });
  }
  getUserStations(){
    this.service.collection = 'stations-with-store';
      this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        this.frmFilters.controls.data.setValue(response);
        this.stores=response;
        // this.getStores();
      }
    });
  }
  searchvalue(){
    var data= this.frmFilters.controls.data.value;
    var valueseach=this.frmFilters.controls.name.value;  
   let newlist= []
    if(valueseach!=""&&valueseach!=null){
              for(var i in data){
                if(data[i].station&&data[i].station.name.includes(valueseach)){
                  newlist.push(data[i]);
                }
              }
       this.stores=newlist;       
    }
  }
  clearsearch(){
    this.frmFilters.controls.name.setValue('');
    if(typeof(this.frmFilters.controls.data)!="undefined"){
      this.stores=this.frmFilters.controls.data.value;
    }
  }

  getStores(){
    let liststore=[];
    moment.locale('es');
    this.service.collection = 'mercado-pago/stores';
    this.service.getAll().subscribe(response => {
      this.stations.map(currentstation=>{
        response.filter(station =>{
          if(station.station.stationId==currentstation.stationId){
            station.store.date_creation= moment(station.details.date_creation).format("LL");
            currentstation.store=station;
          }
        });
        liststore.push(currentstation);
      });
    });
  }

  getPos(liststore){
    moment.locale('es');
    this.service.collection = 'mercado-pago/pos';
    this.service.getAll().subscribe(response => {
        let res=response.map(result=>{
          result.pos.date_created= moment(result.pos.date_created).format("LL");
          return result;
        })
        this.setNewListStore(res,liststore);
    });
  }
  setNewListStore(res,liststore){
    let newlistore=[];
    let listpos=[];
    liststore.map(station=>{
      res.filter((terminal,index)=>{
        if(station.store&&terminal.pos.store_id==station.store.store.id){
          listpos.push(terminal);
          station.pos=listpos;
        }
      });
      newlistore.push(station);
    });
    this.frmFilters.controls.data.setValue(newlistore);
    this.stores=newlistore;
  }
  configPos(data){
    let listpos=[];
  if(data.length>0){
  data.map(pos=>{
    let obj={
      "employee":pos.employeeId,
      "pos":pos.externalPosId 
    }
    listpos.push(obj);
  });
}
  listpos["pos"]=true;
  this.listPos(listpos);
}
  listPos(data){
    this.modallist.show("success","Listado de puntos de ventas",data,"show");
  }
  getLink(data){
    let array;
    let domainurl=CONFIG['API_MERCADO_PAGO'];
    let url_notification=CONFIG['URL_NOTIFICATION_MERCADO_PAGO'];
    let app_id=CONFIG['APP_ID'];
    let url=`${domainurl}/authorization?client_id=${app_id}&response_type=code&platform_id=mp&state=${data}&redirect_uri=${url_notification}`
    array=[url];
    this.modallist.show("success","Sincronizar estación",array,"show");
  }
  deletestore(id,datos,name){
        if(datos&&datos!=null&&datos!=undefined){
          // this.modallist.show("error","Debe eliminar primero todas las cajas",datos,"show");
          this.notification.show("Debe eliminar primero todas las cajas","error");
          return false;
          
        }else{
          this.modal.show(
            id,
            'warning',
          'Estatus',
          '¿Esta seguro que desea eliminar la sucursal '+name+'?',
          'show',
          id => {
            this.removestore(id);
          }
          );
       }
  }
  removestore(id)
  {
    this.service.collection = 'mercado-pago/stores';
    this.service.remove(id).subscribe(response => {
      if(response==null||!response.hasOwnProperty('success')){
        this.modal.hide();
        this.getUserStations();
        this.notification.show("La sucursal se eliminó correctamente","success");
      }else{
        this.notification.show(response,"error");
      }
    });
  }
}
