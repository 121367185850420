<div class="modal {{show}} {{action}}" [class.executing]="executing">
    <div class="content">
      <div>
        <div class="modal-header">
          <a class="icon-close" (click)="close($event)"></a>
          <h2>{{title}}</h2>
        </div>
        <div class="modal-body body-list contenedor" *ngIf="message.userNotFound&&message.userFound; else showlistuser" >
            <p *ngFor="let item of message.userNotFound">{{item}}</p>
            <div>
              <p class="tittle-users">Usuarios agregados correctamente</p>
            </div>
            <p *ngFor="let item2 of message.userFound">{{item2}}</p>
         <ul>
          <li>
            <input class="btn-default cancel" type="button" (click)="close($event)" value="Cerrar" />
          </li>
          <li>
          </li>
        </ul>
          
        </div>
        <ng-template #showlistuser>
          <div class="modal-body body-list contenedor" *ngIf="message.accesslist&&message.accesslist==true; else listuser" >
            <p *ngFor="let item of message">{{item}}</p>
         <ul>
          <li>
            <a class="" routerLink="/dashboard/group-stations/deleteuser/{{message.id}}" (click)="close($event)"> <input class="btn-default cancel" type="button"  value="Ver usuarios" /></a>
          </li>
          <li>
          </li>
        </ul>      
        </div>
        </ng-template>
        <ng-template #listuser >
          <div *ngIf="!message.pos; else listuserpos" class="modal-body body-list contenedor" >
            <p *ngFor="let item of message">{{item}}</p>
         <ul>
          <li>
            <input class="btn-default cancel" type="button" (click)="close($event)" value="Cerrar" />
          </li>
          <li>
          </li>
        </ul>      
        </div>
        </ng-template>
        <ng-template #listuserpos >
          <div class="modal-body body-list contenedor" >
            <p *ngFor="let item of message"><span>Caja:</span>{{item.pos}}<br><span>Empleado:</span>{{item.employee}}</p>
         <ul>
          <li>
            <input class="btn-default cancel" type="button" (click)="close($event)" value="Cerrar" />
          </li>
          <li>
          </li>
        </ul>      
        </div>
        </ng-template>
      </div>
    </div>
  </div>