<div class="multi-select">
  <div class="multiselect" [ngClass]="{'disabled': settings.disabled}" (click)="openMultiselect(); false;">
    <span class="placeholder" >{{settings?.placeholder}}</span>
  </div>
  <div class="list" *ngIf="openList">
    <input type="text" placeholder="Buscar" [formControl]="search">
    <ul class="items">
      <li class="item check-box" *ngFor="let option of optionsShow; let i = index">
        <input id="chkItem_{{i}}" type="checkbox" [(ngModel)]="option.checked" (change)="select(option);">
        <label for="chkItem_{{i}}">
          <span></span>
          {{option.item[settings.property]}}
        </label>
      </li>
    </ul>
  </div>
</div>
<div class="list-stations-and-groups">
  <ul>
    <li *ngFor="let tag of selecteds" >
      <div class="associate">
        <div class="associate-name text-ornage">
          {{tag[settings.property]}}
          
        </div>
        <div class="trash">
          <i class="icon-close" (click)="remove($event, tag)"></i>
        </div>
        <div>
          
        </div>
      </div>
      <div class="discount mt-2 ">
        <p class="text-d">Descuento</p>
        <input type="text" (change)="getDiscount(tag)" [(ngModel)]="tag.discount">
      </div>
    </li>
  </ul>
</div>