<h1 class="text-center">{{namegroup}}</h1>
    <div  class="div-groups">
        <div class="stations row " >
            <form action="" class="form-content" autocomplete="off" [formGroup]="frmElement" (submit)="onSubmit()"  >
                <p class="text-center">Editar Nombre grupo </p>
                    <div class="form-group"  formGroupName="group">
                      <label for="name">Ingrese el nombre del grupo</label>
                      <input type="text" name="name" id="name"  formControlName="name" >
                    </div>
                    <div class="form-actions text-right mt-2">
                      <span  class="mr-2">
                        <input type="submit" value="Actualizar" [disabled]="frmElement.invalid">
                      </span>
                    </div>
                  </form>
    </div>
    </div>
    <div  class="div-groups row form-content">
      <div class="col-6">
        <div class="stations row " *ngIf="listgruopuser&&listgruopuser.userStations.length>0;">
               <p class="text-ornage">Estaciones:</p>
                <ul class="">
                  <li *ngFor="let stations of listgruopuser.userStations; let o=index;" class="">
                         <p class="edit bg-stations">
                           {{stations.name}}
                           <span class="trashgroup float-right" (click)="deleteStationGroup(stations.id)"><a  class="icon-delete m-auto"></a></span>
                         </p>
                     </li>
                </ul>
          </div>
      </div>
        <div class="col-6">
          <form action="" class="" autocomplete="off" [formGroup]="stationsgroup" (submit)="onSubmit('stations')">
              <p class="text-center text-ornage">Agregar Estaciones</p>
              <div class="stations row ml-3 " >
                      <admin-gulf-multiselect-default [items]="stations" [settings]="{property: 'name',placeholder: 'Asociar estaciones de servicio',type:'service',iduser:iduser}" (stations)="GetStationSelected($event)" ></admin-gulf-multiselect-default>
                      <input type="hidden"  formControlName="stations"  id="stations">
                      <div class="form-actions text-right mt-2">
                          <span  class="mr-2">
                            <input type="submit" value="Actualizar" [disabled]="stationsgroup.invalid">
                          </span>
                        </div>
                      </div>
                  </form>
        </div>
    </div>
