<div class="menu">
  <nav *ngIf="superAdmin==true||accescontrol==true">
    <ul>
      <li>
        <a routerLinkActive="active" routerLink="/dashboard/home" *ngIf="groupdiscount!=true&&admin!=true">
          <i class="icon-home ml-3" ></i>
          Dashboard
        </a>
      </li>
      <li *ngIf="groupdiscount!=true">
        <a routerLinkActive="active" routerLink="/dashboard/users">
          <i class="icon-employees ml-3"></i>
          Usuarios
        </a>
      </li>
       <li *ngIf="groupdiscount!=true">
        <a routerLinkActive="active" routerLink="/dashboard/group-stations">
          <i class="icon-station ml-3"></i>
          Grupos de Estaciones
        </a>
      </li>
       <li *ngIf="groupdiscount!=true">
        <a routerLinkActive="active" routerLink="/dashboard/store"  >
          <i class="store-icon ml-3"></i>
          Sucursales
        </a>
      </li>
      <li *ngIf="groupdiscount!=true">
        <a routerLinkActive="active"  routerLink="/dashboard/stations/">
          <i class="icon-station ml-3"></i>
          Estaciones
        </a>
      </li>
      <li *ngIf="groupdiscount!=true">
        <a routerLinkActive="active"  routerLink="/dashboard/orders/">
          <i class="voucher-icon ml-3"></i>
          Órdenes
        </a>
      </li>
      <!-- <li *ngIf="groupdiscount!=true">
        <a routerLinkActive="active" routerLink="/dashboard/employees"  >
          <i class="store-icon ml-3"></i>
          Empleados
        </a>
      </li> -->
      <!-- <li *ngIf="groupdiscount!=true">
        <a routerLinkActive="active"  routerLink="/dashboard/payment-order/">
          <i class="icon-station ml-3"></i>
          Ordenes de pago
        </a>
      </li> -->
      <li>
        <a routerLinkActive="active" routerLink="/dashboard/group-discount" class="mt-80" *ngIf="admin!=true">
          <i class="icon-money ml-3"></i>
          Grupos de Descuentos
        </a>
      </li>
      <li>
        
      </li>
    </ul>
  </nav>
</div>
