<div class="h-100" *ngIf="roles==null||roles.length>0">
    <div id="container-login">
        <form  class="form-content text-center mt-4 pt-4 login-form"  [formGroup]="frmLogin"  autocomplete="off"  (ngSubmit)="onSubmit()">
                <img class="img-form-login" src="/assets/images/logo-easx.png" alt="GULF" >
                <div class="title mt-4">
                    <h1 class=" text-login">Inicia sesión</h1>
                    <p class="text-login" >Grupo de descuentos para estaciones participantes</p>
                </div>
            <div class="form-group mt-4">
                <input class="text-center" type="text" name="email" id="email" placeholder="Usuario o Correo Electrónico" formControlName="email">
                <cms-gulf-validate [control]="frmLogin.controls.email"></cms-gulf-validate>
            </div>
            <div class="form-group mt-4">
                <input class="text-center" type="password" name="passUser" id="passUser" formControlName="password" placeholder="Contraseña">
                <cms-gulf-validate [control]="frmLogin.controls.password"></cms-gulf-validate>
            </div>
            <div  class="btn-login mt-4"    >
                <button    class="btn  w-100 btn-login" [disabled]="!frmLogin.valid" >Inicia Sesión</button>
            </div>
        </form>
    </div>
      
</div>