import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from 'src/app/base/form/form.component';
@Component({
  selector: 'cms-gulf-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent extends BaseFormComponent implements OnInit {
  namegroup:string;
  iduser:string;
  stations=[];
  StationSelected=[];
  listgruopuser;
  allstation=[];
  stationsgroup:FormGroup;
  ngOnInit(): void {
    this.iduser=this.session.userId;
    this.getstationGroups();
    this.getElement();
    this.frmElement = new FormGroup({
      group: new FormGroup({
        name: new FormControl('',[Validators.required]),
        registrationDate: new FormControl(),
      }),
    });
    this.stationsgroup= new FormGroup({
        userStations: new FormControl([ ]),
        stations: new FormControl('',[Validators.required]),
    });
    this.createMessage = 'El grupo se actualizó correctamente';
  this.updateMessage = 'Se actualizaron los datos del grupo';
  this.routes = ['dashboard', 'users'];
  this.breadcrumb.links({
    links: [
      { link: '/dashboard/users', label: 'Listado de usuarios'},
      { link: '', label: 'Editar Grupo de Estaciones'}
    ],
    title: 'Editar Grupo de Estaciones'
  });

  }
  onSubmit(flag=''):boolean{
    if(this.idElement != undefined&&flag==""){
      this.service.collection = `groups`;
      var group=this.frmElement.controls.group.value;
      this.service.update(this.idElement, group).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
          this.notification.show(this.updateMessage, 'success');
          this.getElement();
          // this.router.navigate(this.routes);
        }
        this.sending = false;
      });
    return false
  }
  if(this.idElement != undefined&&flag=="stations"){
    var stations= this.stationsgroup.controls.stations.value;
      for(var i in  stations){
    var data = new Object();
      data["groupId"]=parseInt(this.idElement);
    this.service.collection = `user-stations`;
      this.service.update(stations[i],data).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
              this.notification.show(this.createMessage, 'success');
            this.router.navigate(this.routes);
        }
        this.sending = false;
      });
    }
  } 
  }

  getUserStations(){
    let station=[];
    let stationexist=[];
    let restations=[];
    this.service.collection = 'user-stations';
    let relation=
    this.service.getAll().subscribe(response => {
      if(this.session.userId !== undefined){
        response.map(station=>{
            this.allstation.push(station);
          });
          this.allstation.filter(avaliablestation=>{
              if(avaliablestation.groupId!=null&&avaliablestation.groupId!="null"){
                if(!stationexist.includes(avaliablestation.stationId)){
                  stationexist.push(avaliablestation.stationId);
                }
              }
          })
          let list= response.filter(list=>!stationexist.includes(list.stationId));
          let  result=list;    
          result.map(res=>{
            if(res.userId==this.session.userId){                             
              station=[];
              station["name"]=res.name;
              station["id"]=res.id;
              station["stationId"]=res.stationId;
              station["groupId"]=res.groupId;
              restations.push(station);
            }      
        });
          this.stations=restations; 
    } 
  });
}
getstationGroups(){

  this.service.collection = '/station-groups';
  this.service.getAll().subscribe(response => {
    if(!response.hasOwnProperty('success')){
        if(response.length>0){
          response.map(station=>{
            this.allstation.push(station);
          })
          
        }
      }
  });
}
  getElement(){
    let relation={
      "include": [
         {
        "relation": "userGroups",
        "scope": {
          "include": [
            {"relation": "user"}
          
          ]
      }
    },
      {
        "relation": "userStations"
      },
    ]
  };
    this.service.collection = `groups`;
    if(this.idElement !== undefined){
    
      let user=[];
      this.service.get(this.idElement,'',relation).subscribe(response => {
        if (!response.hasOwnProperty('success')) {
          var groups=this.frmElement.controls.group as FormGroup;
          this.namegroup=response.name;
          this.listgruopuser=response;
          groups.patchValue(response);
          this.getUserStations();
        }
      });
    }
  }
  GetStationSelected($event){

    let arraystation=[];
    let stations={};
    for(var i in $event.selected){
      if($event.selected[i].id!=""&&typeof($event.selected[i].id)!="undefined"){
          arraystation[i]=new Object();
          arraystation[i]["id"]=$event.selected[i].name;
          arraystation[i]["stationId"]=$event.selected[i].id;
        
      }
    }
    this.StationSelected=arraystation;
    
    this.stationsgroup.controls.userStations.setValue(this.StationSelected);
    this.stationsgroup.controls.stations.setValue($event.selected.map(s => s.id));

  }

  deleteStationGroup(id){
    let stations=this.listgruopuser.userStations;
    if(stations.length>1){
    var data = new Object();
    data["groupId"]=null;
    this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿desea eliminar la estación del grupo?',
    'show',
    id => {
      this.service.collection = `user-stations`;
      this.service.update(id,data).subscribe(response => {
          if (response === null) {
            this.getElement();
            this.notification.show("Se elimino de forma correcta la estación","success")
          }
          this.modal.hide();
        });
    }
    );
  }else{
    var data = new Object();
    data["groupId"]=null;
    this.modal.show(
      id,
      'warning',
    'Estatus',
    'Se eliminará el grupo por completo, ¿desea continuar?',
    'show',
    id => {
      this.service.collection = `user-stations`;
      this.service.update(id,data).subscribe(response => {
          if (response === null) {
            this.service.collection = `groups`;
            this.service.remove(+this.idElement).subscribe(response => {
              if (response === null) {
                this.router.navigate(["/dashboard/users"]);
                this.notification.show("Se elimino de forma correcta el grupo","success");
              }
            });
          }
          this.modal.hide();
        });
    }
    );
  }
  }

}
