<h1 class="text-center">Selecciona el archivo</h1>
<form action="" class="form-content" autocomplete="off" [formGroup]="frmElement" enctype='multipart/form-data' (submit)="onSubmit()">
    <button type="button" mat-raised-button (click)="fileInput.click()">Cargar Archivo</button>
    <input hidden (change)="handleLogoFileInput($event.target.files)" #fileInput type="file" id="file">
    <p class="name-file" *ngIf="namefile!=''&&namefile!=null">{{namefile}}</p>
    <input type="hidden"  formControlName="file" >
    <div class="form-actions text-right">
      <span class="mr-2" [class.loading]="sendingFile">
          <input type="submit" value="Subir" [disabled]="frmElement.invalid" >
      </span>
        <a class="btn-default cancel" routerLink="/dashboard/group-discount">Cancelar</a>
      </div>
</form>

<div class="form-content">
  <div class="example" *ngIf="example==true">
    <label for="">Ejemplo de Formato:</label>
    <img src="https://s3-us-west-2.amazonaws.com/imagenes.gx/discount-group/ejemplo-excel.jpg" alt="" class="w-100 mt-2">
  </div>
  <div class="list-users row d-flex" *ngIf="result&&list==true&&result['registered']">
   <div class="success col-4 table-users "  >
      <div class="p1">
        <p class="text-center">Usuarios agregados correctamente</p>
      </div>
      <p *ngIf="result['registered']['news']['total']==0" class="text-center">Sin usuarios</p>
      <ul >
          <li *ngFor="let item of result['registered']['news']['users']" class="pb-1 row">
              {{item.email}}
          </li>
        <p class="result-users">Total: {{result['registered']['news']['total']}}</p>
      </ul>
   </div>
   <div class="duplicates col-4 table-users"  >
    <div class="p1">
      <p class="text-center">Usuarios duplicados</p>
    </div>
    <p *ngIf="result['registered']['duplicates']['total']==0" class="text-center">Sin usuarios</p>
    <ul >
      <li *ngFor="let item of result['registered']['duplicates']['users']" class="pb-1">{{item.email}}</li>
      <p class="result-users">Total: {{result['registered']['duplicates']['total']}}</p>
    </ul>
   </div>
   <div class="unregistered col-4 table-users"  >
    <div class="p1">
      <p class="text-center">Usuarios sin encontrar</p>
    </div>
    <p *ngIf="result['unregistered']['total']==0" class="text-center"> Sin usuarios</p>
    <ul >
      <li *ngFor="let item of result['unregistered']['users']" class="pb-1">{{item}}</li>
      <p class="result-users"> Total: {{result['unregistered']['total']}}</p>
    </ul>
   </div>
  </div>
  <div class=" file mt-2" *ngIf="result&&result['registered']['news']['total']>0">
    <button (click)="downloadFile()">Exportar excel</button>
   </div>
</div>
