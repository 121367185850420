import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

// Service
import { ValidateService } from '../service/validate.service';

@Component({
  selector: 'cms-gulf-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidateComponent implements OnInit {
  message: string;

  @Input() control: FormControl;

  constructor() { }

  ngOnInit(): void {
  }

  get errorMessage(): any {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        this.message = ValidateService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
        return this.message;
      }
    }
    return null;
  }

}
