import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../common/breadcrumb/service/breadcrumb.service';
import { FormViewComponent } from '../../../common/form/form-view.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseFormComponent } from '../../../../base/form/form.component';
import { IAngularMyDpOptions, IMyDateModel, IMyDefaultMonth } from 'angular-mydatepicker';
import * as moment from 'moment';
@Component({
  selector: 'cms-gulf-add-edit-discount',
  templateUrl: './add-edit-discount.component.html',
  styleUrls: ['./add-edit-discount.component.scss']
})


export class AddEditDiscountComponent extends BaseFormComponent implements OnInit {
days=[
  {
  "name":"Lunes",
  "key":"L",
  "checked":false
 },
 {
  "name":"Martes",
  "key":"M",
  "checked":false
 },
 {
  "name":"Miércoles",
  "key":"M",
  "checked":false
 },
 {
  "name":"Jueves",
  "key":"J",
  "checked":false
 },
 {
  "name":"Viernes",
  "key":"V",
  "checked":false
 },
 {
  "name":"Sábado",
  "key":"S",
  "checked":false
 },
 {
  "name":"Domingo",
  "key":"D",
  "checked":false
 },

];
hours = [];
hoursend = [];
showpage:boolean;
stations=[];
minutes = [];
minutesend = [];
groups = [];
schedule24:boolean;
scheduleany:boolean;
dayall:boolean;
dayselect:boolean;
active:boolean;
activeMail:boolean;
StationSelected=[];
idfuelregular=[];
idfuelpremium=[];
idfueldiesel=[];
idfueldieselmarino=[];
typefuel:boolean;
button:string;
myOptions: IAngularMyDpOptions = {
  dateFormat: 'yyyy-mm-dd',
  dayLabels: { su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sab' },
  monthLabels: { 1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
};
myOptions2: IAngularMyDpOptions = {
  dateFormat: 'yyyy-mm-dd',
  dayLabels: { su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sab' },
  monthLabels: { 1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
  disableDateRanges: []
};
defaultMonth: IMyDefaultMonth = {
  defMonth: '',
  overrideSelection: false
};
idelement;
groupstations;
groupsbygroupsstation;
groupsbygroupsstationcollapse:boolean;
groupsbystationscollapse:boolean;
  ngOnInit(): void {
    this.idelement=this.idElement;
    this.schedule24=true;
    this.dayall=true;
    this.active=false;
    this.activeMail=true;
    this.button="Crear";
    this.breadcrumb.links({
      links: [
        { link: '/dashboard/group-discount', label: 'Listado Grupo Descuentos'},
        { link: '', label: this.idElement ? 'Actualizar Grupo' : 'Crear Grupo'}
      ],
      title: this.idElement ? 'Actualizar Grupo de Descuentos' : 'Crear Grupo de Descuentos'
    });
    this.createMessage = 'El grupo se creó correctamente';
    this.updateMessage = 'Se actualizaron los datos grupo';
    // FormGroup
    this.frmElement = new FormGroup({
      discountGroup: new FormGroup({
        name: new FormControl('',[Validators.required]),
        termConditions: new FormControl('',[Validators.required]),
        status: new FormControl(this.active),
        sendEmail: new FormControl(this.activeMail),
        discount: new FormControl('',[Validators.required])
      }),
      validity: new FormGroup({
        startDate: new FormControl(''),
        endDate: new FormControl(''),
      }),
      date: new FormGroup({
        inidate: new FormControl(new Date,[Validators.required]),
        findate: new FormControl('',[Validators.required]),
        
      }),
      schedule: new FormGroup({
        starthour: new FormControl('00'),
        startminute: new FormControl('00'),
        endhour: new FormControl('00'),
        endminute: new FormControl('00'),
      }),
      weekdays: new FormControl(''),
      discountPerLiter: new FormGroup({
        regular: new FormControl(0),
        premium: new FormControl(0),
        diesel: new FormControl(0),
        dieselmarino: new FormControl(0),
      }),
      stations: new FormControl(''),
      stationsselected: new FormControl('' ),
      groupsselected: new FormControl(),
      stationsdb: new FormControl(),
      allstation: new FormControl(),
      ObjectPerLiter: new FormControl(),
      ObjectPerLiterSelected: new FormControl(),
    });
    this.getStations();
    this.getElement();
    this.getGroups();
    this.hours = this.fillTime(24);
    this.hoursend = this.fillTime(24);
    this.minutes = this.fillTime(60);
    this.minutesend = this.fillTime(60);
    this.changehourend();
    this.routes = ['dashboard', 'group-discount'];
  }
  AddDiscountPerLite(){
    this.frmElement.get("discountGroup").get("discount").setValue(0);
    var discountPerLiter=this.frmElement.get("discountPerLiter") as FormGroup;
    var idFueldb=[];
    var discountPerLiterSelected=this.frmElement.controls.ObjectPerLiterSelected.value;
    for(var l in discountPerLiterSelected){
      idFueldb.push(discountPerLiterSelected[l].fuelId)      
    }
    var arrayfuel=[];
    var listaddfuel=[]    
      if(parseFloat(discountPerLiter.controls.regular.value)!=0&&discountPerLiter.controls.regular.value!=""){
        arrayfuel.push({"discount":parseFloat(discountPerLiter.controls.regular.value),"fuelId":"59e6ed8772492f7fea9dc170","fuelKey":"regular"});
      }
      if(parseFloat(discountPerLiter.controls.premium.value)!=0&&discountPerLiter.controls.premium.value!=""){
        arrayfuel.push({"discount":parseFloat(discountPerLiter.controls.premium.value),"fuelId":"59e6ed8772492f7fea9dc171","fuelKey":"premium"});
      }
      if(parseFloat(discountPerLiter.controls.diesel.value)!=0&&discountPerLiter.controls.diesel.value!=""){
        arrayfuel.push({"discount":parseFloat(discountPerLiter.controls.diesel.value),"fuelId":"59e6ed8772492f7fea9dc172","fuelKey":"diesel"});
      }
      if(parseFloat(discountPerLiter.controls.dieselmarino.value)!=0&&discountPerLiter.controls.dieselmarino.value!=""){
        arrayfuel.push({"discount":parseFloat(discountPerLiter.controls.dieselmarino.value),"fuelId":"59e6ed8772492f7fea9dc173","fuelKey":"dieselmarino"});
      }
      if(discountPerLiterSelected&&discountPerLiterSelected.length>0){
          for(var a in arrayfuel){
            if(!idFueldb.includes(arrayfuel[a].fuelId)){
              listaddfuel.push(arrayfuel[a]);
            }
        }
       }else{
        for(var o in arrayfuel){
          listaddfuel.push(arrayfuel[o]);
        }
       }
       
      if(listaddfuel.length>0){
      for( var d in listaddfuel){
        this.adddiscountliter(listaddfuel[d]);
      }
      this.notification.show("se agregaron los descuentos correctamente","success");
            
    }
      
  }
  adddiscountliter(listaddfuel){
    var data=new Object();
    data["discount"]=listaddfuel.discount;
    
    this.service.collection ="discount-by-fuel-types";
    this.service.create(data).subscribe(response => {
     if(response === null || !response.hasOwnProperty('success')){
      this.onSubmit()
      var data2= new Object();
      data2["fuelKey"]=listaddfuel.fuelKey;
      data2["fuelId"]=listaddfuel.fuelId;
      data2["discountGroupId"]=parseFloat(this.idElement);
       data2["discountByFuelTypeId"]=response.id;
       this.service.collection ="discounts-groups/"+this.idElement+"/discount-per-liter-fuels";
       this.service.create(data2).subscribe(response => {
        if(response === null || !response.hasOwnProperty('success')){
          this.getElement();
        
        }
       });
      }
    });
  }
getCopyOfOptions(): IAngularMyDpOptions {
  return JSON.parse(JSON.stringify(this.myOptions2));
}
changeschedule(flag:string){
  if(flag=="any"){
    this.schedule24=false;
    this.scheduleany=true;
  }else{
    this.schedule24=true;
    this.scheduleany=false;
  }
}
changesdays(flag:string){
  if(flag=="select"){
    this.dayall=false;
    this.dayselect=true;
  }else{
    this.dayall=true;
    this.dayselect=false;
  }
}
  getElement(){
    let relation={
      "include": [
        {"relation": "scheduleDiscounts"},
        {
          "relation": "daysDiscounts",
          
        }, {
        "relation": "discountValidities"
      }, {
        "relation": "discountPerLiterFuels",
        "scope": {
          "include": [{"relation": "discountByFuelType"}]
        }
      },
      {
        "relation": "discountGroupByStations",
        "scope": {
          "include": [{"relation": "discountByFuelType"}]
        }
      },
      {
        "relation": "discountGroupByGroups",
        "scope": {
          "include": [{
            "relation": "group",
            "scope": {
              "include": [{"relation": "stationGroups"}]
            }
          }]
        }
      }
    ]
  };
    this.service.collection = `discounts-groups`;
    if(this.idElement !== undefined){
    this.button="Actualizar";
      this.service.get(this.idElement,'',relation).subscribe(response => {
        if (!response.hasOwnProperty('success')) {
          this.showpage=true;
          let group=this.frmElement.controls.discountGroup as FormGroup;
          let date=this.frmElement.get("validity") as FormGroup;
          let datedis=this.frmElement.get("date") as FormGroup;
          var validy;
          group.patchValue(response);
          this.active=response.status;
          const mail=response.sendEmail!=null?response.sendEmail:false;
          this.activeMail=mail;
          group.controls.sendEmail.setValue(mail);
          for(var i in response.discountValidities ){
            validy=response.discountValidities[i];
          }
          let fechaini = moment(validy.startDate).format();
          let fechafin = moment(validy.endDate).format();
           date.controls.startDate.patchValue(validy.startDate);
            datedis.controls.inidate.patchValue(this.utils.formatDate(fechaini))
            date.controls.endDate.patchValue(validy.endDate);
            datedis.controls.findate.patchValue(this.utils.formatDate(fechafin))
            if(response.scheduleDiscounts){
              var time;
              var schedule= this.frmElement.get("schedule") as FormGroup;
            for(var o in response.scheduleDiscounts){
                time=response.scheduleDiscounts[o];
            }
            var startime=time.startTime.split(":");
            var endtime=time.endTime.split(":");
            schedule.controls.starthour.setValue(startime[0]);
            schedule.controls.startminute.setValue(startime[1]);
            schedule.controls.endhour.setValue(endtime[0]);
            schedule.controls.endminute.setValue(endtime[1]); 
            if(startime[0]=="00"&&startime[1]=="00"&&endtime[0]=="23"&&endtime[1]=="59"){
              this.schedule24=true;
              this.scheduleany=false;
            }else{
              this.schedule24=false;
              this.scheduleany=true;
            }           
          }
          if(response.daysDiscounts){
            for(var d in response.daysDiscounts){
              if(response.daysDiscounts[d].weekdays.length==7){
                this.dayall=true;
                this.dayselect=false;
              }else{
                this.dayall=false;
                this.dayselect=true;
              }
              if(response.daysDiscounts[d].weekdays.length>0){
                for(var p in this.days){
                  for(var day in response.daysDiscounts[d].weekdays){
                      if(this.days[p].name==response.daysDiscounts[d].weekdays[day]){
                          this.days[p].checked=true;
                      }
                    }
                }
              }
            }
          }
          if(response.discountPerLiterFuels&&response.discountPerLiterFuels.length>0){
            this.typefuel=true;
            var discountPerLiter= this.frmElement.get("discountPerLiter") as FormGroup;
            this.frmElement.controls.ObjectPerLiterSelected.setValue(response.discountPerLiterFuels);
            for(var l in response.discountPerLiterFuels){
                if(response.discountPerLiterFuels[l].fuelId=="59e6ed8772492f7fea9dc170"){
                  this.idfuelregular["id"]=response.discountPerLiterFuels[l].id;
                  this.idfuelregular["fuelkey"]=response.discountPerLiterFuels[l].fuelKey;
                  discountPerLiter.controls.regular.setValue(response.discountPerLiterFuels[l].discountByFuelType.discount);
                }
                if(response.discountPerLiterFuels[l].fuelId=="59e6ed8772492f7fea9dc171"){
                  this.idfuelpremium["id"]=response.discountPerLiterFuels[l].id;
                  this.idfuelpremium["fuelkey"]=response.discountPerLiterFuels[l].fuelKey;
                  discountPerLiter.controls.premium.setValue(response.discountPerLiterFuels[l].discountByFuelType.discount);
                }
                if(response.discountPerLiterFuels[l].fuelId=="59e6ed8772492f7fea9dc172"){
                  this.idfueldiesel["id"]=response.discountPerLiterFuels[l].id;
                  this.idfueldiesel["fuelkey"]=response.discountPerLiterFuels[l].fuelKey;
                  discountPerLiter.controls.diesel.setValue(response.discountPerLiterFuels[l].discountByFuelType.discount);
                }
                if(response.discountPerLiterFuels[l].fuelId=="59e6ed8772492f7fea9dc173"){
                  this.idfueldieselmarino["id"]=response.discountPerLiterFuels[l].id;
                  this.idfueldieselmarino["fuelkey"]=response.discountPerLiterFuels[l].fuelKey;
                  discountPerLiter.controls.dieselmarino.setValue(response.discountPerLiterFuels[l].discountByFuelType.discount);
                }
            }

          }else{
            this.frmElement.controls.ObjectPerLiterSelected.setValue("");
          }
          if(!response.discountPerLiterFuels||this.frmElement.controls.ObjectPerLiterSelected.value.length<4){
            this.frmElement.controls.ObjectPerLiter.setValue(false);
          }
          if(response.discountGroupByGroups&&response.discountGroupByGroups.length>0){
            this.groupsbygroupsstationcollapse=true;
          }
            let groupstations=[];  
            for(var k in response.discountGroupByGroups){
                    groupstations.push(response.discountGroupByGroups[k].group.id);
              }
              this.groupsbygroupsstation=response;
              this.frmElement.controls.groupsselected.setValue(groupstations);
              this.frmElement.addControl('groupstationsbd',new FormControl(''));
              this.frmElement.controls.groupstationsbd.setValue(response.discountGroupByGroups);
          if(response.discountGroupByStations){
            this.groupsbystationscollapse=true;
            let discountbystations=[];
            let obj=new Object();
                for(var n in response.discountGroupByStations){
                  discountbystations.push(response.discountGroupByStations[n].stationId);
                }
                this.groupstations=response;
                this.frmElement.controls.stationsselected.setValue(discountbystations);
                this.frmElement.controls.stationsdb.setValue(response.discountGroupByStations);
          }
          if(!response.discountGroupByStations){
            this.groupstations={["discountGroupByStations"]:[]};
          }
        }else{
          this.showpage=false;
          this.router.navigate(['/dashboard/group-discount']);  
          this.notification.show("No tienes permisos para esta acción","error");
        }
        this.changehourendbyElement();
      });
    }else{
      this.showpage=true;
    }
  }
  GetStationSelected($event){
    this.StationSelected.length=0;
    for(var i in $event.selected){
      let listStations={};
      if($event.selected[i].id!=""&&typeof($event.selected[i].id)!="undefined"){
        listStations["stationId"]=$event.selected[i].stationId;
          listStations["discount"]=parseFloat($event.selected[i].discount);
          listStations["name"]=$event.selected[i].name;
          this.StationSelected.push(listStations);
        }
      }
    this.frmElement.controls.stations.setValue(this.StationSelected);
    this.frmElement.controls.stationsselected.setValue($event.selected.map(s => s.id));

  }
  getStations(){
    this.service.collection = 'user-stations';
    let relation=
              {
                "where": {"userId": parseInt(this.session.userId)},
                "include": [{"relation": "user"}]
                };
    this.service.getAll(relation).subscribe(response => {
      if(!response.hasOwnProperty('success')){
      var discountgral=this.frmElement.get("discountGroup").value.discount;
      var discount;  
      if(discountgral!=""){
          discount=parseFloat(discountgral);
        }else{
          discount=0;
        }
        for(var i in response){
          response[i]["discount"]=discount;
        }
        this.stations =response;
        this.frmElement.controls.allstation.setValue(response);
      }
    });
  }
  onSubmit():boolean{
      var dayslist= this.days;
      var data= new Object();
      let arraydays=[];
      if(this.dayall==true){
        for(var i in dayslist){
            dayslist[i].checked=true
            arraydays.push(dayslist[i].name);
        }

      }else{
      for(var i in dayslist){
        if(dayslist[i].checked==true){
          arraydays.push(dayslist[i].name);
        }
      }
    }
      var discountPerLiter=this.frmElement.get("discountPerLiter") as FormGroup;
      var arrayfuel=[];
      if(parseFloat(discountPerLiter.controls.regular.value)!=0&&discountPerLiter.controls.regular.value!=""){
        arrayfuel.push({"discount":parseFloat(discountPerLiter.controls.regular.value),"fuelId":"59e6ed8772492f7fea9dc170","fuelKey":"regular"});
      }
      if(parseFloat(discountPerLiter.controls.premium.value)!=0&&discountPerLiter.controls.premium.value!=""){
        arrayfuel.push({"discount":parseFloat(discountPerLiter.controls.premium.value),"fuelId":"59e6ed8772492f7fea9dc171","fuelKey":"premium"});
      }
      if(parseFloat(discountPerLiter.controls.diesel.value)!=0&&discountPerLiter.controls.diesel.value!=""){
        arrayfuel.push({"discount":parseFloat(discountPerLiter.controls.diesel.value),"fuelId":"59e6ed8772492f7fea9dc172","fuelKey":"diesel"});
      }
      if(parseFloat(discountPerLiter.controls.dieselmarino.value)!=0&&discountPerLiter.controls.dieselmarino.value!=""){
        arrayfuel.push({"discount":parseFloat(discountPerLiter.controls.dieselmarino.value),"fuelId":"59e6ed8772492f7fea9dc173","fuelKey":"dieselmarino"});
      }
      
      var groupdis=this.frmElement.get("discountGroup") as FormGroup
      if(isNaN(groupdis.value.discount)){
        this.notification.show("el valor del descuento debe ser un número válido","error");
        return false;
      }
      /*if(+groupdis.value.discount==0||+groupdis.value.discount==0.0){
        this.notification.show("debe de ser mayor a cero el descuento del grupo","error");
        return false;
      }*/
      groupdis.controls.discount.setValue(parseFloat(this.frmElement.get("discountGroup").value.discount))
      data["discountGroup"]=this.frmElement.get("discountGroup").value;
      data["discountGroup"]["owner"]=parseInt(this.session.userId);
      if(arrayfuel.length>0){
        data["discountPerLiter"]=arrayfuel;
      }
      if(arraydays.length>0){
        data["weekdays"]=arraydays;
      }
      var stations=[];
      var newstations=[];
      stations=this.frmElement.controls.stations.value;
      if(stations.length>0){
        for(var m in stations){
          if(parseFloat(stations[m].discount)!=0&&stations[m].discount!=""&&stations[m].discount!=null){
            newstations.push(stations[m]);
          }else
          {
            this.notification.show("debe de ser mayor a cero el descuento por estacion","error");
            return false;
          }
        }
        if(newstations.length>0){
          data["stations"]=newstations;
        }
      }
      let currendate= new Date();
      var seconds=''+currendate.getSeconds();
      if(seconds.length<2){
        seconds = '0' + seconds;
      }
      if(this.schedule24==true){
        data["schedule"]={
          "startTime":"00:"+"00"+":"+seconds,
          "endTime":"23:"+"59"+":"+seconds
        }
      }else{
      data["schedule"]={
        "startTime":this.frmElement.get("schedule").value.starthour+":"+this.frmElement.get("schedule").value.startminute+":"+seconds,
        "endTime":this.frmElement.get("schedule").value.endhour+":"+this.frmElement.get("schedule").value.endminute+":"+seconds
      }
    }
      var frmdate =this.frmElement.get("validity") as FormGroup;
      data["validity"]=frmdate.value;
      var groustations=this.frmElement.controls.groupsselected.value;
      if(groustations&&groustations.length>0){
        data["groupStations"]=groustations;
      }
      if(this.idElement === undefined){
        this.service.collection ="discounts-groups";
        this.service.create(data).subscribe(response => {
          if(response === null || !response.hasOwnProperty('success')){
            this.notification.show(this.createMessage, 'success');
            this.router.navigate(this.routes);
          }
        });
        return false
      }
      this.service.collection = `discounts-groups`;
      this.service.update(this.idElement, data).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
          this.notification.show(this.updateMessage, 'success');
        this.router.navigate(this.routes);
      }
      this.sending = false;
    });
    
  
  }
  getGroups(){
    this.service.collection = 'groups';
    this.service.getAll().subscribe(response => {
      this.groups=response;
      });
  }
  GetGroupsSelected($event){
  
    this.frmElement.controls.groupsselected.setValue($event.selected.map(s => s.id));

  }
  onDateInputChanged(event: IMyDateModel): void {
    var frmdate =this.frmElement.get("validity") as FormGroup;
    var frminputdis =this.frmElement.get("date") as FormGroup;
    frminputdis.controls.findate.setValue("");
    let currendate= new Date();
    var hours=''+currendate.getHours();
    var minutes=''+currendate.getMinutes();
    var seconds=''+currendate.getSeconds();
    if(hours.length<2){
      hours = '0' + hours;
    }
    if(minutes.length<2){
      minutes = '0' + minutes;
    }
    if(seconds.length<2){
      seconds = '0' + seconds;
    }
    let time=hours+":"+minutes+":"+seconds+"."+currendate.getMilliseconds();
    let date = event.singleDate.formatted+"T"+time+"Z";
    let disabletdate=event.singleDate.formatted.split("-");
    var year=parseInt(disabletdate[0]);
    var moth=parseInt(disabletdate[1]);
    var day=parseInt(disabletdate[2]);
    let copy: IAngularMyDpOptions  = this.getCopyOfOptions();
        copy.disableDateRanges= [{
          begin: {year: year, month: 1, day: 1}, 
          end: {year: year, month: moth, day: day}
      }]
        this.myOptions2 = copy;
        this.defaultMonth.defMonth=disabletdate[1]+"/"+disabletdate[0];
    frmdate.controls.startDate.setValue(date);
  }
  onDateoutputChanged(event: IMyDateModel): void {
    let currendate= new Date();
    var hours=''+currendate.getHours();
    var minutes=''+currendate.getMinutes();
    var seconds=''+currendate.getSeconds();
    if(hours.length<2){
      hours = '0' + hours;
    }
    if(minutes.length<2){
      minutes = '0' + minutes;
    }
    if(seconds.length<2){
      seconds = '0' + seconds;
    }
    let time=hours+":"+minutes+":"+seconds+"."+currendate.getMilliseconds();
    let date = event.singleDate.formatted+"T"+time+"Z";
    var frmdate =this.frmElement.get("validity") as FormGroup;
    frmdate.controls.endDate.setValue(date)  
  }
  private fillTime(limit: number): Array<string> {
    let value: any;
    let elements = [];
    for (let i = 0; i < limit; i++) {
      value = i < 10 ? `0${i}` : `${i}`;
      elements.push(value);
    }
    return elements;
  }
  private Timehours(limit: number,hour:number): Array<string> {
    let value: any;
    let elements = [];
    var newhour=+hour;
    var showhour;
    hour=newhour+1;
    showhour = hour < 10 ? `0${hour}` : `${hour}`;
    let form=this.frmElement.get("schedule") as FormGroup;
    form.controls.endhour.setValue(showhour);
    for (let i = hour; i < limit; i++) {
      value = i < 10 ? `0${i}` : `${i}`;
      elements.push(value);
    }
    return elements;
  }
  private TimehoursByElement(limit: number,hour:number): Array<string> {
    let value: any;
    let elements = [];
    var newhour=+hour;
    hour=newhour;
    let showhour = hour < 10 ? `0${hour}` : `${hour}`;
    let form=this.frmElement.get("schedule") as FormGroup;
    form.controls.endhour.setValue(showhour);
    for (let i = hour; i < limit; i++) {
      value = i < 10 ? `0${i}` : `${i}`;
      elements.push(value);
    }
    return elements;
  }
  changehourend(){
    let form=this.frmElement.get("schedule") as FormGroup;
    let hourini= form.controls.starthour.value;
    if(typeof (form.controls.starthour.value)!="undefined"&&form.controls.starthour.value!=""&&form.controls.starthour.value!=null){
      if(hourini=="23"){
        this.minutes=["00"];
        this.minutesend=["59"];
        this.hoursend=["23"];
        form.controls.startminute.setValue("00");
        form.controls.endhour.setValue("23");
        form.controls.endminute.setValue("59");
      }
      else if(+hourini<10){
          this.hoursend=this.Timehours(24,hourini);
          form.controls.startminute.setValue("00");
          form.controls.endminute.setValue("00");
        
        }
        else{
          this.minutes = this.fillTime(60);
          this.minutesend = this.fillTime(60);
          this.hoursend=this.Timehours(24,hourini);
          form.controls.startminute.setValue("00");
          form.controls.endminute.setValue("00");
        }
    } 
  }
  changehourendbyElement(){
    let form=this.frmElement.get("schedule") as FormGroup;
    let hourini= form.controls.starthour.value;
    if(typeof (form.controls.starthour.value)!="undefined"&&form.controls.starthour.value!=""&&form.controls.starthour.value!=null){
      if(hourini=="23"){
        this.minutes=["00"];
        this.minutesend=["59"];
        this.hoursend=["23"];
        form.controls.startminute.setValue("00");
        form.controls.endhour.setValue("23");
        form.controls.endminute.setValue("59");
      }
      else if(+hourini<10){
          this.hoursend=this.TimehoursByElement(24,form.controls.endhour.value);
        
        }
        else{
          this.minutes = this.fillTime(60);
          this.minutesend = this.fillTime(60);
          this.hoursend=this.TimehoursByElement(24,form.controls.endhour.value);
        }
    } 
  }
  updateGroupStations(){
    let stationserarray=[];
    let stationuserlistDelete=[];
    let stationuserlistAdd=[];
    let updateStation=this.frmElement.controls.groupsselected.value;
    if(typeof(this.frmElement.controls.groupstationsbd)!="undefined"){
    let stationUser=this.frmElement.controls.groupstationsbd.value;
      for(var o in stationUser){
        stationserarray.push(stationUser[o].groupId);
      }  
          stationuserlistDelete=this.verifydeleteElementStation(stationUser,updateStation,stationuserlistDelete);
          stationuserlistAdd= this.AddElementStation(updateStation,stationserarray,stationuserlistAdd);
  }  
    if(stationuserlistAdd.length>0)
        {
          for(var l in stationuserlistAdd){
            this.AddStationUser(stationuserlistAdd[l]);
          }
        }
        if(stationuserlistDelete.length>0)
        {
          for(var p in stationuserlistDelete){
            this.DeleteStationUser(stationuserlistDelete[p]);
          }
        }
      
  }
  AddStationUser(body){
    var obj= new Object();
    obj["groupId"]=parseInt(body.groupId);
    obj["discountGroupId"]=parseInt(body.discountGroupId);
    this.service.collection = `discounts-groups`;
  
    var action="/discount-group-by-groups"
    this.service.update(this.idElement,obj,action,'POST').subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
      }
    }
    );
  }
  DeleteStationUser(id){
    this.service.collection = `discount-group-by-groups`;
    this.service.remove(id).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
      
      }
      this.sending = false;
    });
  }
  verifydeleteElementStation(ElemntUser,updateElement,listDelete){
    for(var u in ElemntUser){
      if(!updateElement.includes(ElemntUser[u].groupId)){
        listDelete.push(ElemntUser[u].id);
      }
    } 
    return listDelete;
  }
  AddElementStation(updateStation,stationserarray,stationuserlistAdd){
    for(var i in updateStation){
      if(!stationserarray.includes(updateStation[i])){
        for(var n in this.groups ){
            for(var a in this.groups[n].userGroups ){
              if(this.groups[n].userGroups[a].groupId==updateStation[i]){
            let addstation=[];
            addstation["groupId"]=this.groups[n].userGroups[a].groupId;
            addstation["discountGroupId"]=this.idElement;
            stationuserlistAdd.push(addstation);  
            }
          }
       }
      }
    }
    return stationuserlistAdd;
  }
  changestatus(day){
    {
      if(day.checked==false){
        day.checked=true;
      }else{
        day.checked=false;
        
      }
    }
  }

  changeStatus( ){
    if (this.active === false) {
      this.active = true;
      this.frmElement.controls.discountGroup.get("status").setValue(true);
    }else {
      this.active = false;
      this.frmElement.controls.discountGroup.get("status").setValue(false);
    }
  }

  changeStatusSendMail( ){
    if (this.activeMail === false) {
      this.activeMail = true;
      this.frmElement.controls.discountGroup.get("sendEmail").setValue(true);
    }else {
      this.activeMail = false;
      this.frmElement.controls.discountGroup.get("sendEmail").setValue(false);
    }
   
  }
  deleteStationGroup(id){
    this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿desea eliminar la estación del grupo?',
    'show',
    id => {
      this.service.collection = `discount-group-by-stations`;
      this.service.remove(id).subscribe(response => {
        this.getElement();
        this.getStations();
        this.getGroups();
        this.notification.show("se elimino la estacion de forma correcta","success");
          if (response === null) {
          }
          this.modal.hide();
        });
    }
    );
  }
  deletebygroups(id){
    this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿desea eliminar  el grupo?',
    'show',
    id => {
      this.service.collection = `discount-group-by-groups`;
      this.service.remove(id).subscribe(response => {
        this.getElement();
        this.getStations();
        this.getGroups();
        this.notification.show("se elimino la estacion de forma correcta","success");
          if (response === null) {
          }
          this.modal.hide();
        });
    }
    );
  }
  addgroupstations(){
    var groups=this.frmElement.controls.groupsselected.value;
    if(groups.length>0){
      for(var i in groups){
      this.service.collection ="discount-group-by-groups";
      var data = new Object();
      data["discountGroupId"]=parseInt(this.idElement);
      data["groupId"]=parseInt(groups[i]);
      this.service.create(data).subscribe(response => {
        if(response === null || !response.hasOwnProperty('success')){
          this.notification.show("se agrego de forma correcta el grupo","success");
          this.getElement();
          this.getStations();
          this.getGroups();
      }
    });
  }
    }
  }
  adddiscountStations(){
    var stationsall=this.frmElement.controls.stations.value;
    let stationselected=[];
    for(var n in stationsall){
        if(parseFloat(stationsall[n].discount)!=0&&!isNaN(stationsall[n].discount)){
          stationselected.push(stationsall[n]);
        }
    }
    if(stationselected.length>0){
    var allstation=this.frmElement.controls.allstation.value;
    for(var i in stationselected){
      for(var a in allstation){
        this.inserdiscountstation(stationselected[i],allstation[a]);
      }
    }
  }else{
    this.notification.show("Debes de agregar una estacion y el descuento","error");
  }
}
inserdiscountstation(stationselected,allstation){
  if(stationselected.stationId==allstation.stationId){
    var data = new Object();
    var data2 = new Object();
    data["discount"] =stationselected.discount;
    this.service.collection ="discount-by-fuel-types";
    data2["stationId"]=allstation.stationId;
      data2["name"]=allstation.name;
      data2["discountGroupId"]=parseInt(this.idElement);
    this.service.create(data).subscribe(response => {
      if(response === null || !response.hasOwnProperty('success')){
      this.service.collection ="discount-by-fuel-types/"+response.id+"/discount-group-by-stations";
      
      data2["discountByFuelTypeId"]=response.id;
      this.service.create(data2).subscribe(res => {
        if(res === null || !res.hasOwnProperty('success')){
            this.notification.show("se agrego la estacion de forma correcta","success");
            this.getElement();
            this.getStations();
            this.getGroups();
        }
      });
    }
  });
      
    }
}
  deletediscountypefuel(id){
    if(id["id"]&&id["id"]!=""){
      var idfuel=id["id"];
      var typefuel=id["fuelkey"];
      this.modal.show(
        idfuel,
        'warning',
      'Tipo de gasolina',
      '¿Esta seguro que desea eliminar el tipo de gasolina?',
      'show',
      idfuel => {
        this.service.collection = `discount-per-liter-fuels`;
        this.service.remove(idfuel).subscribe(response => {
            if (response === null || response.count === 1) {
              this.notification.show("Elemento eliminado correctamente","success");
              this.getElement();
              var fuel=this.frmElement.get("discountPerLiter") as FormGroup;
              fuel.get(typefuel).setValue(0);
              if(typefuel=="regular"){
                this.idfuelregular=[];
              }
             else if(typefuel=="premium"){
                this.idfuelpremium=[];
              }
              else if(typefuel=="diesel"){
                this.idfueldiesel=[];
              }
              else if(typefuel=="dieselmarino"){
                this.idfueldieselmarino=[];
              }
            }
            this.modal.hide();
          });
      }
      );
    }
  }
  deletedalliscountypefuel(id=''){
    var arraydelete=[];
    if(this.idfuelregular["id"]){
      arraydelete.push(this.idfuelregular);
    }
    if(this.idfuelpremium["id"]){
      arraydelete.push(this.idfuelpremium);
    }
    if(this.idfueldiesel["id"]){
      arraydelete.push(this.idfueldiesel);

    }
    if(this.idfueldieselmarino["id"]){
      arraydelete.push(this.idfueldieselmarino);
    }
    if(arraydelete.length>0){
    this.modal.show(
      id,
      'warning',
    'Tipos de gasolina',
    '¿Esta seguro que desea eliminar todos los descuentos de gasolina?',
    'show',
    id => {
      this.service.collection = `discount-per-liter-fuels`;
      for(var i in arraydelete){
        this.deleteall(arraydelete[i]);
      }
    }
    );
  }else{
    this.notification.show("No hay ningun descuento por eliminar","error");
  }
  }
  deleteall(arraydelete){
    var typefuel=arraydelete["fuelkey"];
    this.service.remove(arraydelete["id"]).subscribe(response => {
      if (response === null || response.count === 1) {
        this.notification.show("Elementos eliminado correctamente","success");
        this.getElement();
        var fuel=this.frmElement.get("discountPerLiter") as FormGroup;
        fuel.get(typefuel).setValue(0);
        if(typefuel=="regular"){
          this.idfuelregular=[];
        }
     if(typefuel=="premium"){
          this.idfuelpremium=[];
        }
      if(typefuel=="diesel"){
          this.idfueldiesel=[];
        }
      if(typefuel=="dieselmarino"){
          this.idfueldieselmarino=[];
        }
      }
      this.modal.hide();
    });
  }
  updatediscount(indice,station){
    var input="discount-"+indice;
    var discount=((document.getElementById(input) as HTMLInputElement).value);
    if(discount!="0"&&discount!=""){
      this.service.collection = `discount-by-fuel-types`; 
      var data= new Object();
      data["discount"]=parseFloat(discount);
    this.service.update(station.discountByFuelType.id,data).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
        this.notification.show("descuento actualizado correctamente","success");
        this.getElement();
      }
    }
    );
    }else{
      this.notification.show("no puedes dejar vacio el descuento y debe ser mayor a 0","error");
    }
  } 

}
