import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../common/breadcrumb/service/breadcrumb.service';
import { FormViewComponent } from '../../../common/form/form-view.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseFormComponent } from 'src/app/base/form/form.component';

@Component({
  selector: 'cms-gulf-add-edit-stations',
  templateUrl: './add-edit-stations.component.html',
  styleUrls: ['./add-edit-stations.component.scss']
})
  export class AddEditStationsComponent extends BaseFormComponent implements OnInit {
    stations=[];
    users=[];
    idgroup:string;
    StationSelected=[];
    UsersSelected=[];
    date:string;
    iduser:string;
    button:string;
    allstation=[];
    ngOnInit(): void {
      this.button="Crear";
      this.getstationGroups();
      this.getUserStations();
      this.getUsers();
    this.getFormateDate();
    this.getElement();
      this.iduser=this.session.userId;
      this.breadcrumb.links({
        links: [
          { link: '/dashboard/group-stations', label: 'Listado de Estaciones'},
          { link: '', label: this.idElement ? 'Actualizar Grupo' : 'Crear Grupo'}
        ],
        title: this.idElement ? 'Actualizar Grupo' : 'Agregar Estaciones'
      });
  
      this.frmElement = new FormGroup({
        group: new FormGroup({
          name: new FormControl('',[Validators.required]),
          registrationDate: new FormControl(this.date),
        }),
        user: new FormControl(''),
        // user:new FormControl([]),
        stations: new FormControl('',[Validators.required]),
        // rol: new FormControl('', [Validators.required])
      });
      this.createMessage = 'El grupo se agrego correctamente';
    this.updateMessage = 'Se actualizaron los datos del grupo';
    this.routes = ['dashboard', 'users'];
      this.routes = ['dashboard', 'group-stations'];
    }
    onSubmit():boolean{
      if(this.frmElement.controls.user.value.length>0){
        this.frmElement.addControl('users', new FormControl(this.frmElement.controls.user.value));
      }
      let data=this.frmElement.value;
      if(this.idElement === undefined){
      var datos= new Object();
      datos["group"]=data.group;
      datos["stations"]=data.stations;
      datos["group"]["owner"]=parseInt(this.session.userId);
      datos["users"]=Array();
      datos["users"].push(parseInt(this.session.userId));
      if(this.frmElement.controls.user.value.length>0){
        datos["users"]=data.user;
        datos["users"].push(parseInt(this.session.userId));
      }
      this.service.collection = `groups`;
      this.service.create(datos).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
              this.notification.show(this.createMessage, 'success');
            this.router.navigate(this.routes);
        }
        this.sending = false;
      });
      return false
    }
    var group=this.frmElement.controls.group.value;
    this.service.collection = `groups`;
    data= new Object();
    data=group;
      this.service.update(this.idElement, data).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
        this.notification.show(this.updateMessage, 'success');
        this.updateGroupStations();
        this.updateUsers();
        this.router.navigate(this.routes);
      }
      this.sending = false;
    });
  }
  validateStores(){
    let data=this.frmElement.value;
    this.service.collection = 'mercado-pago/stores';
    this.service.getAll().subscribe(async response => {
     const stations= await Promise.all(
      response.map(currentstore=>{
          return  data.stations.find(station=>{
            if(currentstore.station_id==station){ 
              return station;
            }
          });
        }).filter(item=>item!=undefined)
      );
      if(stations.length==data.stations.length){
        this.onSubmit();
      }else{
        this.notification.show("Verifique que todas las estaciones tengan una sucursal asociada","error");
        return false;
      }
     });
  }
    verifystations(stations){
    let res;
      if(this.idElement==undefined||this.idElement=="undefined"){
       res=this.allstation.map(station=>station).filter(list=>stations.includes(list.stationId));
    }else{
      res=this.allstation.map(station=>station).filter(list=>stations.includes(list.stationId)&&list.groupId!=this.idElement);
    }
     let listation=[];
      res.map(namstation=>{
        if(!listation.includes(namstation.name)){
          listation.push(namstation.name);
        }
     });
     if(listation.length>0){
      this.showmessage(listation);
      return false;
     }else{
       return true;
     }
    }
    getstationGroups(){

      this.service.collection = '/station-groups';
      this.service.getAll().subscribe(response => {
        if(!response.hasOwnProperty('success')){
            if(response.length>0){
              response.map(station=>{
                this.allstation.push(station);
              })
              
            }
          }
      });
    }
    showmessage(listation){
      this.modallist.show("error","La o las estaciones  pertence a algún grupo",listation,"show");
    }
    getUserStations(){
      let station={};
      let restations=[];
    let stationexist=[];
      this.service.collection = 'user-stations';
        this.service.getAll().subscribe(response => {
        if(!response.hasOwnProperty('success')){
          response.map(station=>{
            this.allstation.push(station);
          });
          this.allstation.filter(avaliablestation=>{
              if(avaliablestation.groupId!=null&&avaliablestation.groupId!="null"){
                if(avaliablestation.groupId!=this.idElement){
                  if(!stationexist.includes(avaliablestation.stationId)){
                    stationexist.push(avaliablestation.stationId);
                }
                }
              }
          });
          let list= response.filter(liststation=>!stationexist.includes(liststation.stationId));
         let result=list;
         if(result.length>0){
            result.map(res=>{
            if(res.userId==this.session.userId){
              station={};
              station["id"]=res.id;
              station["stationId"]=res.stationId;
              station["name"]=res.name;
              station["groupId"]=res.groupId;
              restations.push(station);
            }
          });
        }
            this.stations=restations;
          }
      });
    }
    getUsers(){
      if(this.idElement!=undefined){
        this.idgroup=this.idElement;
      }
      var user=[];
      var iduser=parseInt(this.session.userId);
      this.service.collection = 'users';
      this.service.getAll().subscribe(response => {
        if(!response.hasOwnProperty('success')){
          for(var i in response){
            if(response[i].id!=iduser&&response[i].email!="api_discount@gulf.mx"){
              user.push(response[i]);
            }
          }
          this.users=user;
          }
      });
    }
    GetStationSelected($event){
  
      this.frmElement.controls.stations.setValue($event.selected.map(s => s.stationId));  
    }
    GetUsersSelected($event){
      
      this.UsersSelected.length=0;
    for(var i in $event.selected){
      if($event.selected[i].id!=""&&typeof($event.selected[i].id)!="undefined"){
        this.UsersSelected.push($event.selected[i].id);
      }
    }
    this.frmElement.controls.user.setValue($event.selected.map(s => s.id));
  
    }
    getFormateDate(){
      let currendate= new Date();
      var day=''+currendate.getDate();
      var month=''+(currendate.getMonth()+1)
      var hours=''+currendate.getHours();
      var minutes=''+currendate.getMinutes();
      var seconds=''+currendate.getSeconds();
        if(day.length<2){
          day = '0' + day;
        }
        if(month.length<2){
          month = '0' + month;
        }
        if(hours.length<2){
          hours = '0' + hours;
        }
        if(minutes.length<2){
          minutes = '0' + minutes;
        }
        if(seconds.length<2){
          seconds = '0' + seconds;
        }
      let time=hours+":"+minutes+":"+seconds+"."+currendate.getMilliseconds();
          this.date =currendate.getFullYear()+"-"+month+"-"+day+"T"+time+"Z";
    }

    getElement(){
      let relation={
        "include": [
           {
          "relation": "userGroups",
          "scope": {
            "include": [
              {"relation": "user"}
            
            ]
        }
      },
        {
          "relation": "stationGroups"
        }
      ]
    };
      this.service.collection = `groups`;
      if(this.idElement !== undefined){
        this.button="Actualizar";
        let station=[];
        let user=[];
        this.service.get(this.idElement,'',relation).subscribe(response => {
          if (!response.hasOwnProperty('success')) {
            var groups=this.frmElement.controls.group as FormGroup;
            groups.patchValue(response);
            for(var o in response.stationGroups){
              station.push(response.stationGroups[o].stationId);
            } 
            for(var i in response.userGroups){
              user.push(response.userGroups[i].userId);
          } 
            this.frmElement.controls.stations.setValue(station);
            this.frmElement.addControl('stationsbd',new FormControl(''));
            this.frmElement.controls.stationsbd.setValue(response.stationGroups);
            this.frmElement.addControl('usersbd',new FormControl(''));
            this.frmElement.controls.usersbd.setValue(response.userGroups);
            this.frmElement.controls.user.setValue(user);
            this.frmElement.controls.stations.clearValidators();
          this.frmElement.controls.stations.updateValueAndValidity();
            
          }
        });
      }
    }

    updateGroupStations(){
      let stationserarray=[];
      let stationuserlistDelete=[];
      let stationuserlistAdd=[];
      let updateStation=this.frmElement.controls.stations.value;
      if(typeof(this.frmElement.controls.stationsbd)!="undefined"){
      let stationUser=this.frmElement.controls.stationsbd.value;
        for(var o in stationUser){
          stationserarray.push(stationUser[o].stationId);
        }  
            stationuserlistDelete=this.verifydeleteElementStation(stationUser,updateStation,stationuserlistDelete);
            stationuserlistAdd= this.AddElementStation(updateStation,stationserarray,stationuserlistAdd);
    
    }  
      if(stationuserlistAdd.length>0)
          {
            for(var l in stationuserlistAdd){
              this.AddStationUser(stationuserlistAdd[l]);
            }
          }
          if(stationuserlistDelete.length>0)
          { 
            
            try{
              let stations=this.frmElement.controls.stationsbd.value;
            if(stations.length==stationuserlistDelete.length){
              throw "No puedes dejar un grupo  sin estaciones";
            }else{
            for(var p in stationuserlistDelete){
              this.DeleteStationUser(stationuserlistDelete[p]);
            }
          }
          }catch(e){
              this.notification.show(e,"error");
          }
        }
        
    }

    updateUsers(){
      let stationserarray=[];
      let stationuserlistDelete=[];
      let stationuserlistAdd=[];
      let updateStation=this.frmElement.controls.user.value;
      let stationUser=this.frmElement.controls.usersbd.value;
            if(typeof(this.frmElement.controls.usersbd)!="undefined"){
              for(var o in stationUser){
                stationserarray.push(stationUser[o].userId);
              }       
            stationuserlistDelete=this.verifydeleteElementUser(stationUser,updateStation,stationuserlistDelete);
            stationuserlistAdd= this.AddUser(updateStation,stationserarray,stationuserlistAdd);
    }  
      if(stationuserlistAdd.length>0)
      
          {
            for(var l in stationuserlistAdd){
              this.AddUserslist(stationuserlistAdd[l]);
            }
          }
          if(stationuserlistDelete.length>0)
          {
            for(var p in stationuserlistDelete){
              this.DeleteUser(stationuserlistDelete[p]);
            }
          }
        
    }


    verifydeleteElementStation(ElemntUser,updateElement,listDelete){
      for(var u in ElemntUser){
        if(!updateElement.includes(ElemntUser[u].stationId)){
          listDelete.push(ElemntUser[u].id);
        }
      } 
      return listDelete;
    }
    verifydeleteElementUser(ElemntUser,updateElement,listDelete){
      updateElement=this.getNewListUser(ElemntUser,updateElement);
      for(var u in ElemntUser){
        if(!updateElement.includes(ElemntUser[u].userId)){
          listDelete.push(ElemntUser[u].id);
        }
      } 
      return listDelete;
    }
    getNewListUser(listbduser,listupdateuser){
      const idus=+this.session.userId;
      for(var i in listbduser){
        if(listbduser[i].user.id==idus){
          if(!listupdateuser.includes(listbduser[i].user.id)){
            listupdateuser.push(listbduser[i].user.id);
          }
        }
      }
      return listupdateuser;
    }
    AddElementStation(updateStation,stationserarray,stationuserlistAdd){
      for(var i in updateStation){
        if(!stationserarray.includes(updateStation[i])){
          for(var n in this.stations ){
            if(this.stations[n].stationId==updateStation[i]){
           let addstation=[];
           addstation["name"]=this.stations[n].name;
           addstation["stationId"]=updateStation[i];
           addstation["groupId"]=this.idElement;
           stationuserlistAdd.push(addstation);  
          }
         }
        }
      }
      return stationuserlistAdd;
    }
    AddUser(updateStation,stationserarray,stationuserlistAdd){
      for(var i in updateStation){
        if(!stationserarray.includes(updateStation[i])){
           let addstation=[];
           addstation["userId"]=updateStation[i];
           addstation["groupId"]=this.idElement;
           stationuserlistAdd.push(addstation);  
        }
      }
      return stationuserlistAdd;
    }
    DeleteStationUser(id){
     
      this.service.collection = `station-groups`;
      this.service.remove(id).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
        
        }
        this.sending = false;
      });
    }
    DeleteUser(id){
     
      this.service.collection = `user-groups`;
      this.service.remove(id).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
        
        }
        this.sending = false;
      });
    }
    AddStationUser(body){
      var obj= new Object();
      obj["name"]=body.name;
      obj["stationId"]=body.stationId;
      obj["groupId"]=parseInt(body.groupId);
      this.service.collection = `groups`;
      var action="/station-groups"
      this.service.update(this.idElement,obj,action,'POST').subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
        }
      }
      );
    }
    AddUserslist(body){
      var obj= new Object();
      obj["userId"]=parseInt(body.userId);
      obj["groupId"]=parseInt(body.groupId);
      this.service.collection = `user-groups`;
      this.service.create(obj).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
        
        }
        this.sending = false;
      });
    }
  
  }
