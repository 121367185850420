import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Modulos
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { ValidateModule } from './utils/validate/validate.module';


// Services
import { ValidateService } from './utils/service/validate.service';
import { RequestService } from './request/request.service';
import { NotificationService }  from './common/notification/notification.service';
import { SessionService } from './utils/session/session.service';
import { AuthService } from './components/login/auth/auth.service';
import { ModalService } from './common/notification/modal/modal.service';
import { ModaListService } from './common/notification/modal-list/modal-list.service';
// Componentes
import { AppComponent } from './app.component';
import { ModalComponent } from './common/notification/modal/modal.component';
import { LoginComponent } from './components/login/login.component';

import { NotificationComponent } from './common/notification/notification.component';
import { AlertComponent } from './common/notification/alert/alert.component';
import { AccessGuard } from '../app/utils/access/access.guard';
import { ModalListComponent } from './common/notification/modal-list/modal-list.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { CongratulationsComponent } from './components/congratulations/congratulations.component'; 

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotificationComponent,
    ModalComponent,
    AlertComponent,
    ModalListComponent,
    CongratulationsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ValidateModule,
    DashboardModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  providers: [
    ValidateService,
    SessionService,
    RequestService, 
    NotificationService,
    ValidateService,
    AuthService,
    ModalService,
    AccessGuard,
    ModaListService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
