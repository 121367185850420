<h3 class="text-center tittle-group" *ngIf="update!=true"> Se creará un sucursal para la estación: {{stationselected}}</h3>  
<form action="" *ngIf="show==true"  autocomplete="off" [formGroup]="frmElement" class="form-content form-group" (submit)="onSubmit()">
    <label for="">Ingrese el nombre de la sucursal *</label>
    <input type="text" formControlName="name">
    <div class="row">
        <div class="col-5">
            <div id="schedule" class="row mb-3">
                <div class="col-12">
                  <div class="form-group pl-2 col-12" formGroupName="schedule">
                    <label for="schedule">Horario en que aplica</label>
                    <div class="row">
                      <input type="button" value="24 horas" [className]="schedule24?'bg-schedule' : ''" (click)="changeschedule('all')">
                      <input type="button" value="Asignar Horario" [className]="scheduleany?'bg-schedule ml-2' : 'ml-2'" (click)="changeschedule('any')">
                    </div>
                    <ul class="row" *ngIf="schedule24!=true">
                      <p>Inicio</p>
                      <li class="col-3">
                        <select name="starthour" id="cboHourStart" formControlName="starthour" >
                          <option *ngFor="let hour of hours; let index = i" value="{{hour}}">{{hour}}</option>
                        </select>
                      </li>
                      <li class="col-2">
                        <label for="starthour">Hora</label>
                      </li>
                      <li class="col-3">
                        <select name="startminute" id="startminute" formControlName="startminute" >
                          <option *ngFor="let minute of minutes; let index = i" value="{{minute}}">{{minute}}</option>
                        </select>
                      </li>
                      <li class="col-2">
                        <label for="startminute">Minutos</label>
                      </li>
                    </ul>
                    <ul class="row" *ngIf="schedule24!=true">
                      <p>Fin</p>
                      <li class="col-3">
                        <select name="endhour" id="endhour" formControlName="endhour">
                          <option *ngFor="let hour of hoursend; let index = i" value="{{hour}}">{{hour}}</option>
                        </select>
                      </li>
                      <li class="col-2">
                        <label for="endhour">Hora</label>
                      </li>
                      <li class="col-3">
                        <select name="endminute" id="endminute" formControlName="endminute" >
                          <option *ngFor="let minute of minutesend; let index = i" value="{{minute}}">{{minute}}</option>
                        </select>
                      </li>
                      <li class="col-2">
                        <label for="cboMinuteStart">Minutos</label>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
                 <div class="col-6 ">
                   <label class="pl-4">Días de la semana</label>
                    <div class=" pl-4 row ">
                        <input type="button" value="Todos los días"  [className]="dayall?'bg-schedule' : ''" (click)="changesdays('all')">
                      <input type="button" value="Asignar días" [className]="dayselect?'bg-schedule ml-2' : 'ml-2'" (click)="changesdays('select')">
                    </div>
                        <div id="days" class="pl-4 d-flex mt-1" *ngIf="dayall!=true">
                          <div class="check-box" *ngFor="let day of days let i = index" >
                            <input type="checkbox"  id="chkStatus{{i}}" [checked]="day.checked"  (click)="changestatus(day);">
                            <label for="chkStatus{{i}}">
                              <span></span>
                            </label><br>
                            <span class="status-active">{{day.key}}</span>
                          </div>
                        </div>
                 </div>
    </div>

    <div id="address" formGroupName="location">
        <div class=" row ">
            <div class="form-group col-4 ">
                <div class="p-10">
                    <label for="">No. de calle *</label>
                    <input type="text"  formControlName="street_number">
                </div>
            </div>
            <div class="form-group col-4 ">
              <div class="p-10">
                  <label for="">Nombre de calle *</label>
                  <input type="text"  formControlName="street_name">
              </div>
          </div>
            <div class="form-group col-4 ">
              <div class="p-10">
                <label for="">Estado*</label>
                <select name="state_name" id="state_name"   (change)="setCities($event.target.value)" class="select-css" formControlName="state_name">
                    <option value="">--seleccionar--</option>
                    <option id="{{item.id}}" value="{{item.name}}" *ngFor="let item of states">{{item.name}}</option>
                </select>
            </div>
            </div>
        </div>
        <div class=" row ">
          <div class="form-group col-4">
            <div class="p-10">
              <label for="">Ciudad *</label>
              <select name="" id="" class="select-css" formControlName="city_name">
                  <option value="">--seleccionar--</option>
                  <option value="{{item.name}}" *ngFor="let item of cities">{{item.name}}</option>
              </select>
          </div>
        </div>
            <div class="form-group col-4 ">
                <div class="p-10">
                    <label for="">Latitud*</label>
                    <input type="text" formControlName="latitude">
                </div>
            </div>
            <div class="form-group col-4 ">
                <div class="p-10">
                    <label for="">Longitud*</label>
                    <input type="text" formControlName="longitude">
                </div>
            </div>
            <div class="form-group col-4">
                <div class="p-10">
                    <label for="">Referencia</label>
                    <input type="text" formControlName="reference">
                </div>
            </div>
        </div>
    </div>
    <div class="form-actions text-right">
        <span  class="mr-2" [class.loading]="sending">
          <input type="submit"  value="{{button}}" [disabled]="frmElement.invalid" >
        </span>
        <a class="btn-default cancel" routerLink="/dashboard/stations">Cancelar</a>
      </div>
</form>
