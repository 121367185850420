import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs/observable/timer';
//- Service
import { AlertService } from './alert.service';
//-
const MODE = {
  OPEN: 'open',
  CLOSE: 'close'
}
@Component({
  selector: 'admin-gulf-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  private timer;
  type: string;
  status: string;
  message: string;
  constructor(
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.alert.currentMessage.subscribe((response) => {
      this.message = response.message;
      this.type = response.type;
      if (this.message !== '') {
        this.status = MODE.OPEN;
      }
    });
  }

  close(): void {
    if (this.status !== '') {
      this.status = MODE.CLOSE;
      this.timer = timer(4000).subscribe(i => this.status = '');
    }
  }
}
