import { Component, OnInit } from '@angular/core';
import { BaseListComponent } from '../../../../base/list/list.component';
import * as moment from 'moment';
@Component({
  selector: 'cms-gulf-list-register',
  templateUrl: './list-register.component.html',
  styleUrls: ['./list-register.component.scss']
})
export class ListRegisterComponent extends BaseListComponent implements OnInit {
pos=[];
stores=[];

  ngOnInit(): void {
    this.getStore();
    this.getPos();
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de cajas'}
        ],
        title: 'Listado de cajas'
      }
    );
  }
  getPos(){
    moment.locale('es');
    this.service.collection = 'mercado-pago/pos';
    this.service.getAll().subscribe(response => {
        let res=response.map(result=>{
          result.pos.date_created= moment(result.pos.date_created).format("LL");
          return result;
        })
       this.pos=res;
    });
  }
  getStore(){
    this.service.collection = 'mercado-pago/stores';
    this.service.getAll().subscribe(response => {
       this.stores=response;
    });
  }
}
