<form action="" class="form-content" autocomplete="off"  >
    <h2 class="text-center f-15">Datos de la orden</h2>
    <div class="containter" *ngIf="order!=undefined&&order['order']">
        <div class="row">
            <div class="col-4">
                <p class=""><span><b>No. Orden:</b> </span> {{order["order"].orderSaleId}}</p>
            </div>
            <div class="col-4">
                <p class=""><span><b>No. Orden Mercado Pago:</b> </span> {{order["order"].orderId}}</p>
            </div>
            <div class="col-4">
                <p class=" text-danger"><span><b>Estatus orden:</b> </span> {{order["order"].statusOrder}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <p class=" text-danger"><span><b>Estatus pago:</b> </span> {{order["order"].statusPayment}}</p>
            </div>
            <div class="col-4">
                <p class=" "><span><b>Fecha:</b> </span> {{order["order"].dateCreate}}</p>
            </div>
            <div class="col-4">
                <p class=" "><span><b>Nombre de la sucursal:</b> </span> {{order["order"].externalStoreId}}</p>
            </div>
        </div>
        <h2 class=" f-15 mt-3 mb-2">información caja</h2>
        <div class="row">
            <div class="col-4">
                <p class=""><span> <b>Nombre de caja:</b>  </span> {{order["order"].externalPosId}}</p>
            </div>
            <div class="col-4">
                <p class=""><span> <b>Nombre de empleado:</b> </span> {{order["employeeName"]}}</p>
            </div>
        </div>
        <h2 class=" f-15 mt-3 mb-2">Productos</h2>
        <div class="row">
            <table class="table-order">
                <thead class="mt-4">
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Monto</th>
                </thead>
                <tbody *ngIf="order['details'].items.length>0">
                    <tr *ngFor="let product of order['details'].items">
                    <td class="text-center">
                        {{product.description}}
                    </td>
                    <td class="text-center">
                        {{product.quantity}}
                    </td>
                    <td class="text-center">
                        ${{product.unit_price*product.quantity}}
                    </td>
                    </tr>
                    <tr class="mt-4">
                       <td colspan="3">
                           <p class="float-right"><b> Subtotal:</b> <span>  ${{this.total_amount}}</span></p>
                       </td>
                    </tr>
                    <tr class="">
                       <td colspan="3">
                           <p class="float-right"><b> Total:</b> <span>  ${{this.total_amount}}</span></p>
                       </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>