
  <div class="filter" [ngClass]="{ show: status, close: close }">
    <form action="" [formGroup]="frmFilters" autocomplete="off" (ngSubmit)="searchvalue()">
      <fieldset>
        <legend>Búsqueda por nombre de estación</legend>
        <ul class="row">
          <li class="col-6">
            <label for="txtName">Nombre de la estación</label>
            <input type="text" name="name" id="txtName" formControlName="name">
          </li>
          <li class="col-6">
            <p>
              <a class="btn-default clear" (click)="clearsearch()">Limpiar</a>
              <span [class.loading]="sending">
                <button type="submit" class="btn-default">
                  Buscar
                </button>
              </span>
            </p>
          </li>
        </ul>
      </fieldset>
    </form>
  </div>
  
  <table #table>
    <thead>
      <tr >
        <th class="text-center">Estaciones</th>
        <th class="text-center">Sucursal</th>
        <th class="text-center">Sincronizar</th>
        <th class="text-center">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of stores | paginate: { itemsPerPage: 10, currentPage: page }">
        <td class="text-center">{{item.station.name}}</td>        
        <td class="text-center" *ngIf="item.store!=null; else withoutStore">{{item.store.name}}</td>
        <ng-template #withoutStore>
          <td class="text-center data-none" > 
            Sin sucursal
          </td>
        </ng-template>
        <td class="text-center " *ngIf="item.station.authorizationReminder!=null;else sync">
           <p class="text-blue">Sincronizada</p> 
          <!-- <div class="edit d-flex-discount" >
            <a  class="icon-sync ml-2" (click)="getLink(item.stationKey)"  > </a>
          </div> -->
        </td>   
        <ng-template #sync>
          <td class="text-center ">
            <div class="edit d-flex-discount" >
            <a  class="icon-sync ml-2" (click)="getLink(item.station.stationKey)"  > </a>
          </div>
          </td>
        </ng-template>
          <td class="text-center " *ngIf="item.store!=null;else addstore">
            <p class="text-blue">Sucursal Asociada</p>
          </td>  
          <ng-template #addstore >
            <td class="text-center" *ngIf="item.station.authorizationReminder!=null; else withoutstore">
              <a     routerLink="/dashboard/stations/add/{{item.station.stationId}}?" class="status-active" > Asociar sucursal</a>   
            </td>
          </ng-template>
          <ng-template #withoutstore>
            <td class="text-center">
              <p class="data-none">Requiere sincronización</p>
            </td>
          </ng-template>
      </tr>
    </tbody>
  </table>
<pagination-template #p="paginationApi"(pageChange)="page = $event" *ngIf="stores.length>0">
    <nav class="pagination" aria-label="Paginación de la tabla">
      <ul>
        <li class="prev" > 
          <a *ngIf="!p.isFirstPage()"  (click)="p.previous()">
            <span class="icon-prev" [class.disabled]="p.isFirstPage()"></span>
          </a>
        </li>
        <li class="page">
          <a class="disabled" >{{page}}</a>
        </li>
        <li class="next">
          <a *ngIf="!p.isLastPage(); else showlast"  (click)="p.next()">
            <span class="icon-next" [class.disabled]="p.isLastPage()"></span>
          </a>
          <ng-template #showlast>
            <a class="link-off">
              <span class="icon-next " ></span>
            </a>
          </ng-template>
        </li>
      </ul>
    </nav>
</pagination-template>