
  <table #table>
    <thead>
      <tr >
        <th class="text-center">Nombre</th>
        <th class="text-center">Fecha de Registro</th>
        <th class="text-center">Sucursal</th>
        <th class="text-center">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of pos">
        <td class="text-center">{{item.pos.name}}</td>
        <td class="text-center edit " > 
          {{item.pos.date_created}}
         </td>
        <td class="text-center">Sucursal vfn</td>        
          <td class="text-center " >
          
          </td>  
      </tr>
    </tbody>
  </table>
