import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseListComponent } from '../../../../base/list/list.component';
import * as moment from 'moment';

@Component({
  selector: 'cms-gulf-list-stations',
  templateUrl: './list-stations.component.html',
  styleUrls: ['./list-stations.component.scss']
})
export class ListStationsComponent extends BaseListComponent implements OnInit {
  listgroups: [];
  allstation=[];
  stations=[];
  ngOnInit(): void {
    this.GetUsers();
    this.getUserStations();
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de estaciones disponibles'}
        ],
        title: 'Listado de estaciones disponibles'
      }
    );
    this.frmFilters = new FormGroup({
      name: new FormControl(''),
      data: new FormControl(''),
      userslist: new FormControl('')
    });
  }
  searchvalue(){
    var data= this.frmFilters.controls.data.value;
    var valueseach=this.frmFilters.controls.name.value;  
   let newlist= []
    if(valueseach!=""&&valueseach!=null){
              for(var i in data){
                if(data[i].name.includes(valueseach)){
                  newlist.push(data[i]);
                }
              }
      //  this.listgroups=newlist;       
    }
  }
  clearsearch(){
    this.frmFilters.controls.name.setValue('');
    if(typeof(this.frmFilters.controls.data)!="undefined"){
      // this.listgroups=this.frmFilters.controls.data.value;
    }
  }
  private GetUsers(){
    
    this.service.collection = 'users';
    this.service.getAll().subscribe(async response => {
      if(!response.hasOwnProperty('success')){
        this.frmFilters.controls.userslist.setValue(response);
        await this.getGroups();
        await this.getUserStations();
      }
    });
  }
  getGroups(){
    var listuser=this.frmFilters.controls.userslist.value;
    this.service.collection = 'groups';
    let stations=[];
    let users=[];
    let roluser=[];
    this.service.getAll().subscribe(response => {
      for(var user in listuser){
        if(listuser[user].id==this.session.userId){
          for(var listrol in listuser[user].userRoles){
              if(!roluser.includes(listuser[user].userRoles[listrol].role.name)){
                    roluser.push(listuser[user].userRoles[listrol].role.name)
              }
          }
        }
      }
      let role=roluser;
      for(var i in response){
          stations=[];
          users=[];
        moment.locale('es');
        response[i].registrationDate= moment(response[i].registrationDate).format("LL");
        if(role.length>1&&role.includes("GROUPDISCOUNT")){
          if(response[i].default&&response[i].default==true){
            for(var p in response[i].defaultStationGroups){
              stations.push(response[i].defaultStationGroups[p].name);
            }
          }else{
            for(var o in response[i].stations){
              stations.push(response[i].stations[o].name);
            }
          }
          if(response[i].user){
            if(!users.includes(response[i].user.name)){
              users.push(response[i].user.name);
            }
         }
      }
      else if(role.includes("ADMIN")&&!role.includes("GROUPDISCOUNT")){
        for(var o in response[i].stations){
          stations.push(response[i].stations[o].name);
          
        }
       if(response[i].user){
          if(!users.includes(response[i].user.name)){
            users.push(response[i].user.name);
          }
       }
      }
      response[i].stations.map(station=>{this.allstation.push(station)});
      response[i].user=users.toString();
      }
      this.listgroups=response;
      });
  }
  getUserStations(){
    this.service.collection = 'user-stations';
    let listation=[];
      this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        response.map((station,indice)=>{
          if(station.userId==this.session.userId){
            listation.push(station);
          }
          
        });
        this.stations=listation;
      }
    });
  }
  getstationcurrentuser(){
    let stationexist=[];
    this.allstation.map(  (avaliablestation,indice)=>{
      if(avaliablestation.groupId!=null&&avaliablestation.groupId!="null"){
        if(!stationexist.includes(avaliablestation.stationId)){
          stationexist.push(avaliablestation.stationId);
        }
        }
      });
      let list= this.allstation.filter(liststation=>{
        return !stationexist.includes(liststation.stationId);
      });
      var hash = {};
        let result = list.filter(function(current) {
          var exists = !hash[current.id];
          hash[current.id] = true;
          return exists;
        });
        this.stations=result;
  }

}
