import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BaseListComponent } from '../../../../base/list/list.component';
@Component({
  selector: 'cms-gulf-list-userb2c',
  templateUrl: './list-userb2c.component.html',
  styleUrls: ['./list-userb2c.component.scss']
})
export class ListUserb2cComponent extends BaseListComponent implements OnInit {
  userb2c;
  namegroup:string;
  showpage:boolean;
  ngOnInit(): void {
    this.getgroupdiscount();
    this.getusersb2c();
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado Grupo de Descuentos'}
        ],
        title: 'Listado de Usuarios B2C'
      }
    );
    this.frmFilters = new FormGroup({
      name: new FormControl(''),
      data: new FormControl(''),
      userslist: new FormControl('')
    });
  }
  searchvalue(){
    var data= this.frmFilters.controls.data.value;
    var valueseach=this.frmFilters.controls.name.value;  
   let newlist= []
    if(valueseach!=""&&valueseach!=null){
              for(var i in data){
                if(data[i].name.includes(valueseach)){
                  newlist.push(data[i]);
                }
              }
       this.userb2c=newlist;       
    }
  }
  getusersb2c(){
    let relation=
    {
      include: [{
                "relation": "discountGroupUsersB2C",
                "scope": {
                  "where": {"unsubscribe": false}
                }
              }]
      };
    this.service.collection = 'discounts-groups';
    let arrayusersb2c=[];
    this.service.get(this.idElement,'',relation).subscribe(response => {
    
      for(var i in response.discountGroupUsersB2C){
      if(response.id==this.idElement){
        moment.locale('es');
        var date= moment(response.discountGroupUsersB2C[i].registrationDate).format("LL");
        response.discountGroupUsersB2C[i].registrationDate=date;
          arrayusersb2c.push(response.discountGroupUsersB2C[i]);
      }
    }
    this.userb2c=arrayusersb2c;
    this.frmFilters.controls.data.setValue(arrayusersb2c);
  });
  }
  clearsearch(){
    this.frmFilters.controls.name.setValue('');
    this.getusersb2c();
  }
  deleteStationGroup(id){
   
    this.modal.show(
      id,
      'warning',
    'Usuario B2C',
    '¿desea eliminar este usuario del grupo?',
    'show',
    id => {
     var message=this.modal.getMessage();
     this.service.collection = `discount-group-users-b2c`;
     var action='?message='+message;
      this.service.update(id,'',action,'DELETE').subscribe(response => {
        this.getusersb2c();
        this.notification.show("Usuario eliminado correctamente","success");
          if (response === null) {
            if(this.userb2c.length==1){
              this.router.navigate(["/dashboard/group-discount"]);
            }
          }
          this.modal.hide();
        });
    },'1'
    );
    
  }
  chagestatus(id,status){
    var estatus;
    if(status==false){
      estatus=true;
    }else{
      estatus=false;
    }
    this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿desea cambiar el estatus del usuario?',
    'show',
    id => {
      this.service.collection = `discount-group-users-b2c`;
      var action="status?status="+estatus; 
      this.service.getspecific(id,action).subscribe(response => {
          if (response === null || response.count === 1) {
            this.getusersb2c();
          }
          this.modal.hide();
        });
    }
    );
    
  }
  getgroupdiscount(){
    this.service.collection = `discounts-groups`;
    if(this.idElement !== undefined){
      this.service.get(this.idElement).subscribe(response => {
        if (!response.hasOwnProperty('success')) {
          this.showpage=true;
        this.namegroup=response.name;
        this.breadcrumb.links(
          {
            links: [
              { link: '/dashboard/group-discount', label: 'Listado Grupo de Descuentos'},
              { link: '', label: 'Usuarios Asociados'}
            ],
            title: 'Usuarios Asociados'
          }
        );
        }else{
          this.showpage=false;
          this.router.navigate(['/dashboard/group-discount']);  
          this.notification.show("No tienes permisos para esta acción","error");
        }
    }
    )};
  }
}
