import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cms-gulf-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss']
})
export class CongratulationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
