import { Component, OnInit } from '@angular/core';
import { ModaListService } from './modal-list.service';
@Component({
  selector: 'cms-gulf-modal-list',
  templateUrl: './modal-list.component.html',
  styleUrls: ['./modal-list.component.scss']
})
export class ModalListComponent implements OnInit {
  show = 'show';
  executing = false;
  title = '';
  action = '';
  message;
  constructor(
  private  modal: ModaListService
  ) { }

  ngOnInit(): void {
    this.modal.currentMessage.subscribe((response) => {
      this.show = response.show;
      this.title = response.title;
      this.action = response.action;
      this.message = response.message;
    });

    this.modal.currentAction.subscribe((response) => {
      this.show = response.show;
      this.executing = response.executing;
    });

  }
  close(event: Event): void {
    event.preventDefault();
    this.modal.elementBody.className = '';
    this.modal.actionSource.next({ action: 'cancel' });
    this.show = '';
  }
}
