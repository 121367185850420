<div>
  <a  class="plus" title="Agregar Usuarios" routerLink="/dashboard/users/group">
    <i class="icon-plus"></i>
  </a>
</div>
<div *ngFor="let group of listgruopuser;let indice=index" class="table mt-2">
    <button type="button " class="w-100" [collapse]="indicegroups">{{group.group}}</button>
        <div collapseContent #indicegroups="collapseContent" class="div-groups">
                <div class="stations row form-content" *ngIf="group.stations.length>0;">
                     <div class="col-6">
                          <p class="text-ornage">Estaciones:</p>
                           <ul>
                             <li *ngFor="let stations of group.stations; let o=index;" class="">
                                    <p class="edit bg-stations">
                                      {{stations.name}}
                                      <span class="trashgroup float-right" (click)="deleteStationGroup(stations.id,group.idgroup)"><a  class="icon-delete m-auto"></a></span>
                                    </p>
                                </li>
                           </ul>
                     </div>
                     <div class="col-6 form-group text-center">
                        <a class="w-100 mt-4" id="{{group.idgroup}}" routerLink="/dashboard/users/edit/groups/{{group.idgroup}}"><button type="button" class="mt-3">Editar Grupo</button></a>
                      
                    </div>
                </div>
            </div>
</div>