import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from '../../../base/form/form.component';
@Component({
  selector: 'cms-gulf-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent extends BaseFormComponent implements OnInit {
linkdas:boolean;
roluser;
  ngOnInit(): void {
    this.routes = ['dashboard'];
        this.linkdas=false;
        this.frmElement = new FormGroup({
          password: new FormControl('',[Validators.required]),
        });
        this.verifydatauser();
  }
  onSubmit():boolean{
    var data  = this.frmElement.value;
    this.service.collection = `users`;
    let action="/password";
    this.service.update(this.session.userId, data,action,"PATCH").subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
        this.notification.show(this.updateMessage, 'success');
        var roles=this.roluser;
          if(roles.length==1&&roles[0]=="GROUPDISCOUNT"){
            this.routes = ['dashboard/group-discount'];
            this.router.navigate(this.routes);
          }else{
            this.router.navigate(this.routes);
          }
      }
      this.sending = false;
    });
    return false

  }

  verifydatauser(){
    var iduser= this.session.userId;
    let relation={
      "include": [
        {
          "relation": "userRoles",
          "scope": {
            "include": [
              {"relation": "role"}
            
            ]
        }
      }, {
        "relation": "userStations"
      }
    ]
  };
  
    if(this.session.token!=""&&iduser!=""){
      var collection="users";
      this.service.collection = 'users';
      this.service.get(iduser,collection,relation).subscribe( response => {
        if(response.success === undefined){
            if(response["userRoles"]){
                var rol=[];
              for(var i in response["userRoles"]){
                      if(!rol.includes(response["userRoles"][i].role.name)){
                        rol.push(response["userRoles"][i].role.name);
                      }
                    }
                    this.roluser=rol;
             
        }
        
      }
    });
  }
}
  
}
