
  <div class="filter" [ngClass]="{ show: status, close: close }">
    <form action="" [formGroup]="frmFilters" autocomplete="off" (ngSubmit)="searchvalue()">
      <fieldset>
        <legend>Búsqueda por orden</legend>
        <ul class="row">
          <li class="col-6">
            <label for="txtName">No. de orden</label>
            <input type="text" name="name" id="txtName" formControlName="name">
          </li>
          <li class="col-6">
            <p>
              <a class="btn-default clear" (click)="clearsearch()">Limpiar</a>
              <span [class.loading]="sending">
                <button type="submit" class="btn-default">
                  Buscar
                </button>
              </span>
            </p>
          </li>
        </ul>
      </fieldset>
    </form>
  </div>
<table #table>
    <thead>
      <tr >
        <th class="text-center">No. orden</th>
        <th class="text-center">No. orden Mercado pago</th>
        <th class="text-center">Estación</th>
        <th class="text-center">Empleado</th>
        <th class="text-center">Fecha</th>
        <th class="text-center">Estatus Pago</th>
        <th class="text-center">Acciones</th>
      </tr>
    </thead>
    <tbody *ngIf="orders&&orders.length>0">
      <tr *ngFor="let item of orders | paginate: { itemsPerPage: 10, currentPage: page }">
        <td class="text-center">
            {{item.order.orderSaleId}}
        </td>
        <td class="text-center">
          {{item.order.orderId}}
      </td>
        <td class="text-center">
          {{item.station.name}}
        </td>
        <td class="text-center">
          {{item.employeeName}}
        </td>
        <td class="text-center">
          {{item.order.date}}
        </td>
        <td class="text-center">
          {{item.order.statusPayment}}
        </td>
        <td class="text-center edit">
            <a class="link w-100" routerLink="/dashboard/orders/detail-order/{{item.order.id}}"><p>Ver detalle</p></a>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-template #p="paginationApi"(pageChange)="page = $event" *ngIf="orders&&orders.length>0">
    <nav class="pagination" aria-label="Paginación de la tabla">
      <ul>
        <li class="prev" > 
          <a *ngIf="!p.isFirstPage()"  (click)="p.previous()">
            <span class="icon-prev" [class.disabled]="p.isFirstPage()"></span>
          </a>
        </li>
        <li class="page">
          <a class="disabled" >{{page}}</a>
        </li>
        <li class="next">
          <a *ngIf="!p.isLastPage(); else showlast"  (click)="p.next()">
            <span class="icon-next" [class.disabled]="p.isLastPage()"></span>
          </a>
          <ng-template #showlast>
            <a class="link-off">
              <span class="icon-next " ></span>
            </a>
          </ng-template>
        </li>
      </ul>
    </nav>
</pagination-template>