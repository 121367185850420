import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
// Global Service
import { RequestService } from '../request/request.service';
import { SessionService } from '../utils/session/session.service';
@Injectable({
  providedIn: 'root'
})
export class BaseService {

  collection: string;

  constructor(
    private requestService: RequestService,
    private session: SessionService
    ) {
      const urltoken='Bearer '+this.session.token;
    this.requestService.updateHeaders('Authorization',urltoken);
  }

  private toQueryString(params): string {
    let queryString = '';
    let first = true;
    const keys = Object.keys(params);
    for (const key of keys) {
      if (first) {
        queryString += '?';
        first = false;
      }
      else {
        queryString += '&';
      }
      queryString += `${key}=${params[key]}`;
    }
    return queryString;
  }
  /**
   *
   * @param url
   */
  setAPI(url): void {
    this.requestService.api = url;
  }
  /**
   *
   * @param model
   * @param action
   */
  create(model = {}, action = ''): Observable<any> {
    action = `${this.collection}${action}`;
    return this.requestService.execute(action, model, 'POST');
  }
  createtxt(model = {}, action = ''): Observable<any> {
    action = `${this.collection}${action}`;
    return this.requestService.executeTxt(action, model, 'POST');
  }
  /**
   *
   * @param model
   * @param method
   * @param action
   */
  send(model = {}, method = 'POST', action = ''): Observable<any> {
    action = `${this.collection}${action}`;
    this.requestService.updateHeaders('enctype', 'application/x-www-form-urlencoded');
    this.requestService.removeHeader('Content-Type');
    return this.requestService.execute(action, model, method).pipe(
      tap(response => {
        this.requestService.updateHeaders('Content-Type', 'application/json');
        this.requestService.removeHeader('enctype');
      })
    );
  }

  get(id: string, action = '', filter = undefined): Observable<any> {
    action = `${this.collection}/${id}`;
    if (filter) {
      filter = JSON.stringify(filter);
      filter = encodeURIComponent(filter);
      action = `${action}?filter=${filter}`;
    }
    return this.requestService.execute(action, {}, 'GET');
  }
  getspecific(id: string, action = '', filter = undefined): Observable<any> {
    var act = `${this.collection}/${id}/${action}`;
    if (filter) {
      filter = JSON.stringify(filter);
      filter = encodeURIComponent(filter);
      action = `${action}?filter=${filter}`;
    }
    return this.requestService.execute(act, {}, 'GET');
  }

  getAlltxt(filter = undefined, action = ''): Observable<any> {
    action = `${this.collection}${action}`;
    if (filter) {
      filter = JSON.stringify(filter);
      filter = encodeURIComponent(filter);
      action = `${action}?filter=${filter}`;
    }
    return this.requestService.executeTxt(action, {}, 'GET');
  }
  getAll(filter = undefined, action = ''): Observable<any> {
    action = `${this.collection}${action}`;
    if (filter) {
      filter = JSON.stringify(filter);
      filter = encodeURIComponent(filter);
      action = `${action}?filter=${filter}`;
    }
    return this.requestService.execute(action, {}, 'GET');
  }

  update(id: string, model = {}, action = '', method = 'PATCH'): Observable<any> {
    action = `${this.collection}/${id}${action}`;
    return this.requestService.execute(action, model, method);
  }

  remove(id: number, action = ''): Observable<any> {
    action = `${this.collection}/${id}`;
    return this.requestService.execute(action, {}, 'DELETE');
  }
  getJson(url){
    return this.requestService.getUrlFile(url);
  }
  set(action = '', model = {}, method = 'PUT'): Observable<any> {
    action = `${this.collection}/${action}`;
    return this.requestService.execute(action, model, method);
  }
  settxt(action = '', model = {}, method = 'PUT'): Observable<any> {
    action = `${this.collection}/${action}`;
    return this.requestService.executeTxt(action, model, method);
  }
}
