import { Component, Input, OnInit } from '@angular/core';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { FormViewComponent } from '../../../common/form/form-view.component';


import { BreadcrumbService } from '../../../common/breadcrumb/service/breadcrumb.service';
import { BaseFormComponent } from 'src/app/base/form/form.component';

@Component({
  selector: 'cms-gulf-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent extends BaseFormComponent implements OnInit {
  active:boolean;
roles=[];
rolesuser=[];
stations=[];
rolesSelected=[];
StationSelected=[];
 date:string;
users:FormGroup;
button:string;
accescontrol:boolean;
iduser:boolean;
idedituser=0;
  ngOnInit(): void {    
    this.button="Crear";
    this.active=false;
    this.getChangePassword();
    this.getRoles();
    this.getStations();
    this.getElement();
    this.getFormateDate();
    this.breadcrumb.links({
      links: [
        { link: '/dashboard/users', label: 'Listado de Usuarios'},
        { link: '', label: this.idElement ? 'Actualizar Usuario' : 'Agregar Usuario'}
      ],
      title: this.idElement ? 'Actualizar Usuario' : 'Agregar Usuario'
    });

    // FormGroup
    
    this.frmElement = new FormGroup({
      user: new FormGroup({
        username: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
        generatedPassword: new FormControl('defaultpass', [Validators.required]),
        status: new FormControl(this.active ),
        registrationDate: new FormControl(this.date),
      }),
      roles: new FormControl('',[Validators.required]),
      stationsselected: new FormControl('' ),
    });
    this.createMessage = 'El usuario se generó correctamente';
    this.updateMessage = 'Se actualizaron los datos del usuario';
  
    this.routes = ['dashboard', 'users'];
  }
  getChangePassword(){
    var iduser= this.session.userId;
          let relation={
            "include": [
              {
                "relation": "userRoles",
                "scope": {
                  "include": [
                    {"relation": "role"}
                  
                  ]
              }
            }, {
              "relation": "userStations"
            }
          ]
        };
        
          if(this.session.token!=""&&iduser!=""){
            var collection="users";
            this.service.collection = 'users';
            this.service.get(iduser,collection,relation).subscribe( response => {
              if(response.success === undefined){
                  if(response["userRoles"]){
                      var roles=[];
                    for(var i in response["userRoles"]){
                            if(!roles.includes(response["userRoles"][i].role.name)){
                              roles.push(response["userRoles"][i].role.name);
                            }


                    }
                    this.accescontrol=response.accessControl;
                    var rol=roles;
                    if(rol.includes("SUPERADMIN")){
                      this.iduser=true;
                    }
                    if(typeof(this.idElement)!="undefined"){
                      this.idedituser=parseInt(this.idElement);
                    }            
              }
              
            }
          });
        }
    
  }
  getFormateDate(){
    let currendate= new Date();
    var day=''+currendate.getDate();
    var month=''+(currendate.getMonth()+1)
    var hours=''+currendate.getHours();
    var minutes=''+currendate.getMinutes();
    var seconds=''+currendate.getSeconds();
      if(day.length<2){
        day = '0' + day;
      }
      if(month.length<2){
        month = '0' + month;
      }
      if(hours.length<2){
        hours = '0' + hours;
      }
      if(minutes.length<2){
        minutes = '0' + minutes;
      }
      if(seconds.length<2){
        seconds = '0' + seconds;
      }
    let time=hours+":"+minutes+":"+seconds+"."+currendate.getMilliseconds();
        this.date =currendate.getFullYear()+"-"+month+"-"+day+"T"+time+"Z";
  }
  GetRolesSelected($event){
    this.rolesSelected.length=0;
    for(var i in $event.selected){
      if($event.selected[i].id!=""&&typeof($event.selected[i].id)!="undefined"){
        this.rolesSelected.push($event.selected[i].id);
      }
    }
    this.frmElement.controls.roles.setValue($event.selected.map(s => s.id));

  }
  GetStationSelected($event){
    this.StationSelected.length=0;
    for(var i in $event.selected){
      if($event.selected[i].stationId!=""&&typeof($event.selected[i].stationId)!="undefined"){
        this.StationSelected.push($event.selected[i].stationId);
      }
    }
    this.frmElement.controls.stationsselected.setValue($event.selected.map(s => s.stationId));

  }
  getRoles(){
    this.service.collection = 'roles';
    this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        let arrayrole={};
        this.roles =response;
        for(var i in response){
          arrayrole[i]= new Object();
          arrayrole[i]["id"]=response[i].id;
          arrayrole[i]["name"]=response[i].name;
        }
        
      }
    });
  }
  getStations(){
    this.service.collection = 'stations';
    this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        this.stations =response;
      }
    });
  }
  changeStatus( ){
    if (this.active === false) {
      this.active = true;
      this.frmElement.controls.user.get("status").setValue(true);
    }else {
      this.active = false;
      this.frmElement.controls.user.get("status").setValue(false);
    }
   
  }
  onSubmit():boolean{
    let data;
    var station=this.frmElement.controls.stationsselected.value;
    if(this.idElement === undefined){
     station=this.frmElement.controls.stationsselected.value;
      if(station.length>0){
        this.frmElement.addControl('stations',new FormControl( station));
      }
      this.service.collection ="users";
      this.service.create(this.frmElement.value).subscribe(response => {
        if(response === null || !response.hasOwnProperty('success')){
          this.notification.show(this.createMessage, 'success');
          this.router.navigate(this.routes);
        }
      });
      return false
    }
    if(station.length>0){
      this.frmElement.addControl('stations',new FormControl( station));
    }
    if(this.accescontrol==true){
      this.frmElement.removeControl("generatedPassword");
    }else{
      this.frmElement.addControl('stations',new FormControl( station));
    }
    
    data  = this.frmElement.controls.user.value;
    this.service.collection = `users`;
    this.service.update(this.idElement, data).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
        this.notification.show(this.updateMessage, 'success');
        this.updateUserroles();
        this.updateUserStations();
        if(this.session.userId==this.idElement){
          this.setRoleUser();
          this.router.navigate(['dashboard']);
        }else{
        this.router.navigate(this.routes);
      }
    }
      this.sending = false;
    });
  }
  setRoleUser(){
    let roles=this.frmElement.controls.roles.value;
    let allroles=[];
    // let getallrole=JSON.parse(localStorage.getItem("allRoles"));
    // let newroles=[];  
    // for(var e in getallrole){
    //   allroles.push(getallrole[e].id);
    // }
    // for(var a in roles){
    //         if(allroles.includes(roles[a])){
    //           for(var i in getallrole){
    //           if(getallrole[i].id==roles[a]&&!newroles.includes(getallrole[i].name)){
    //             newroles.push(getallrole[i].name);
    //           }
    //         }
    //         }
    //   }
    //   this.SetSessionUser(newroles);
  }
  SetSessionUser(newroles){
    // if(newroles.length>0){
    // localStorage.setItem('role',JSON.stringify(newroles));
    //   this.session.role=JSON.stringify(newroles);
    // }
  }
  updateUserroles(){
    let rolesUser=this.frmElement.controls.UserRoles.value;
    let roluserarray=[];
    let roluserlistDelete=[];
    let roluserlistAdd=[];
    let updaterol=this.frmElement.controls.roles.value;
    for(var o in rolesUser){
        roluserarray.push(rolesUser[o].roleId);
    }
        if(roluserarray.length>updaterol.length){
          roluserlistDelete=this.verifydeleteElementRole(rolesUser,updaterol,roluserlistDelete); 
          roluserlistAdd= this.AddElemntRole(updaterol,roluserarray,roluserlistAdd);
        }
        if(roluserarray.length<updaterol.length){
          roluserlistDelete=this.verifydeleteElementRole(rolesUser,updaterol,roluserlistDelete); 
          roluserlistAdd= this.AddElemntRole(updaterol,roluserarray,roluserlistAdd); 
        }
        if(roluserarray.length==updaterol.length){
          roluserlistDelete=this.verifydeleteElementRole(rolesUser,updaterol,roluserlistDelete); 
          roluserlistAdd= this.AddElemntRole(updaterol,roluserarray,roluserlistAdd);
    }
        if(roluserlistAdd.length>0)
        {
          for(var l in roluserlistAdd){
            this.AddRolUser(roluserlistAdd[l]);
          }
        }
        if(roluserlistDelete.length>0)
        {
          for(var p in roluserlistDelete){
            this.DeleteRolUser(roluserlistDelete[p]);
          }
        }
      
  }
  DeleteRolUser(id){
    this.service.collection = `user-roles`;
    this.service.remove(id).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
      
      }
      this.sending = false;
    });
  }
  AddRolUser(id){
    let obj= new Object()
    obj["userId"]=parseInt(id["userId"]);
    obj["roleId"]=parseInt(id["roleId"]);
    this.service.collection = `user-roles`;
    this.service.create(obj).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
      
      }
      this.sending = false;
    });
  }

  updateUserStations(){
    let stationserarray=[];
    let stationuserlistDelete=[];
    let stationuserlistAdd=[];
    let updateStation=this.frmElement.controls.stationsselected.value;
    if(typeof(this.frmElement.controls.UserStationsList)!="undefined"){
    let stationUser=this.frmElement.controls.UserStationsList.value;
    for(var o in stationUser){
      stationserarray.push(stationUser[o].stationId);
    }              
        if(stationserarray.length>updateStation.length){
          stationuserlistDelete=this.verifydeleteElementStation(stationUser,updateStation,stationuserlistDelete);
          stationuserlistAdd= this.AddElementStation(updateStation,stationserarray,stationuserlistAdd);
        }
        if(stationserarray.length<updateStation.length){
          stationuserlistDelete=this.verifydeleteElementStation(stationUser,updateStation,stationuserlistDelete);
          stationuserlistAdd= this.AddElementStation(updateStation,stationserarray,stationuserlistAdd);   
        }
        if(stationserarray.length==updateStation.length){
          stationuserlistDelete=this.verifydeleteElementStation(stationUser,updateStation,stationuserlistDelete);
          stationuserlistAdd= this.AddElementStation(updateStation,stationserarray,stationuserlistAdd);
    }
  }else{
    stationuserlistAdd= this.AddElementStation(updateStation,stationserarray,stationuserlistAdd);
  }
        if(stationuserlistAdd.length>0)
        {
          for(var l in stationuserlistAdd){
            this.AddStationUser(stationuserlistAdd[l]);
          }
        }
        if(stationuserlistDelete.length>0)
        {
          for(var p in stationuserlistDelete){
            this.DeleteStationUser(stationuserlistDelete[p]);
          }
        }
      
  }
  DeleteStationUser(id){
    this.service.collection = `user-stations`;
    this.service.remove(id).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
      
      }
      this.sending = false;
    });
  }
  AddStationUser(id){
    let obj= new Object()
    obj["name"]=id["name"];
    obj["stationId"]=id["stationId"];
    obj["userId"]=parseInt(id["userId"]);
    obj["groupId"];
    this.service.collection = `user-stations`;
    this.service.create(obj).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
      
      }
      this.sending = false;
    });
  }
  verifydeleteElementStation(ElemntUser,updateElement,listDelete){
    for(var u in ElemntUser){
      if(!updateElement.includes(ElemntUser[u].stationId)){
        listDelete.push(ElemntUser[u].id);
      }
    } 
    return listDelete;
  }
  verifydeleteElementRole(ElemntUser,updateElement,listDelete){
    for(var u in ElemntUser){
      if(!updateElement.includes(ElemntUser[u].roleId)){
        listDelete.push(ElemntUser[u].id);
      }
    } 
    return listDelete;
  }
  AddElemntRole(updaterol,roluserarray,roluserlistAdd){
    for(var i in updaterol){
      if(!roluserarray.includes(updaterol[i])){
          let addrol=[];
          addrol["userId"]=this.idElement;
          addrol["roleId"]=updaterol[i];
          roluserlistAdd.push(addrol);
      }
    }
    return roluserlistAdd;
  }
  AddElementStation(updateStation,stationserarray,stationuserlistAdd){
    for(var i in updateStation){
      if(!stationserarray.includes(updateStation[i])){
        for(var n in this.stations ){
          if(this.stations[n].stationId==updateStation[i]){
         let addstation=[];
         addstation["name"]=this.stations[n].name;
         addstation["stationId"]=updateStation[i];
         addstation["userId"]=this.idElement;
         stationuserlistAdd.push(addstation);  
        }
       }
      }
    }
    return stationuserlistAdd;
  }
  getElement(){
    let relation={
      "include": [
        {
          "relation": "userRoles",
          "scope": {
            "include": [
              {"relation": "role"}
            
            ]
        }
      }, {
        "relation": "userStations"
      }
    ]
  };
    this.service.collection = `users`;
    if(this.idElement !== undefined){
      this.button="Actualizar";
      this.service.get(this.idElement,'',relation).subscribe(response => {
        if (!response.hasOwnProperty('success')) {
          let group=this.frmElement.controls.user as FormGroup;
          // response.generatedPassword=response.generatedPassword;
          this.frmElement.controls.user.patchValue(response);
          group.controls.generatedPassword.setValue("defaultpass");
          this.active=response.status;
          let rol=[];
          let station=[];
          if(response.userRoles){
            this.frmElement.addControl('UserRoles', new FormControl(''));
            this.frmElement.controls.UserRoles.setValue( response.userRoles)
            for(var i in response.userRoles){
              rol.push(response.userRoles[i].roleId);
            }
            this.frmElement.controls.roles.patchValue(rol);
          }
          
          if(response.userStations){
            this.frmElement.addControl('UserStationsList', new FormControl(''));
            this.frmElement.controls.UserStationsList.setValue( response.userStations)
            for(var i in response.userStations){
              station.push(response.userStations[i].stationId)
            }
            this.frmElement.controls.stationsselected.patchValue(station);
          }
          this.frmElement.controls.user.get("generatedPassword").clearValidators();
          this.frmElement.controls.user.get("generatedPassword").updateValueAndValidity();
          
        }
      });
    }
  }
   generatePassword(length=8) {
    let   characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var pass = "";
    for (var i=0; i < length; i++){
       
            pass += characters.charAt(Math.floor(Math.random()*characters.length));   
    }
    this.frmElement.controls.user.get("generatedPassword").setValue(pass);
    // return pass;
}
showPassword(event:any){
  let inputPassword = document.getElementById('generatedPassword');
  let iconClass = event.target;
  if(inputPassword['type'] === "password"){
    inputPassword['type'] = 'text';
    iconClass.className = 'icon-hide';
    return false
  }
  inputPassword['type'] = 'password';
  iconClass.className = 'icon-show';
}
updatepassword(){
    var pass= this.frmElement.get("user").value.generatedPassword;
    this.service.collection = `users`;
    var data= new Object();
    data["password"]=pass;
    let action="/password";
    this.service.update(this.idElement, data,action,"PATCH").subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
          this.notification.show("Se ha modificado la contraseña correctamente","success");
      }
    });
}
}
