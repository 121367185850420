import { Component, OnInit } from '@angular/core';

// Breadcrumb service
import { BreadcrumbService } from './service/breadcrumb.service';
import { BaseFormComponent } from 'src/app/base/form/form.component';

@Component({
  selector: 'cms-gulf-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BaseFormComponent implements OnInit {
  iduser:string;
  datauser;
  user:string;
  groupdiscount:boolean;
  admin:boolean;
  links: any = [
    {
      label: 'Dashboard',
      link: '/dashboard/home'
    }
  ];
  title = 'Dashboard';


  ngOnInit(): void {
    this.breadcrumb.values.subscribe( response => {
      this.links = response.links;
      this.title = response.title;
    });
    this.verifydatauser();
  }
        verifydatauser(){
          var iduser= this.session.userId;
          let relation={
            "include": [
              {
                "relation": "userRoles",
                "scope": {
                  "include": [
                    {"relation": "role"}
                  
                  ]
              }
            }, {
              "relation": "userStations"
            }
          ]
        };
        
          if(this.session.token!=""&&iduser!=""){
            var collection="users";
            this.service.collection = 'users';
            this.service.get(iduser,collection,relation).subscribe( response => {
              if(response.success === undefined){
                  if(response["userRoles"]){
                      var rol=[];
                    for(var i in response["userRoles"]){
                            if(!rol.includes(response["userRoles"][i].role.name)){
                              rol.push(response["userRoles"][i].role.name);
                            }
                        
                    }
                    if(rol.includes("SUPERADMIN")){
                      this.user="SUPERADMIN";
                    }
                    this.datauser=response;
                    this.datauser.userRoles=rol;
                    if(rol.length==1&&rol[0]=="GROUPDISCOUNT"){
                    this.groupdiscount=true;
                    this.admin=false;
      
                    }
                    if(rol.length==1&&rol[0]=="ADMIN"){
                    this.admin=true;
                    this.groupdiscount=false;
                        
                    }
                                
              }
              
            }
          });
        }
      }
}
