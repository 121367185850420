import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//Services
import { NotificationService } from '../common/notification/notification.service';
import * as CONFIG from '../../../parameters.json'; 
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private httpHeaders = new HttpHeaders({
    "Accept":"*/*"
  });

  public api = CONFIG['API'];

  constructor(
    private http: HttpClient,
    private notification: NotificationService,
    private router: Router
  ) { }

  private handleError<T>(operation = 'Collection', result?: T) {
    return (response: any): Observable<T> => {
      let message = 'Estamos teniendo problemas de conexión';
      if (response.error.hasOwnProperty('error')) {
        if (response.error.hasOwnProperty('error')) {
          message = response.error.error;
          if(typeof response.error.error === 'object'){
            const errors = response['error']['error'];
            if(errors.details){
              const errors = response['error']['error'].details;
              for (const key in errors) {
                let error=errors[key].path.split("/");
                message = error[1]+ ` : ${errors[key].message}`;

            }
            }else{
              message =  errors.message;
            }
          }
          if(response.url.includes("refresh-token")&&response.error.error.statusCode === 401 && response.error.error.name === 'UnauthorizedError'&&response.error.error.message.includes("jwt expired")) {
            localStorage.removeItem('token');
             this.router.navigate(['/login']);  
           }
          if(response.error.error.statusCode === 401 && response.error.error.name === 'UnauthorizedError'&&response.error.error.message.includes("jwt expired")) {
            var token=localStorage.getItem('token');
            message = '';
            if(token!=""&&token!=null&&localStorage.getItem('refreshtoken')!=""&&localStorage.getItem('refreshtoken')!=null){
              var refreshtoken=localStorage.getItem('refreshtoken');
              this.getRefreshtoken(refreshtoken);
              
            }else{
              message = 'Se ha terminado la sesión, por favor vuelva a iniciarla';
              localStorage.removeItem('token');
              this.router.navigate(['/login']);  

            }
          }
          if(response.error.error.statusCode === 401 && response.error.error.name === 'UnauthorizedError'&&response.error.error.message.includes("jwt malformed")) {
            message = 'hubo un error con la session';
            localStorage.removeItem('token');
            this.router.navigate(['/login']);  
          }
        }
        else {
          const errors = Object.keys(response['error']['errors']);
          if (errors.length) {
            message = response['error']['errors'][errors[0]];
          }
        }
      }else{
        if(response.error.hasOwnProperty('errors')){
          let item = response['error']['errors'];
          for (const key in item) {
            message = item[key];
          }
        }
        try{
          const error=JSON.parse(response.error);
          if(error.error&&error.error.message){
            message=error.error.message;
          }
        }catch(e){
        }
      }
      this.notification.show(message, 'error');
      return of(result as T);
    };
  }
  refreshlogin(credentials: Object):Observable<any>{
    const urltoken=''+credentials;
    this.updateHeaders('refresh',urltoken);
    return this.execute('auth/refresh-token', {}, 'POST').pipe(
      tap(response =>{
        if(response.success === undefined){
          if(response.hasOwnProperty('access')){
            return true;
          }
        }
      })
    );
  }
  getRefreshtoken(token){
    return new Promise((resolve,reject)=>{
    this.refreshlogin(token).subscribe( response => {
      if(response.success === undefined){
        return resolve(response);
      }
      
    })
  }).then(function(data){
    if(data["accessToken"]!=""&&data["accessToken"]!="null"&&data["accessToken"]!=null){
      localStorage.setItem('token',data["accessToken"]);
      window.location.href="/";
    }
  })
  }
  updateHeaders(header: string, value: string): void {
    this.httpHeaders = this.httpHeaders.set(header, value);
  }

  removeHeader(name: string): void {
    this.httpHeaders = this.httpHeaders.delete(name);
  }
  getUrlFile(url):Observable<any>{
    try{
      url= url;
      let headers = this.httpHeaders;
      let options = {headers,responseType: 'text' as 'text' };
    return this.http.get(url,options);
    }catch(e){
      console.log(e);
    }
  }
  execute(
    collection: string,
    data: object,
    method = 'GET',
    headers = this.httpHeaders
  ): Observable<any> {
    const service = CONFIG['API'] + collection;
    // const service = `${this.config[this.api]}${collection}`;
    const options = { body: data, headers: headers };
    return this.http.request(method, service, options).pipe(
      catchError(this.handleError(collection, { success: false }))
    );
  }
  executeTxt(
    collection: string,
    data: object,
    method = 'GET',
    headers = this.httpHeaders
  ): Observable<any> {
    const service = CONFIG['API'] + collection;
    // const service = `${this.config[this.api]}${collection}`;
    const options = { body: data, headers: headers,responseType: 'text' as 'text' };
    return this.http.request(method, service, options).pipe(
      catchError(this.handleError(collection, { success: false }))
    );
  }
}
