import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../common/breadcrumb/service/breadcrumb.service';
import { BaseListComponent } from '../../../base/list/list.component';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'cms-gulf-group-discount',
  templateUrl: './group-discount.component.html',
  styleUrls: ['./group-discount.component.scss']
})
export class GroupDiscountComponent extends BaseListComponent implements OnInit {
groups;

  ngOnInit(): void {
    this.getGroups();
    this.frmFilters = new FormGroup({
      name: new FormControl(''),
      data: new FormControl(''),
      userslist: new FormControl('')
    });
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado Grupo de Descuentos'}
        ],
        title: 'Grupo de Descuentos'
      }
    );
  }
  getGroups(){
    this.service.collection = 'discounts-groups';
    this.service.getAll().subscribe(response => {
      this.groups=response;
      this.frmFilters.controls.data.setValue(response);
    });
  }
  clearsearch(){
    this.frmFilters.controls.name.setValue('');
    this.getGroups();
    // if(typeof(this.frmFilters.controls.data)!="undefined"){
    //   this.groups=this.frmFilters.controls.data.value;
    // }
  }
  searchvalue(){
    // var data= this.frmFilters.controls.data.value;
    this.service.collection = 'discounts-groups/search';
    var valueseach=this.frmFilters.controls.name.value;  
    var params="?name="+valueseach;
  this.service.getAll('',params).subscribe(response => {
    this.groups=response;
  });
  }
  chagestatus(id,status){
    var estatus;
    if(status==false){
      estatus=true;
    }else{
      estatus=false;
    }
    this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿desea cambiar el estatus del grupo?',
    'show',
    id => {
      this.service.collection = `discounts-groups`;
      var action="status?status="+estatus; 
      this.service.getspecific(id,action).subscribe(response => {
          if (response === null || response.count === 1) {
            this.getGroups();
          }
          this.modal.hide();
        });
    }
    );
    
  }
}
