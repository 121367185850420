<div class="modal {{show}} {{action}}" [class.executing]="executing">
  <div class="content">
    <div>
      <div class="modal-header">
        <a class="icon-close" (click)="close($event)"></a>
        <h2>{{title}}</h2>
      </div>
      <div class="modal-body">
        <span class="icon-{{action}}"></span>
        <p [innerHTML]="message"></p>
        <input *ngIf="userb2c=='1'" type="text" placeholder="Mensaje" id="msjb2c" value="" [(ngModel)]="msjuserb2c">
        <ul>
          <li>
            <input class="btn-default cancel" type="button" (click)="close($event)" value="Cancelar" />
          </li>
          <li>
            <input class="btn-default" type="submit" (click)="execute()" value="Aceptar" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>