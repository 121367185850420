import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  
  private totalSource = new BehaviorSubject<any>({ count: 0 });
  private pageSource = new BehaviorSubject<any>({ page: undefined });

  currentTotal = this.totalSource.asObservable();
  currentPage = this.pageSource.asObservable();

  constructor() { }

  total(count: number) {
    this.totalSource.next({
      count: count
    });
  }

  change(page: number) {
    this.pageSource.next({
      page: page
    });
  }

  clearPages() {
    this.pageSource.observers = [];
    this.pageSource.next({ page: undefined });
  }
}
