import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseListComponent } from '../../../../base/list/list.component';
@Component({
  selector: 'cms-gulf-filter-userb2c',
  templateUrl: './filter-userb2c.component.html',
  styleUrls: ['./filter-userb2c.component.scss']
})
export class FilterUserb2cComponent extends BaseListComponent implements OnInit {
userb2c=[];
 date;
namesearch;
namegroup:string;
buttonsearch;
idgroup;
showpage:boolean;

  ngOnInit(): void {
    this.idgroup=this.idElement;
    this.namesearch="Correo Electrónico";
    this.buttonsearch="Teléfono";
    this.breadcrumb.links(
      {
        links: [
          { link: '/dashboard/group-discount/update/'+this.idElement, label: 'Listado Grupo de Descuentos'}
        ],
        title: 'Asociar Usuarios'
      }
    );
    this.frmFilters = new FormGroup({
      name: new FormControl(''),
      data: new FormControl(''),
      userslist: new FormControl('')
    });
    this.getgroupdiscount();
    this.createMessage = 'Se Agrego de forma correcta el usuario al grupo ';
  }
  changesearch(){
    if(this.namesearch=="Teléfono"){
      this.namesearch="Correo Electrónico"
      this.buttonsearch="Teléfono"
      
    }else{
      this.buttonsearch="Correo"
      this.namesearch="Teléfono"
    }
  }
  searchvalue(){
    if(this.namesearch=="Correo Electrónico"){
      this.service.collection = 'usersB2C/search/email';
      var valueseach=this.frmFilters.controls.name.value;  
      var params="?email="+valueseach;
      this.service.getAll('',params).subscribe(response => {
        this.userb2c=response;
      });
    }else{
      this.service.collection = 'usersB2C/search/phone';
      var valueseach=this.frmFilters.controls.name.value;  
      var params="?phoneNumber=%2B"+valueseach;
      this.service.getAll('',params).subscribe(response => {
        this.userb2c=response;
      });
    }
  }
  clearsearch(){
    this.frmFilters.controls.name.setValue('');
    this.userb2c=[];
  }
  adduserb2c(id,email){
    this.getFormateDate();
    var obj= new Object();
    obj["userb2cId"]=id;
    obj["name"]=email;
    obj["email"]=email;
    obj["registrationDate"]=this.date;
    obj["status"]=true;
    this.modal.show(
      id,
      'success',
    'Usuario B2C',
    '¿desea agregar el usuario al grupo?',
    'show',
    id => {

      this.service.collection = 'discounts-groups/'+this.idElement+'/discount-group-users-b2c';
      this.service.create(obj).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
          this.notification.show(this.createMessage, 'success');
          this.userb2c=[];
          }
          this.modal.hide();
        });
    }
    );
    
  }
  getFormateDate(){
    let currendate= new Date();
    var day=''+currendate.getDate();
    var month=''+(currendate.getMonth()+1)
    var hours=''+currendate.getHours();
    var minutes=''+currendate.getMinutes();
    var seconds=''+currendate.getSeconds();
      if(day.length<2){
        day = '0' + day;
      }
      if(month.length<2){
        month = '0' + month;
      }
      if(hours.length<2){
        hours = '0' + hours;
      }
      if(minutes.length<2){
        minutes = '0' + minutes;
      }
      if(seconds.length<2){
        seconds = '0' + seconds;
      }
    let time=hours+":"+minutes+":"+seconds+"."+currendate.getMilliseconds();
        this.date =currendate.getFullYear()+"-"+month+"-"+day+"T"+time+"Z";
  }
  getgroupdiscount(){
    this.service.collection = `discounts-groups`;
    if(this.idElement !== undefined){
      this.service.get(this.idElement).subscribe(response => {
        if (!response.hasOwnProperty('success')) {
          this.showpage=true;
        this.namegroup=response.name;
        this.breadcrumb.links(
          {
            links: [
              { link: '/dashboard/group-discount', label: 'Listado Grupo de Descuentos'},
              { link: '', label: 'Asociar Usuarios'}
            ],
            title: 'Asociar Usuarios'
          }
        );
        }else{
          this.showpage=false;
          this.router.navigate(['/dashboard/group-discount']);  
          this.notification.show("No tienes permisos para esta acción","error");
        }
    }
    )};
  }
}
