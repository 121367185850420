<div class="notification row {{type}} {{status}}">
  <div class="col-2">
    <span class="icon-{{type}}"></span>
  </div>
  <div class="col-8">
    <p>{{message}}</p>
  </div>
  <div class="col-2" (click)="close()">
    <span class="icon-close"></span>
  </div>
</div>