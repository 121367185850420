import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
// Pagination Service
import { PaginationService } from './pagination.service';
// Handler Service
import { NotificationService } from '../notification/notification.service';
const MAX_ELEMENTS = 10;
@Component({
  selector: 'admin-gulf-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

  // Estados para los botones de la paginación
  prev = true;
  next = false;
  // Pagina actual
  active = 1;

  @Input() max = MAX_ELEMENTS;

  @Input() config = {
    limit: false,
    page: 0,
    total: 0
  }

  constructor(
    private service : PaginationService,
    private notification: NotificationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.next = false;
    if (changes['config']['currentValue']['limit']) {
      this.next = true;
      if (changes['config']['currentValue']['total'] > 0) {
        this.active = changes['config']['currentValue']['page'];
      }
      if (this.active === 1) { this.prev = true; }
    } else {
      this.active = changes['config']['currentValue']['page'];
    }
  }

  changePage(event: any, page: number): boolean {
    event.preventDefault();
    if (event['target']['className'].match('disabled') === null) {
      if (page !== this.active && page > 0) {
        if (page === 1) {
          this.prev = true;
        }
        if (page > this.active) {
          this.prev = false;
        }
        if (page < this.active) {
          this.config.limit = false;
          this.next = false;
        }
        if (this.config.limit) {
          return false;
        }
        this.service.change(page);
      }
    }
  }

}
