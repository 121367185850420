
<div *ngIf="showpage==true">
  <h3 class="text-center tittle-group">Grupo: {{namegroup}}</h3>
    <a type="button"  id="users-b2c" readonly="readonly"  class="status-active btn-userb2c-file"  routerLink="/dashboard/group-discount/upload-userb2c/{{idgroup}}"> Carga de usuarios por excel</a>
</div>
  <div class="filter" [ngClass]="{ show: status, close: close }" *ngIf="showpage==true">
    <form action="" [formGroup]="frmFilters" autocomplete="off" (ngSubmit)="searchvalue()">
      <fieldset>
        <legend>Búsqueda por {{namesearch}}</legend>
        <ul class="row">
          <li class="col-6">
            <label for="txtName">{{namesearch}}</label>
            <input type="text" name="name" id="txtName" formControlName="name">
          </li>
          <li class="col-6">
            <p>
              <a class="btn-default clear" (click)="clearsearch()">Limpiar</a>
              <span [class.loading]="sending">
                <button type="submit" class="btn-default">
                  Buscar
                </button>
              </span>
            </p>
          </li>
          
        </ul>
      </fieldset>
    </form>
    <div><button type="button" class="btn-default" (click)="changesearch()">
      Buscar por {{buttonsearch}}
    </button></div>
  </div>
  <table #table *ngIf="showpage==true">
    <thead>
      <tr>
        <th class="text-center">Nombre</th>
        <th class="text-center">Correo</th>
        <th class="text-center">Teléfono</th>
        <th class="text-center">Acciones</th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let item of userb2c; let indice=index;">
        <td class="text-center">{{item.username}}</td>
        <td class="text-center">{{item.email}}</td>
        <td class="text-center">{{item.phoneNumber}}</td>
        <td class="text-center"><input type="button"  id="users-{{indice}}" (click)="adduserb2c(item.id,item.email)" readonly="readonly" class="status-active" value="Agregar"> </td>
      </tr>
    </tbody>
  </table>
  
  