import { Component, OnInit, Input } from '@angular/core';
import { BaseFormComponent } from 'src/app/base/form/form.component';
@Component({
  selector: 'cms-gulf-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseFormComponent implements OnInit {
  iduser:string;
  superAdmin:boolean;
  groupdiscount:boolean;
  admin:boolean;
  accescontrol:boolean;
  load:boolean;
  ngOnInit(): void {
    this.verifydatauser();
    
  }
  
  verifydatauser(){
    var iduser= this.session.userId;
    let relation={
      "include": [
        {
          "relation": "userRoles",
          "scope": {
            "include": [
              {"relation": "role"}
            
            ]
        }
      }, {
        "relation": "userStations"
      }
    ]
  };
  
    if(this.session.token!=""&&iduser!=""){
      var collection="users";
      this.service.collection = 'users';
      this.service.get(iduser,collection,relation).subscribe( response => {
        if(response.success === undefined){
          
          this.accescontrol=response.accessControl;
            if(response["userRoles"]){
                var rol=[];
              for(var i in response["userRoles"]){
                      if(!rol.includes(response["userRoles"][i].role.name)){
                        rol.push(response["userRoles"][i].role.name);
                      }
                    }
                   if(rol.length==1&&"GROUPDISCOUNT"){
                    this.groupdiscount=true;
                    this.admin=false;
                      
                  }
                  if(rol.length==1&&rol[0]=="ADMIN"){
                    this.admin=true;
                    this.groupdiscount=false;
                  }
                  for(var i in rol){
                      if(rol[i]=="SUPERADMIN"){
                        this.superAdmin=true;
                        }
                
                 }
                 this.load=true;
        }
        
      }
    });
  }
}
  
  menu(): void {
  }
}
