import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  constructor() { }

  // tslint:disable-next-line: typedef
  static getValidatorErrorMessage(validatorName: string, validatorRule?: any) {
    const config = {
      required: 'Campo obligatorio',
      email: 'No es un formato de correo válido',
      customEmail: 'No es un formato de correo válido',
      minlength: `Debe contener mínimo ${validatorRule.requiredLength} carácteres`,
      pattern: 'El campo no tiene un formato válido',
      maxlength: 'Está excediendo el máximo número de caracteres permitidos',
      equalTo: 'Los valores no coinciden',
      whitespace: 'No se permiten espacios',
      password: 'La contraseña debe incluir mínimo una mayúscula y un número; mínimo 8 y máximo 12 caracteres',
      empty: 'El campo no tiene contenido',
      onlyNumbers: 'Sólo se permiten números',
      onlyDecimals: 'Sólo se permiten decimales',
      min: `El número no puede ser menor a ${validatorRule.min}`,
      dateGreaterThan: 'La fecha final debe ser mayor a fecha inicial',
      dateSmallerThan: 'La fecha inicial debe ser menor a fecha final',
      dateRange: 'La fecha final no debe de ser mayor a 2 meses'
    };

    return config[validatorName];
  }
}
