import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from '../../../../base/form/form.component';
@Component({
  selector: 'cms-gulf-add-edit-store',
  templateUrl: './add-edit-store.component.html',
  styleUrls: ['./add-edit-store.component.scss']
})
export class AddEditStoreComponent extends BaseFormComponent implements OnInit {
  public sending=false;
  days=[
    {
    "name":"monday",
    "key":"L",
    "checked":false
   },
   {
    "name":"tuesday",
    "key":"M",
    "checked":false
   },
   {
    "name":"wednesday",
    "key":"M",
    "checked":false
   },
   {
    "name":"thursday",
    "key":"J",
    "checked":false
   },
   {
    "name":"friday",
    "key":"V",
    "checked":false
   },
   {
    "name":"saturday",
    "key":"S",
    "checked":false
   },
   {
    "name":"sunday",
    "key":"D",
    "checked":false
   },
  
  ];
  schedule24:boolean;
  scheduleany:boolean;
  minutes = [];
  minutesend = [];
  hours = [];
  hoursend = [];
  dayselect:boolean;
  dayall:boolean;
  states=[];
  cities=[];
  stationselected:string;
  arraycities=[];
  update:boolean;
  button:string;
  show:boolean;
  ngOnInit(): void {
    this.update=false;
    this.button="Crear";
    this.getJsonStates();
    this.getJsonCities();
    this.getUserStations();
    this.getElement();
    this.frmElement = new FormGroup({
       name: new FormControl('',[Validators.required]) ,
       station_id: new FormControl('') ,
      schedule: new FormGroup({
        starthour: new FormControl('00'),
        startminute: new FormControl('00'),
        endhour: new FormControl('23'),
        endminute: new FormControl('59'),
      }),
      weekdays: new FormControl(''),
      location: new FormGroup({
        street_number: new FormControl('',[Validators.required]),
        street_name: new FormControl('',[Validators.required]),
        city_name: new FormControl('',[Validators.required]),
        state_name: new FormControl('',[Validators.required]),
        latitude: new FormControl('',[Validators.required]),
        longitude: new FormControl('',[Validators.required]),
        reference: new FormControl(''),
      })
    });
    this.hours = this.fillTime(24);
    this.hoursend = this.fillTime(24);
    this.minutes = this.fillTime(60);
    this.minutesend = this.fillTime(60);
    this.schedule24=true;
    this.dayall=true;
    this.breadcrumb.links({
      links: [
        { link: '/dashboard/stations', label: 'Listado de sucursales'},
        { link: '', label: this.update ? 'Actualizar sucursal' : 'Agregar sucursal'}
      ],
      title: this.update ? 'Actualizar sucursal' : 'Agregar sucursal'
    });
  }
  onSubmit():boolean{
    this.sending=true;
    let datos={"store":{},"station_id":""};
    let time=this.frmElement.get("schedule") as FormGroup;
    let dayslist= this.days;
      let arraydays={"business_hours":{}};
      if(this.dayall==true){
        for(let i in dayslist){
            dayslist[i].checked=true
            arraydays["business_hours"][dayslist[i].name]=[{"open":`${time.controls.starthour.value}:${time.controls.startminute.value}`,"close":`${time.controls.endhour.value}:${time.controls.endminute.value}`}];
          }
          
        }else{
          for(let i in dayslist){
            if(dayslist[i].checked==true){
              arraydays[i]= new Object();
              arraydays[i][dayslist[i].name]= new Array();
              arraydays[i][dayslist[i].name][0]={"open":`${time.controls.starthour.value}:${time.controls.startminute.value}`,"close":`${time.controls.endhour.value}:${time.controls.endminute.value}`};
        }
      }
    }
    let location=this.frmElement.get("location") as FormGroup;
    datos["store"]["location"]=this.frmElement.get("location").value;
    datos["store"]["location"]["latitude"]=parseFloat(location.controls.latitude.value);
    datos["store"]["location"]["longitude"]=parseFloat(location.controls.longitude.value);
    datos["store"]["name"]=this.frmElement.controls.name.value;
    if(this.idElement != undefined){
      if(Object.keys(arraydays.business_hours).length !== 0){
        datos["store"]["business_hours"]=arraydays.business_hours;
      }
      if(!this.idElement.includes("?")){
        this.collection='mercado-pago/stores';
        this.service.settxt(this.idElement,datos["store"]).subscribe( response => {
          try{
            this.sending=false;
            response=JSON.parse(response);
            this.notification.show(response.message,"error");
          }catch(e){
            this.sending=false;
            this.notification.show(response,"success");
            this.router.navigate(["/dashboard/store"]);
          }
          return false;
        
      });
      }else{
        const idstation=this.idElement.split("?");
      datos["station_id"]=`${idstation[0]}`;
        const external=  Math.round(Math.random() * 9999999);
      datos["store"]["external_id"]=`STORE${external}`;
      this.service.collection = 'mercado-pago/stores';
      this.service.create(datos).subscribe(async response => {
        if(response.status==201){
          this.sending=false;
          this.notification.show(response.message,"success");
          this.router.navigate(["/dashboard/stations"]);
        }else{
          this.sending=false;
          this.notification.show(response.message,"error");
        }
        try{
          
        }catch(e){
          
        }
      
      });
      return false;
    }
  }
}
  changeschedule(flag:string){
    if(flag=="any"){
      this.schedule24=false;
      this.scheduleany=true;
    }else{
      let time=this.frmElement.get("schedule") as FormGroup;
      time.controls.starthour.setValue("00");
      time.controls.endhour.setValue("23");
      time.controls.endminute.setValue("59");
      this.schedule24=true;
      this.scheduleany=false;
    }
  }
  private fillTime(limit: number): Array<string> {
    let value: any;
    let elements = [];
    for (let i = 0; i < limit; i++) {
      value = i < 10 ? `0${i}` : `${i}`;
      elements.push(value);
    }
    return elements;
  }
  changesdays(flag:string){
    if(flag=="select"){
      this.dayall=false;
      this.dayselect=true;
    }else{
      this.dayall=true;
      this.dayselect=false;
    }
  }
  changestatus(day){
    {
      if(day.checked==false){
        day.checked=true;
      }else{
        day.checked=false;
        
      }
    }
  }
  getUserStations(){
    if(this.idElement.includes("?")){
      let idtemp=this.idElement.split("?");
      let idstation=idtemp[0]
      this.service.collection = 'stations-with-store';
      this.service.getAll().subscribe(response => {
        if(!response.hasOwnProperty('success')){
          let result=response.filter((station,indice)=>{
              return station.station.stationId==idstation;
          });
          const coordinates=this.frmElement.get("location") as FormGroup;
          const lat=result[0].station.lat!=null? result[0].station.lat: 0;
          const long=result[0].station.long!=null? result[0].station.long: 0;
          coordinates.controls.latitude.setValue(lat);
          coordinates.controls.longitude.setValue(long);
          this.stationselected=result[0].station.name;
        }
      });
    }
  }
  
  getElement(){  
    if(!this.idElement.includes("?")){
      this.update=true;
      this.button="Actualizar";
      this.service.collection = 'mercado-pago/stores';
      this.service.get(this.idElement).subscribe(response => {
        if(!response.hasOwnProperty('success')){
          const formlocation=this.frmElement.get("location") as FormGroup;
          this.frmElement.patchValue(response.details); 
          this.frmElement.controls.station_id.setValue(response.station.id);
          let location=response.details.location.address_line.split(",");
          let cityName=location[1];
          let stateName=location[2];
          let street=location[0].split(" ");
          formlocation.controls.state_name.setValue(stateName.trim());
          this.setCities(stateName.trim());
          formlocation.controls.city_name.setValue(cityName.trim());
          formlocation.controls.street_name.setValue(street[0]);
          formlocation.controls.street_number.setValue(street[1]);
         
          if(response.details.business_hours){
              if(Object.keys(response.details.business_hours).length==7){
                this.dayall=true;
                this.dayselect=false;
              }else{
                this.dayall=false;
                this.dayselect=true;
              }
              if(Object.keys(response.details.business_hours).length>0){
                for(var p in this.days){
                      if(response.details.business_hours[this.days[p].name]!=undefined){
                          this.days[p].checked=true;
                      }
                }
              }
          }
          this.show=true;
        }
      });
    }else{
      this.show=true;
    }
  }
  getJsonStates(){
    const url="./assets/json/states.json";
    this.service.getJson(url).subscribe(async response => {
      const res=JSON.parse(response);
      this.states=res;
    });
  }
  getJsonCities(){
    const url="./assets/json/cities.json";
    this.service.getJson(url).subscribe(async response => {
      const res=JSON.parse(response);
      this.arraycities=res;
    });
  }
  setCities(stateselected){
    if(typeof this.arraycities[stateselected]!=undefined&&this.arraycities[stateselected]!=undefined){
    this.cities=this.arraycities[stateselected].cities;
    }else{
      this.cities=[];
    }
  }
  
}
