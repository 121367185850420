import * as moment from 'moment';
export class Utils {
  /**
    * Parse date string into a object
    * @param date String date in format yyyy-mm-ddThhh:mm:ss
    * @returns {object} Object to datepicker component
    */
  formatDate(date: string): {} {
    const temp = new Date(date);
    return {
      dateRange: null,
      isRange: false,
      singleDate:{
      date: {
        year: moment.utc(temp).year(),
        month: moment.utc(temp).month() + 1,
        day: moment.utc(temp).date()
      },
      formatted: `${moment.utc(temp).year()}-${moment.utc(temp).month() + 1}-${moment.utc(temp).date()}`
    }
  }
  }
}