<div class="multi-select">
  <div class="multiselect" [ngClass]="{'disabled': settings.disabled}" (click)="openMultiselect(); false;">
    <span class="placeholder" *ngIf="selecteds.length == 0">{{settings?.placeholder}}</span>
    <span class="tag" *ngFor="let tag of selecteds" (click)="remove($event, tag)">{{tag[settings.property]}} <i
        class="icon-close" *ngIf="!settings.disabled"></i></span>
        <!-- <input type="text" value="{{selecteds}}"> -->
  </div>
  <div class="list" *ngIf="openList">
    <input type="text" placeholder="Buscar" [formControl]="search">
    <ul class="items">
      <li class="item check-box" *ngFor="let option of optionsShow; let i = index">
        <input id="chkItem_{{i}}" type="checkbox" [(ngModel)]="option.checked" (change)="select(option);">
        <label for="chkItem_{{i}}">
          <span></span>
          {{option.item[settings.property]}}
        </label>
      </li>
    </ul>
  </div>
</div>