<h3 class="text-center tittle-group" >Grupo: {{namegroup}}</h3>  
<div *ngIf="listuser&&listuser.length>0">
  <button class="status-inactive" (click)="deletealluser()">Eliminar todos los usuarios</button>
</div>
<table #table>
    <thead>
      <tr >
        <th class="text-center">Nombre</th>
        <th class="text-center">Correo</th>
        <th class="text-center">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of listuser">
        <td class="text-center">{{item.user.username}}</td>
        <td class="text-center">{{item.user.email}} </td>
        <td class="text-center " >
            <div class="edit d-flex-discount">
              <a  class="icon-delete m-auto" (click)="DeleteUser(item.id,item.user.username)"> </a>
            </div>
          </td>  
      </tr>
    </tbody>
  </table>