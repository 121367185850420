<div class="actions">
  <a class="link-a" title="Agregar Grupo de Descuentos" routerLink="/dashboard/group-discount/add">
    <i class="icon-plus"></i>
  </a>
</div>
<div class="filter" [ngClass]="{ show: status, close: close }">
  <form action="" [formGroup]="frmFilters" autocomplete="off" (ngSubmit)="searchvalue()">
    <fieldset>
      <legend>Búsqueda por Nombre</legend>
      <ul class="row">
        <li class="col-6">
          <label for="txtName">Nombre</label>
          <input type="text" name="name" id="txtName" formControlName="name">
        </li>
        <li class="col-6">
          <p>
            <a class="btn-default clear" (click)="clearsearch()">Limpiar</a>
            <span [class.loading]="sending">
              <button type="submit" class="btn-default">
                Buscar
              </button>
            </span>
          </p>
        </li>
      </ul>
    </fieldset>
  </form>
</div>
<table #table>
  <thead>
    <tr>
      <th class="text-center">Nombre</th>
      <th class="text-center">Descuento</th>
      <th class="text-center">Usuarios B2C</th>
      <th class="text-center">Acciones</th>
      <th class="text-center">Habilitar / Deshabilitar</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of groups; let indice=index;">
      <td class="text-center">{{item.name}}</td>
      <td class="text-center">{{item.discount}}</td>
      <td class="text-center edit" *ngIf="item.totalUsers>0; else sinusers"><span class="text-orange">{{item.totalUsers}}</span> <a class="text-center w-100  link"  routerLink="/dashboard/group-discount/list-userb2c/{{item.id}}">Ver usuarios </a> </td>
      <ng-template #sinusers> <td class="text-center no-item">Sin usuarios</td> </ng-template>
      <td class="text-center">
       <div class="edit d-flex-discount">
          <a  *ngIf="item.owner==this.session.userId" class="icon-edit m-auto"  routerLink="/dashboard/group-discount/update/{{item.id}}">
          </a>
          <a   *ngIf="item.owner==this.session.userId" class="icon icon-users-1 m-auto"  routerLink="/dashboard/group-userb2c/{{item.id}}">
          </a>
       </div>
      </td>
      <td class="text-center">
        <div class="form-group">
          <input type="button" *ngIf="item.status==true; else elsestatus;" id="users-{{indice}}" readonly="readonly" (click)="chagestatus(item.id,item.status)" class="status-inactive" value="Deshabilitar">
          <ng-template #elsestatus><input type="button"  id="users-{{indice}}" readonly="readonly" (click)="chagestatus(item.id,item.status)" class="status-active" value="Habilitar"> </ng-template>
        </div>
      </td>
    </tr>
  </tbody>
</table>

