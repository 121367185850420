<form action="" class="form-content" autocomplete="off" [formGroup]="frmElement" (submit)="onSubmit()" *ngIf="showpage==true">
    <div class="form-group col-5 p-10" formGroupName="discountGroup">
      <label for="name">Ingrese el nombre del grupo *</label>
      <input type="text" name="name" id="name" formControlName="name">
    </div>
    <div id="Validity" class="row p-10 ">
        <div class="col-6 pl-3">
            <div class="form-group input-box-container" formGroupName="date">
              <label for="validity">Vigencia del descuento *</label>
              <div class="row">
               <div class="col-5">
                  <input type="text"  placeholder="fecha de inicio" class="input-box " formControlName="inidate" name="maindate" id="maindate"  (click)="dinput.toggleCalendar()"  angular-mydatepicker [options]="myOptions" readonly #dinput="angular-mydatepicker" (dateChanged)="onDateInputChanged($event)">
               </div>
               <div class="col-5">
                  <input type="text"  placeholder="fecha fin" class="input-box  col-6 ml-3" name="enddate" id="enddate" formControlName="findate"  (click)="doutput.toggleCalendar()"  angular-mydatepicker [options]="myOptions2" [defaultMonth]="defaultMonth" readonly #doutput ="angular-mydatepicker" (dateChanged)="onDateoutputChanged($event)">
               </div>
              </div>
            </div>
        </div>
    </div>
      <div id="schedule" class="row mb-3">
        <div class="col-12">
          <div class="form-group pl-2 col-5" formGroupName="schedule">
            <label for="schedule">Horario en que aplica</label>
            <div class="row">
              <input type="button" value="24 horas" [className]="schedule24?'bg-schedule' : ''" (click)="changeschedule('all')">
              <input type="button" value="Asignar Horario" [className]="scheduleany?'bg-schedule ml-2' : 'ml-2'" (click)="changeschedule('any')">
            </div>
            <ul class="row" *ngIf="schedule24!=true">
              <p>Inicio</p>
              <li class="col-3">
                <select name="starthour" id="cboHourStart" formControlName="starthour" (change)="changehourend()">
                  <option *ngFor="let hour of hours; let index = i" value="{{hour}}">{{hour}}</option>
                </select>
              </li>
              <li class="col-2">
                <label for="starthour">Hora</label>
              </li>
              <li class="col-3">
                <select name="startminute" id="startminute" formControlName="startminute" >
                  <option *ngFor="let minute of minutes; let index = i" value="{{minute}}">{{minute}}</option>
                </select>
              </li>
              <li class="col-2">
                <label for="startminute">Minutos</label>
              </li>
            </ul>
            <ul class="row" *ngIf="schedule24!=true">
              <p>Fin</p>
              <li class="col-3">
                <select name="endhour" id="endhour" formControlName="endhour">
                  <option *ngFor="let hour of hoursend; let index = i" value="{{hour}}">{{hour}}</option>
                </select>
              </li>
              <li class="col-2">
                <label for="endhour">Hora</label>
              </li>
              <li class="col-3">
                <select name="endminute" id="endminute" formControlName="endminute" >
                  <option *ngFor="let minute of minutesend; let index = i" value="{{minute}}">{{minute}}</option>
                </select>
              </li>
              <li class="col-2">
                <label for="cboMinuteStart">Minutos</label>
              </li>
            </ul>
          </div>
         <div class="col-6 ">
           <label class="pl-4">Días de la semana</label>
            <div class=" pl-4 row ">
                <input type="button" value="Todos los días"  [className]="dayall?'bg-schedule' : ''" (click)="changesdays('all')">
              <input type="button" value="Asignar días" [className]="dayselect?'bg-schedule ml-2' : 'ml-2'" (click)="changesdays('select')">
            </div>
                <div id="days" class="pl-4 d-flex mt-1" *ngIf="dayall!=true">
                  <div class="check-box" *ngFor="let day of days let i = index" >
                    <input type="checkbox"  id="chkStatus{{i}}" [checked]="day.checked"  (click)="changestatus(day);">
                    <label for="chkStatus{{i}}">
                      <span></span>
                    </label><br>
                    <span class="status-active">{{day.key}}</span>
                  </div>
                </div>
         </div>
      </div>
      </div>
    <div class="type-gas row" formGroupName="discountGroup">
      <p class="text-d ">Descuento general *</p>
      <div class="col-3 form-group">
        <div class="mt-2  mb-1">
          <input type="text" name="regular" id="regular" placeholder="Descuento" formControlName="discount">
        </div>
        <p class="link-collapse  mt-2 p-10"  [collapse]="discountbytype">
          Descuento por tipo de gasolina
         </p>
      </div>
    </div>
    <div  [className]="typefuel==true?'type-gas mt-2 div-groups show' : 'type-gas mt-2 div-groups '"  collapseContent #discountbytype="collapseContent">  
        <div class="ml-1 row">
          <p class="text-d col-6">Descuento por litro</p>
          <p class="col-6 edit"> <a class="icon-delete m-auto deletefuel delete-all" (click)="deletedalliscountypefuel()"></a></p>
        </div>
        <div class=" row " formGroupName="discountPerLiter">
            <div class="col-3 form-group">
              <div class="pd-d">
                <p class="text-d">Regular</p>
                <div class="edit d-flex">
                  <input type="text" name="regular" id="regular" placeholder="Descuento" formControlName="regular">
                  <a  *ngIf="idfuelregular['id']" class="icon-delete m-auto deletefuel" (click)="deletediscountypefuel(idfuelregular)"></a>
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <div class="pd-d">
                <p class="text-d">Premium</p>
               <div class="edit d-flex">
                  <input type="text" name="premium" id="premium" placeholder="Descuento" formControlName="premium">
                  <a *ngIf="idfuelpremium['id']" class="icon-delete m-auto deletefuel" (click)="deletediscountypefuel(idfuelpremium)"></a>
                </div>
              </div>
            </div> 
            <div class="col-3 form-group">
              <div class="pd-d">
                <p class="text-d">Diésel</p>
                <div class="edit d-flex">
                  <input type="text" name="disel" id="disel" placeholder="Descuento" formControlName="diesel">
                  <a *ngIf="idfueldiesel['id']" class="icon-delete m-auto deletefuel" (click)="deletediscountypefuel(idfueldiesel)"></a>
                </div>
              </div>
            </div>
            <div class="col-3 form-group">
              <div class="pd-d">
                <p class="text-d">Diésel Marino</p>
              <div class="edit d-flex">
                  <input type="text" name="disel" id="disel" placeholder="Descuento" formControlName="dieselmarino">
                  <a *ngIf="idfueldieselmarino['id']" class="icon-delete m-auto deletefuel" (click)="deletediscountypefuel(idfueldieselmarino)"></a>
                </div>
              </div>
            </div>
        </div>
        <div class="mt-3 mb-2">
          <p *ngIf="idelement&&this.frmElement.controls.ObjectPerLiter.value==false" class="link-save btn-discount-liter   p-10" (click)="AddDiscountPerLite()">Guardar</p>
        </div>
    </div>
    <div id="discountbygroups" class="row" *ngIf="!idelement">
      <div class="form-group col-5 p-10" >
        <admin-gulf-multiselect-discount [items]="stations" [settings]="{property: 'name', selected: frmElement.controls.stationsselected.value,propertySelected: 'id',placeholder: 'Asociar estaciones de servicio',selecteddiscount:this.frmElement.controls.stationsdb.value}" (stations)="GetStationSelected($event)" (click)="getStations()"></admin-gulf-multiselect-discount>
      <input type="hidden" name="stationsselected" id="stationsselected" formControlName="stationsselected">
      </div>
      <div class="form-group col-5 p-10">
            <admin-gulf-multiselect-default [items]="groups" [settings]="{property: 'name',selected: frmElement.controls.groupsselected.value,propertySelected: 'id',placeholder: 'Asociar grupo de estaciones'}" (stations)="GetGroupsSelected($event)"></admin-gulf-multiselect-default>
            <input type="hidden" value="" formControlName="groupsselected" name="groupsselected" id="groupsselected" >
          </div>
    </div>
    <div class="row" *ngIf="idelement"> 
      <p class="link-collapse w-100" [collapse]="estaciondiscount">
       Estaciones de descuento
      </p>
      <div collapseContent #estaciondiscount="collapseContent" [className]="groupsbystationscollapse==true?'div-groups show' : 'div-groups '">
        <div class="stations row ">
          <div class="list-stations-and-groups col-5 p-div">
            <p class="text-ornage">Listado de estaciones del grupo actualmente</p>
            <ul *ngIf="groupstations && groupstations.discountGroupByStations.length>0">
              <li *ngFor="let item of groupstations.discountGroupByStations;let indice=index;" >
                <div class="associate">
                  <div class="associate-name text-ornage">
                    {{item.name}}
                    
                  </div>
                  <div class="trash" (click)="deleteStationGroup(item.id)">
                    <i class="icon-close"></i>
                  </div>
                  <div>
                    
                  </div>
                </div>
                <div class="discount mt-2 ">
                  <p class="text-d">Descuento</p>
                  <input type="text" value="{{item.discountByFuelType.discount}}" id="discount-{{indice}}" >
                </div>
                <p class="link-save w-100"  (click)="updatediscount(indice,item)">
                  Actualizar
                </p>
              </li>
              
            </ul>
          </div>
          <div class="div-groups col-5 p-div">
            <div class="stations row ">
              <div class="list-stations-and-groups">
                <p class="text-ornage">Agregar estacion de descuento</p>
                <admin-gulf-multiselect-discount [items]="stations" [settings]="{property: 'name', propertySelected: 'id',placeholder: 'Asociar estaciones de servicio',selecteddiscount:this.frmElement.controls.stationsdb.value}" (stations)="GetStationSelected($event)"></admin-gulf-multiselect-discount>
                <p class="link-save w-100" *ngIf="this.frmElement.controls.stations.value.length>0"(click)="adddiscountStations()" >
                   Guardar
                 </p>
              </div>
            </div>
        </div>
        </div>
    </div>
    <p class="link-collapse w-100 mt-2" [collapse]="groupdiscount">
      Grupo de estaciones
     </p>
     <div collapseContent #groupdiscount="collapseContent"  [className]="groupsbygroupsstationcollapse==true?'div-groups show' : 'div-groups '">
      <div class="stations row ">
        <div class=" col-5 p-div">
          <p class="text-ornage">Listado de grupo de estaciones actualmente</p>
          <ul *ngIf="groupsbygroupsstation && groupsbygroupsstation.discountGroupByGroups">
            <li *ngFor="let groups of groupsbygroupsstation.discountGroupByGroups; let o=index;" class="n-pb">
              <p class="edit bg-stations">
                {{groups.group.name}}
                <span class="trashgroup float-right" (click)="deletebygroups(groups.id)"><a  class="icon-delete m-auto"></a></span>
              </p>
          </li>
          </ul>
        </div>
        <div class="div-groups col-5 p-div">
          <div class="stations row ">
            <div class="list-stations-and-groups">
              <p class="text-ornage">Agregar grupo de estaciones</p>
              <admin-gulf-multiselect-default [items]="groups" [settings]="{property: 'name',propertySelected: 'id',placeholder: 'Asociar Grupo de estaciones'}" (stations)="GetGroupsSelected($event)"></admin-gulf-multiselect-default>
            <input type="hidden" value="" formControlName="groupsselected" name="groupsselected" id="groupsselected" >
              <p class="link-save w-100" *ngIf="this.frmElement.controls.groupsselected.value&&this.frmElement.controls.groupsselected.value.length>0"(click)="addgroupstations()" >
                 Guardar
               </p>
            </div>
          </div>
      </div>
      </div>
  </div>
    </div>
    
  <div class="term-conditions mt-3" formGroupName="discountGroup">
    <p class="text-d pl-2">Ingrese sus términos y condiciones *</p>
   <div class="">
      <textarea class="form-control " aria-label="With textarea" formControlName="termConditions"></textarea>
   </div>
    <div id="days" class="pl-2 d-flex mt-4">
      <div class="check-box"  >
        <input type="checkbox"  id="chkStatus" (change)="changeStatus( )" [checked]="active" >
        <label for="chkStatus">
          <span></span>
        </label>
        <span class="status-active ml-2 text-blue">Activo</span>
      </div>
    </div>
    <div id="days" class="pl-2 d-flex mt-4">
      <div class="check-box"  >
        <input type="checkbox"  id="chkmail" (change)="changeStatusSendMail( )" [checked]="activeMail" >
        <label for="chkmail">
          <span></span>
        </label>
        <span class="status-active ml-2 text-blue">Habilitar envios de correo</span>
      </div>
    </div>
  </div>
    <div class="form-actions text-right">
      <span  class="mr-2">
        <input type="submit" value="{{button}}" [disabled]="frmElement.invalid" >
      </span>
      <a class="btn-default cancel" routerLink="/dashboard/group-discount">Cancelar</a>
    </div>
  </form>
  