import { Component, OnInit } from '@angular/core';
import { BaseListComponent } from '../../../../base/list/list.component';
@Component({
  selector: 'cms-gulf-removeruserstations',
  templateUrl: './removeruserstations.component.html',
  styleUrls: ['./removeruserstations.component.scss']
})
export class RemoveruserstationsComponent extends BaseListComponent implements OnInit {

listuser:any;
namegroup:string;
  ngOnInit(): void {
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de usuarios del grupo de estaciones'}
        ],
        title: 'Listado de usuarios del grupo de estaciones'
      }
    );
    this.getUsers();
  }
  
  deletealluser(id=''){
      let users=this.listuser;
    this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿Esta seguro que desea eliminar a todos los usuarios?, se eliminará el grupo por completo',
    'show',
    id => {
      this.service.collection = `user-groups`;
      for(let i in users){
        let user=users[i];
        this.service.remove(user.id).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
            this.modal.hide();
            if(+users.length-1==+i){
              this.getUsers();
            }
        }
        });
      }

    }
    );
  }
  getUsers(){
      let relation={
        "include": [
           {
          "relation": "userGroups",
          "scope": {
            "include": [
              {"relation": "user"}
            
            ]
        }
      },
        {
          "relation": "stationGroups"
        }
      ]
    };
    var userlist=[];
    var usercreator;
      this.service.collection = `groups`;
      if(this.idElement!== undefined){
        this.service.get(this.idElement,'',relation).subscribe(response => {
          this.namegroup=response.name;
          for(var user of response.userGroups){
            if(user.user.id!=this.session.userId){
              userlist.push(user);
            }else{
              usercreator=user.id;
            }
          }
          this.listuser=userlist;
          if(this.listuser.length==0){
            if(this.idElement!=undefined){
              this.removeallgroupstations(this.idElement,usercreator);
            }
          }
        });
      }
    }

    removeallgroupstations(id,usercreator){
      this.service.collection = `groups`;
      var action='/station-groups';
      this.service.update(id,'',action,'DELETE').subscribe(response => {
          if (response===null||!response.hasOwnProperty('success')) {
            this.removeusercreator(usercreator,id);
          }
        });
    }
    removeusercreator(iduser,id){
      this.service.collection = `user-groups`;
      this.service.remove(iduser).subscribe(response => {
        if (response===null||!response.hasOwnProperty('success')) {
          this.removegroup(id);
        }
      });
    }
    removegroup(id){
      this.service.collection = `groups`;
        this.service.remove(id).subscribe(response => {
            if (response===null||!response.hasOwnProperty('success')) {
                   this.router.navigate(["/dashboard/group-stations"]);
                   this.notification.show("Grupo eliminado correctamente","success")
            }
          });
    }
    DeleteUser(id,user){
     this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿Esta seguro que desea eliminar al usuario: '+user+' ?',
    'show',
    id => {
      this.service.collection = `user-groups`;
      this.service.remove(id).subscribe(response => {
        if (response === null || !response.hasOwnProperty('success')) {
          this.getUsers();
          this.modal.hide();
        }
      });
    }
    );
      
    }
}
