import { Component, OnInit } from '@angular/core';
import { ValidateService } from '../../../utils/validate/validate.service';
import { BaseFormComponent } from '../../../base/form/form.component';
import { BreadcrumbService } from '../../common/breadcrumb/service/breadcrumb.service';

@Component({
  selector: 'cms-gulf-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent  extends BaseFormComponent implements OnInit  {
groupsstations;
groupsdiscounts;
users;

 

  ngOnInit(): void {
    this.breadcrumb.links(
      {
        links: [{ label: 'Dashboard', link: '' }],
        title: 'Dashboard'
      }
    );
    this.getUsers();
    this.getGroupDiscount();
    this.getGroupStations();
  }
  getUsers(){
    this.service.collection = 'users/count';
    this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        this.users =response.count;
        
      }
    });
  }
  getGroupDiscount(){
    this.service.collection = 'discounts-groups/count';
    this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        this.groupsdiscounts =response.count;
        
      }
    });
  }
  getGroupStations(){
    this.service.collection = 'groups/count';
    this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        this.groupsstations =response.count;
        
      }
    });
  }

}
