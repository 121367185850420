import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BaseListComponent } from '../../../base/list/list.component';
import * as moment from 'moment';
@Component({
  selector: 'cms-gulf-payments-orders',
  templateUrl: './payments-orders.component.html',
  styleUrls: ['./payments-orders.component.scss']
})
export class PaymentsOrdersComponent extends BaseListComponent implements OnInit {
orders:[];
stations:[];
page:number=1;
@Input() id: string;
@Input() maxSize: number;
@Output() pageChange: EventEmitter<number>;
@Output() pageBoundsCorrection: EventEmitter<number>;
  ngOnInit(): void {
    this.getOrders();
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de órdenes de pago'}
        ],
        title: 'Listado de órdenes de pago'
      }
    );
    this.frmFilters = new FormGroup({
      name: new FormControl(''),
      data: new FormControl(''),
      userslist: new FormControl('')
    });
 
  }
  async getOrders(){
      this.service.collection = 'mercado-pago/order';
      this.service.getAll().subscribe(response => {
        if(!response.hasOwnProperty('success')){
          if(response.length>0){
            response.map(res=>{
              moment.locale('es');
               res["order"]["date"]=moment(res.order.createdAt).format('LL'); 
              });
                this.frmFilters.controls.data.setValue(response);
                this.orders=response;       
          }else{
            this.orders=[];
          }
        }
      });
    }
    searchvalue(){
      var data= this.frmFilters.controls.data.value;
      var valueseach=this.frmFilters.controls.name.value;  
     let newlist;
     newlist=[];
      if(valueseach!=""&&valueseach!=null){
                for(var i in data){
                  if(data[i].order.orderSaleId==valueseach){
                    newlist.push(data[i]);
                  }
                }
         this.orders=newlist;       
      }
    }
    clearsearch(){
      this.frmFilters.controls.name.setValue('');
      if(typeof(this.frmFilters.controls.data)!="undefined"){
        this.orders=this.frmFilters.controls.data.value;
      }
    }
  
}
