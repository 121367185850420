<form action="" class="form-content" autocomplete="off" [formGroup]="frmElement" (submit)="onSubmit()"  >
    <div class="form-group"  formGroupName="group">
      <label for="name">Ingrese el nombre del grupo</label>
      <input type="text" name="name" id="name"  formControlName="name" >
    </div>
    <div class="form-group">
      <div class="row">
       <div class="col-6">
        <div class="form-group">
            <admin-gulf-multiselect-default [items]="stations" [settings]="{property: 'name', selected: frmElement.controls.stations.value,propertySelected: 'id',placeholder: 'Asociar estaciones de servicio',type:'service',iduser:iduser}" (stations)="GetStationSelected($event)" ></admin-gulf-multiselect-default>
          </div>
       </div>
      </div>
     
    </div>
    <div class="form-actions text-right">
      <span  class="mr-2">
        <input type="submit" value="Crear" [disabled]="frmElement.invalid">
      </span>
      <a class="btn-default cancel" routerLink="/dashboard/users">Cancelar</a>
    </div>
  </form>
  