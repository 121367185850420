import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from 'src/app/base/form/form.component';

@Component({
  selector: 'cms-gulf-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent extends BaseFormComponent implements OnInit {
  stations=[];
  StationSelected=[];
  date:string;
  iduser:string;
  button:string;
  allstation=[];
  ngOnInit(): void {
    this.iduser=this.session.userId;
    this.getstationGroups();
    this.getFormateDate();
    this.getElement();
    this.getUserStations();
    this.frmElement = new FormGroup({
      group: new FormGroup({
        name: new FormControl('',[Validators.required]),
        registrationDate: new FormControl(this.date),
        owner: new FormControl(parseInt(this.session.userId)),
      }),
      userStations: new FormControl([ ]),
      // user:new FormControl([]),
      stations: new FormControl('',[Validators.required]),
      // rol: new FormControl('', [Validators.required])
    });
    this.createMessage = 'El grupo se agrego correctamente';
    this.updateMessage = 'Se actualizaron los datos del grupo';
    this.routes = ['dashboard', 'users'];
    this.breadcrumb.links({
      links: [
        { link: '/dashboard/users', label: 'Listado de usuarios'},
        { link: '', label: 'Crear Grupo de Estaciones'}
      ],
      title: 'Crear Grupo de Estaciones'
    });
    
  }
  onSubmit():boolean{
    var data=this.frmElement.value;
    this.service.collection = `groups/user-stations`;
    if(this.idElement === undefined){
      this.service.create(data).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
            this.notification.show(this.createMessage, 'success');
          this.router.navigate(this.routes);
      }
      this.sending = false;
    });
    return false
  }
  }
  verifystations(stations){
  
    let res=this.allstation.map(station=>station).filter(list=>stations.includes(list.stationId));
   let listation=[];
    res.map(namstation=>{
      if(!listation.includes(namstation.name)){
        listation.push(namstation.name);
      }
   });
   if(listation.length>0){
    this.showmessage(listation);
    return false;
   }else{
     return true;
   }
  }
  showmessage(listation){
    this.modallist.show("error","La o las estaciones  pertence a algún grupo",listation,"show");
  }
  getUserStations(){
    let station={};
    let restations=[];
    let stationexist=[];
    this.service.collection = '/user-stations';
    this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        response.map(station=>{
          this.allstation.push(station);
        });
        this.allstation.filter(avaliablestation=>{
            if(avaliablestation.groupId!=null&&avaliablestation.groupId!="null"){
              if(!stationexist.includes(avaliablestation.stationId)){
                stationexist.push(avaliablestation.stationId);
              }
            }
        });
       let list= response.filter(liststation=>!stationexist.includes(liststation.stationId));
         let result=list;
         if(result.length>0){
            result.map(res=>{
            if(res.userId==this.session.userId){
              station={};
              station["id"]=res.stationId;
              station["name"]=res.name;
              station["idg"]=res.id;
              restations.push(station);
            }
          });
        }
          this.stations=restations;
        }
    });
  }
  getstationGroups(){
    
    this.service.collection = '/station-groups';
    this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
          if(response.length>0){
            response.map(station=>{
              this.allstation.push(station);
            })
            
          }
        }
    });
  }
  GetStationSelected($event){

    let arraystation=[];
    let stations={};
    for(var i in $event.selected){
      if($event.selected[i].id!=""&&typeof($event.selected[i].id)!="undefined"){
          arraystation[i]=new Object();
          arraystation[i]["id"]=$event.selected[i].idg;
          arraystation[i]["stationId"]=$event.selected[i].id;
        
      }
    }
    this.StationSelected=arraystation;
    // let group=this.frmElement.get('userStations') as FormArray;
    // this.frmElement.controls.userStations.patchValue(this.StationSelected);
    this.frmElement.controls.userStations.setValue(this.StationSelected);
    this.frmElement.controls.stations.setValue($event.selected.map(s => s.id));

  }

  getElement(){
    let relation={
      "include": [
         {
        "relation": "userGroups",
        "scope": {
          "include": [
            {"relation": "user"}
          
          ]
      }
    },
      {
        "relation": "userStations"
      },
    ]
  };
    this.service.collection = `groups`;
    if(this.idElement !== undefined){
      this.button="Actualizar";
      let station=[];
      let user=[];
      this.service.get(this.idElement,'',relation).subscribe(response => {
        if (!response.hasOwnProperty('success')) {
          var groups=this.frmElement.controls.group as FormGroup;
          groups.patchValue(response);
          for(var i in response.userStations){
              station.push(response.userStations[i].stationId);
          } 
          this.frmElement.controls.stations.setValue(station);
          this.frmElement.addControl('stationsbd',new FormControl(''));
          this.frmElement.controls.stationsbd.setValue(response.userStations);
          this.frmElement.addControl('usersbd',new FormControl(''));
          this.frmElement.controls.usersbd.setValue(response.userGroups);
          this.frmElement.controls.stations.clearValidators();
        this.frmElement.controls.stations.updateValueAndValidity();
          
        }
      });
    }
  }
  getFormateDate(){
    let currendate= new Date();
    var day=''+currendate.getDate();
    var month=''+(currendate.getMonth()+1)
    var hours=''+currendate.getHours();
    var minutes=''+currendate.getMinutes();
    var seconds=''+currendate.getSeconds();
      if(day.length<2){
        day = '0' + day;
      }
      if(month.length<2){
        month = '0' + month;
      }
      if(hours.length<2){
        hours = '0' + hours;
      }
      if(minutes.length<2){
        minutes = '0' + minutes;
      }
      if(seconds.length<2){
        seconds = '0' + seconds;
      }
    let time=hours+":"+minutes+":"+seconds+"."+currendate.getMilliseconds();
        this.date =currendate.getFullYear()+"-"+month+"-"+day+"T"+time+"Z";
  }

  updateStations(){
    let stationserarray=[];
    let stationuserlistDelete=[];
    let stationuserlistAdd=[];
    let updateStation=this.frmElement.controls.stations.value;
    if(typeof(this.frmElement.controls.stationsbd)!="undefined"){
    let stationUser=this.frmElement.controls.stationsbd.value;
      for(var o in stationUser){
        stationserarray.push(stationUser[o].stationId);
      }  
          stationuserlistDelete=this.verifydeleteElementStation(stationUser,updateStation,stationuserlistDelete);
          stationuserlistAdd= this.AddElementStation(updateStation,stationserarray,stationuserlistAdd);
  
  }  

    if(stationuserlistAdd.length>0)
        {
          for(var l in stationuserlistAdd){
            this.AddStationUser(stationuserlistAdd[l]);
          }
        }
        if(stationuserlistDelete.length>0)
        {
          for(var p in stationuserlistDelete){
            this.DeleteStationUser(stationuserlistDelete[p]);
          }
        }
      
  }
  verifydeleteElementStation(ElemntUser,updateElement,listDelete){
    for(var u in ElemntUser){
      if(!updateElement.includes(ElemntUser[u].stationId)){
        listDelete.push(ElemntUser[u].id);
      }
    } 
    return listDelete;
  }
  AddElementStation(updateStation,stationserarray,stationuserlistAdd){
    for(var i in updateStation){
      if(!stationserarray.includes(updateStation[i])){
        for(var n in this.stations ){
          if(this.stations[n].id==updateStation[i]){
         let addstation=[];
         addstation["name"]=this.stations[n].name;
         addstation["stationId"]=updateStation[i];
         addstation["groupId"]=this.idElement;
         stationuserlistAdd.push(addstation);  
        }
       }
      }
    }
    return stationuserlistAdd;
  }
  AddStationUser(body){
    var obj= new Object();
    obj["name"]=body.name;
    obj["stationId"]=body.stationId;
    obj["groupId"]=parseInt(body.groupId);
    this.service.collection = `groups`;
    var action="/station-groups"
    this.service.update(this.idElement,obj,action,'POST').subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
      }
    }
    );
  }
  DeleteStationUser(id){
     
    this.service.collection = `station-groups`;
    this.service.remove(id).subscribe(response => {
      if (response === null || !response.hasOwnProperty('success')) {
      
      }
      this.sending = false;
    });
  }
}
