<h3 class="text-center">Sucursal: {{name}}</h3>
<table #table>
    <thead>
      <tr >
        <th class="text-center">Caja</th>
        <th class="text-center">Empleado</th>
        <th class="text-center">Proveedor</th>
        <th class="text-center">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of pos">
        <td class="text-center" >{{item.details.name}}</td>
        <td class="text-center" >{{item.employee_name}}</td>
        <td class="text-center" >{{item.pos.provider}}</td>
        <td class="text-center ">
            <div class="edit d-flex-discount" >
              <a  class="icon-edit mr-3" (click)="ModalEditPos(item)" > </a>
              <a  class="icon-delete " (click)="deletePos(item.pos.id,item.details.name,pos)" > </a>
            </div>
        </td>  
      </tr>
    </tbody>
  </table>


  <div class="modal {{showEditPos}} taxes">
    <div class="content">
      <div>
        <div class="modal-header">
          <a class="icon-close" (click)="CloseEditPos()"></a>
          <h2>Editar punto de venta (POS)</h2>
        </div>
        <div class="modal-body">
          <form action="" [formGroup]="frmPost" autocomplete="off" (ngSubmit)="EditPost(currentpos)" >
              <div class="information-ticket" id="exportTicket">
             <div class="form-group">
                <label for="">Ingresa el nombre de la caja*</label>
                <input type="text" formControlName="name">
             </div>
             <div>
              
             </div>
            </div>
            <div class="mt-2">
              <button type="submit" >Actualizar</button>
            </div>
          </form>
          </div>
      </div>
    </div>
  </div>