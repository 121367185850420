import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseListComponent } from '../../../base/list/list.component';

@Component({
  selector: 'cms-gulf-details-pos',
  templateUrl: './details-pos.component.html',
  styleUrls: ['./details-pos.component.scss']
})
export class DetailsPosComponent extends BaseListComponent implements OnInit {
pos:[];
name:string;
showEditPos = '';
currentpos = '';
frmPost :FormGroup;

  ngOnInit(): void {
    this.getStore();
    this.getStoreElement();
    this.breadcrumb.links(
      {
        links: [
          { link: '/dashboard/store', label: 'Listado de sucursales'},
          { link: '', label:'Listado de cajas'}
        ],
        title: 'Listado de cajas'
      }
    );
    this.frmPost = new FormGroup({
      name: new FormControl('',[Validators.required]),
  
    });
  }
  getStore(){
    if(this.idElement!=undefined){
      this.service.collection='mercado-pago/pos/store';
      this.service.get(this.idElement).subscribe(response=>{
       this.pos=response;
      });
    }
  }
  getStoreElement(){
    if(this.idElement!=undefined){
      this.service.collection='mercado-pago/stores';
      this.service.get(this.idElement).subscribe(response=>{
       this.name=response.details.name;
      });
    }
  }
  ModalEditPos(pos) {
    this.frmPost.controls.name.setValue(pos.details.name);
    this.currentpos=pos.pos.id;
    if (this.showEditPos === 'show') {
      this.showEditPos= '';
      return false;
    }
    this.showEditPos = 'show';
  }
  async deletePos(idpos='',name='',array=[]){
      this.modal.show(
        idpos,
        'warning',
        `punto de venta`,
        `¿Estas seguro que desea eliminar el punto de venta <b>${name}</b>?`,
        'show',
        idpos => {
          this.service.collection = 'mercado-pago/pos';
          this.service.remove(idpos).subscribe(async response => {
            this.modal.hide();
            if (response === null || response.count === 1) {
              this.notification.show("Se elimino correctamente el punto de venta","success");
              if(array.length==1){
                this.router.navigate(["/dashboard/store"])
              }else{
                this.getStore();
              }
            }else{
              if(response.message){
                this.notification.show(response.message,"error");
              }
            }
          });
        });
  }
  CloseEditPos() {
    this.getStore();
    if (this.showEditPos === 'show') {
      this.showEditPos= '';
      return false;
    }
    this.showEditPos = 'show';
  }
  EditPost(id){
    const data={"name":this.frmPost.controls.name.value};
    this.service.collection = 'mercado-pago/pos';
    this.service.settxt(id,data).subscribe(async response => {
      try{
        let result=JSON.parse(response);
        if(result.message){
          this.notification.show(result.message,"error");
        }
      }catch(e){
        this.notification.show(response,"success");
        await this.getStore();
        this.showEditPos="";
      }
    });
  }
}
