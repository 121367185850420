import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs/observable/timer';
//- Service
import { NotificationService } from './notification.service';
//-
const MODE = {
  OPEN: 'open',
  CLOSE: 'close'
}
@Component({
  selector: 'admin-gulf-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  private timer;
  type: string;
  status: string;
  message: string;
  constructor(
    private notification: NotificationService
  ) { }

  ngOnInit() {
    this.notification.currentMessage.subscribe((response) => {
      this.message = response.message;
      this.type = response.type;
      if (this.message !== '') {
        this.status = MODE.OPEN;
        timer(4000).subscribe(i => this.close());
      }
    });
  }

  close(): void {
    if (this.status !== '') {
      this.status = MODE.CLOSE;
      this.timer = timer(4000).subscribe(i => this.status = '');
    }
  }

}
