import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumb = new BehaviorSubject<any>({ links: [ {label: '', link: '/dashboard/home'} ] });

  values = this.breadcrumb.asObservable();

  constructor() { }

  links(values: { links: any[], title: string }): void {
    this.breadcrumb.next(values);
  }
}
