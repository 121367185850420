import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from '../../../../base/form/form.component';
import * as xlsx from 'xlsx';
const Excel_type='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8';
const excel_ext=".xlsx";
@Component({
  selector: 'cms-gulf-upload-userb2c-file',
  templateUrl: './upload-userb2c-file.component.html',
  styleUrls: ['./upload-userb2c-file.component.scss']
})
export class UploadUserb2cFileComponent extends BaseFormComponent implements OnInit {
  srcResult: any;
  namefile: string;
  example:boolean;
  list:boolean;
  result:string;
  sendingFile:boolean;

  ngOnInit(): void {
    this.sendingFile=false;
    this.example=true;
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Usuarios B2C '}
        ],
        title: 'Carga de Usuarios B2C por Excel'
      }
    );
    this.createMessage = 'Se agrego correctamente los usuarios';
    this.updateMessage = 'Solo se permiten archivos de cálculo';
    this.frmElement = new FormGroup({
      file: new FormControl(this.srcResult,[Validators.required]),
      });
  }
  onSubmit():boolean{
    this.sendingFile=true;
    var data  = this.frmElement.value;
    const formData: FormData = new FormData();
    formData.append('file', this.srcResult, this.srcResult.name);
    this.service.collection = `discounts-groups`;
    let action="/discount-group-users-b2c/upload";
    this.service.update(this.idElement, formData,action,"POST").subscribe(response => {
      this.sendingFile=false;
      if (response === null || !response.hasOwnProperty('success')) {
        if(response.registered){
        this.result=response;
        this.example=false;
        this.list=true;
        if(response.registered.duplicates.total==0&&response.unregistered.total==0&&response.registered.news.total>0){
          // this.notification.show("usuarios agregados correctamente","success");
        } 
        else{
          // this.notification.show("algunos usuarios no fueron agregados correctamente","error");
        }
      }else if(!response.message){
        this.example=true;
        this.list=false;
        this.notification.show("Ha ocurrido un error verifique que el archivo tenga el formato correcto","error");
      }else{
        this.example=true;
        this.list=false;
        this.notification.show(response.message,"error");
      }
    }
      this.sending = false;
    });
    return false

  }
 
  handleLogoFileInput(files: FileList) {
    if(files.length>0){
    this.frmElement.controls.file.setValue(files[0]);
    this.srcResult = files[0];
    this.namefile=files[0].name;
    }
  }
  downloadFile(){
    let json=[{}];
    this.exportExcel(json,"usuarios-agregados",this.result);
  }
  exportExcel(json:any[],excelFileName:string,obj:any){
    let limitcells=0;
    const worksheet: xlsx.WorkSheet= xlsx.utils.json_to_sheet(json);
    //Title cells
    // Value cells
    obj.registered.news.users.map((product,index)=>{
      let cellnum=1+index;
      xlsx.utils.sheet_add_aoa(worksheet, [[product.email]], {origin: `A${cellnum}`});
    xlsx.utils.sheet_add_aoa(worksheet, [[product.name]], {origin: `B${cellnum}`});
    limitcells=cellnum;
    });
    const workbook: xlsx.WorkBook={Sheets:{'Usuarios agregados':worksheet},SheetNames:['Usuarios agregados']};
    const excelbuffer:any=xlsx.write(workbook,{bookType:'xlsx',type:'array'});
    this.saveexcel(excelbuffer,excelFileName);
  }

  saveexcel(buffer:any,filename:string){
    const data: Blob= new Blob([buffer],{type:Excel_type});
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(data);
      a.download = filename+excel_ext; 
      a.click(); //for download 
  }

}
