import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// Handler Service Login
import { AuthService } from './auth/auth.service';
// Handler Session
import { SessionService } from '../../utils/session/session.service';
// Notification Service
import { NotificationService } from '../../common/notification/notification.service';
// CONFIG
import { CONFIG } from '../../gulf';
import { BaseService } from 'src/app/base/base.service';
@Component({
  selector: 'admin-gulf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public frmLogin: FormGroup;

  public sending = false;
  roles=[];
  constructor(
    private session: SessionService,
    private auth: AuthService,
    private router: Router,
    private notification: NotificationService,
    private service: BaseService,
  ) { 
    
  }

    ngOnInit() {
     if ( this.session.inSession()) {
          this.verifydatauser();
     }else{
       this.roles=null;
      this.session.destroy();
     }
    this.frmLogin = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
   
  }
 
   verifydatauser(){
    var iduser= this.session.userId;
    let relation={
      "include": [
        {
          "relation": "userRoles",
          "scope": {
            "include": [
              {"relation": "role"}
            
            ]
        }
      }, {
        "relation": "userStations"
      }
    ]
  };
    if(this.session.token!=""&&iduser!=""){
      var collection="users";
      this.service.collection = 'users';
      this.service.get(iduser,collection,relation).subscribe( response => {
        if(response.success === undefined){
            if(response["userRoles"]){
                var rol=[];
              for(var i in response["userRoles"]){
                      if(!rol.includes(response["userRoles"][i].role.name)){
                        rol.push(response["userRoles"][i].role.name);
                      }
                    }
                    if(rol.length==1){
                            if(rol.includes("GROUPDISCOUNT")){
                              this.router.navigate(['/dashboard/group-discount']);
                            }
                            if(rol.includes("ADMIN")){
                              this.router.navigate(['/dashboard/users']);
                            }
                        }
                        else if(rol.length>1){
                          this.router.navigate(['/dashboard/home']);
                        }
                      else{
                        this.roles=null;
                        this.session.destroy();
                      }
                
            }else{
              this.roles=null;
              this.session.destroy();
            }
        }else{
          this.roles=null;
          this.session.destroy();
        }
        
      })
    
  }else{
    this.session.destroy();
       this.roles=null;
  }
}
  onSubmit() {
    this.sending = true;
    this.auth.login(this.frmLogin.value).subscribe( response => {
      this.sending = false;
      if(response.success === undefined){
        const Token = response.token;
        if(Token !="" && Token !== null){
          this.session.create(response);
        }else{
          this.notification.show('El usuario no tiene permisos necesarios para iniciar sesión', 'error');
          this.sending = false;
          this.frmLogin.reset();
        }
      }
    });
  }

}
