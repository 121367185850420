

<div class="table mt-4">
  <h3 class="text-center">Estaciones disponibles</h3>
  <button type="button " class="w-100" [collapse]="indicegroups2">Ver estaciones</button>
      <div collapseContent #indicegroups2="collapseContent" class="div-groups">
        <div class="stations  form-content" >
                <p class="text-ornage">Estaciones:</p>
                <div class="row" *ngIf="stations.length>0;">
                  <div class="col-12" >
                        <ul class="no-list-style">
                          <li  class="row mt-2" *ngFor="let station of stations; let o=index;" >
                                 <div class="col-8">
                                   <p class="edit bg-stations">
                                     - {{station.name}}
                                     <span class="trashgroup float-right" ><a  class="icon-delete m-auto"></a></span>
                                   </p>
                                 </div>
                                 <div class="col-4 form-group text-center">
                                   <a     routerLink="/dashboard/store/add/{{station.stationId}}?" class="status-active" > Crear sucursal</a>   
                                  </div>
                             </li>
                        </ul>
                  </div>
                  
                </div>
              </div>
          </div>
</div>