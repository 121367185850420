import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'cms-gulf-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
@Input() listrole:string;
constructor() { }

ngOnInit(): void {
  }

}
