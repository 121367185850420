<main class="row-grid h-100">
  <aside class="col-3-aside brand-color p-relative">
    <cms-gulf-aside></cms-gulf-aside>
  </aside>
  <section class="col-9">
    <div class="content">
      <cms-gulf-breadcrumb></cms-gulf-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </section>
</main>
