<div class="multi-select">
  <div class="multiselect" [ngClass]="{'disabled': settings.disabled}" (click)="openMultiselect(); false;">
    <span class="placeholder" >{{settings?.placeholder}}</span>
  </div>
  <div class="list" *ngIf="openList">
    <input type="text" placeholder="Buscar" [formControl]="search">
    <ul class="items">
      <li class="item check-box" *ngFor="let option of userslist; let i = index">
        <input id="chkItem_{{i}}" type="checkbox" [(ngModel)]="option.checked" (change)="select(option);">
        <label for="chkItem_{{i}}">
          <span></span>
          {{option.item[settings.property]}}
        </label>
      </li>
      <li *ngIf="userslist.length==0&&type=='service'&&iduser!=''" class="text-center mt-2 text-red">Sus estaciones ya pertenecen a un grupo</li>
      <li class="text-center" *ngIf="userslist.length==0&&type=='service'&&iduser!=''"><a href="/dashboard/users/update/{{iduser}}" class="text-center status-active">Agregar más estaciones</a></li>
    </ul>
  </div>
</div>
<div class="list-stations-and-groups">
  <ul>
    <li *ngFor="let tag of selecteds" >
      <div class="associate">
        <div class="associate-name">
          {{tag[settings.property]}}
        </div>
        <div class="trash">
          <i class="icon-delete" (click)="remove($event, tag)"></i>
        </div>
      </div>
    </li>
  </ul>
</div>