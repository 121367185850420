<h2 class="text-center">Por favor Introduzca su nueva contraseña</h2>
<form class="form-content" autocomplete="off" [formGroup]="frmElement"  (submit)="onSubmit()">
    <div class="form-group">
        <label for="name">Ingrese su nueva contraseña</label>
        <input type="password" name="password" id="password" formControlName="password">
      </div>
      <div class="form-actions text-right mt-3">
        <span [class.loading]="sending" class="mr-2">
          <input type="submit" value="Actualizar" [disabled]="frmElement.invalid">
        </span>
      </div>
</form>