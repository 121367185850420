<div class="content">
    <div  class="header  text-center"><img _ngcontent-pmu-c46="" src="/assets/images/gulf-logo-cms.svg" alt="GULF"></div>
    <section class="main">
        <div class="check">
            <h2 class="text-center mt-4">La autorización y sincronización con mercado pago fue completada satisfactoriamente</h2>
            <div  alt="" class="checked"></div>
            <div class="text-center mt-3"><p class=""><a href="https://www.gulfmexico.mx/" class="c-gulf mr-3"> Ir a Gulf México</a></p></div>
        </div>
    </section>
    <div class="footer">
        <hr class="mt-4">
        <div class="text-center c-white" ><p><span style="color:inherit;font-size:16px;">Gulf México © 2021. All rights reserved</span><br></p></div>
    </div>
</div>
