import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// Handler service
import { RequestService } from '../../../request/request.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private requestService: RequestService
  ) { }

  login(credentials: Object):Observable<any>{
    return this.requestService.execute('auth/login', credentials, 'POST').pipe(
      tap(response =>{
        if(response.success === undefined){
          if(response.hasOwnProperty('access')){
            this.requestService.updateHeaders('Autorization', response['access']['token']);
          }
        }
      })
    );
  }
}
