import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../common/breadcrumb/service/breadcrumb.service';
import { BaseFormComponent } from '../../../base/form/form.component';
import { BaseListComponent } from '../../../base/list/list.component';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { getgroups } from 'process';
@Component({
  selector: 'cms-gulf-group-stations',
  templateUrl: './group-stations.component.html',
  styleUrls: ['./group-stations.component.scss']
})
export class GroupStationsComponent extends BaseListComponent implements OnInit {
  title="Lista de Estaciones";
  listgroups;
  users;
  iduser:number;

  ngOnInit(): void {
    this.iduser=+this.session.userId;
    this.breadcrumb.links(
      {
        links: [
          { link: '', label: 'Listado de Estaciones'}
        ],
        title: 'Listado de Estaciones'
      }
    );
    this.frmFilters = new FormGroup({
      name: new FormControl(''),
      data: new FormControl(''),
      userslist: new FormControl('')
    });
    this.GetUsers();
   
  }
  searchvalue(){
    var data= this.frmFilters.controls.data.value;
    var valueseach=this.frmFilters.controls.name.value;  
   let newlist= []
    if(valueseach!=""&&valueseach!=null){
              for(var i in data){
                if(data[i].name.includes(valueseach)){
                  newlist.push(data[i]);
                }
              }
       this.listgroups=newlist;       
    }
  }
  clearsearch(){
    this.frmFilters.controls.name.setValue('');
    if(typeof(this.frmFilters.controls.data)!="undefined"){
      this.listgroups=this.frmFilters.controls.data.value;
    }
  }
  private GetUsers(){
    
    this.service.collection = 'users';
    this.service.getAll().subscribe(response => {
      if(!response.hasOwnProperty('success')){
        this.frmFilters.controls.userslist.setValue(response);
        this.getGroups();
      }
    });
  }
  getGroups(){
    var listuser=this.frmFilters.controls.userslist.value;
    this.service.collection = 'groups';
    let stations=[];
    let users=[];
    let roluser=[];
    this.service.getAll().subscribe(response => {
      for(var user in listuser){
        if(listuser[user].id==this.session.userId){
          for(var listrol in listuser[user].userRoles){
              if(!roluser.includes(listuser[user].userRoles[listrol].role.name)){
                    roluser.push(listuser[user].userRoles[listrol].role.name)
              }
          }
        }
      }
      let role=roluser;
      for(var i in response){
          stations=[];
          users=[];
        moment.locale('es');
        response[i].registrationDate= moment(response[i].registrationDate).format("LL");
        if(role.length>1&&role.includes("GROUPDISCOUNT")){
          if(response[i].default&&response[i].default==true){
            for(var p in response[i].defaultStationGroups){
              stations.push(response[i].defaultStationGroups[p].name);
            }
          }else{
            for(var o in response[i].stations){
              stations.push(response[i].stations[o].name);
            }
          }
          if(response[i].user){
            if(!users.includes(response[i].user.name)){
              users.push(response[i].user.name);
            }
         }
      }
      else if(role.includes("ADMIN")&&!role.includes("GROUPDISCOUNT")){
        for(var o in response[i].stations){
          stations.push(response[i].stations[o].name);
          
        }
       if(response[i].user){
          if(!users.includes(response[i].user.name)){
            users.push(response[i].user.name);
          }
       }
      }
      response[i].user=users.toString();
      response[i].stations=stations.toString();
      }
      this.listgroups=response;
      this.frmFilters.controls.data.setValue(response);
      });
  }
  validategroup(id){
    let relation={
      "include": [
         {
        "relation": "userGroups",
        "scope": {
          "include": [
            {"relation": "user"}
          
          ]
      }
    },
      {
        "relation": "stationGroups"
      }
    ]
  };
  var userlist=[];
  var usercreator;
    this.service.collection = `groups`;
    if(id!== undefined){
      this.service.get(id,'',relation).subscribe(response => {
        for(var user of response.userGroups){
          if(user.user.id!=this.session.userId){
            userlist.push(user);
          }else{
            usercreator=user.id;
          }
        }
        response.userGroups=userlist;
        this.deletegroup(id,response,usercreator);      
      });
    }
  }
  deletegroup(id,datos,usercreator){
    var users;
      if(id==datos.id){
        if(datos.userGroups.length>0){
          users=[];
          for(var a in datos.userGroups){
              users.push(datos.userGroups[a].user.username)
          }
          users.accesslist=true;
          users.id=id;
          this.modallist.show("error","Debe eliminar primero a todos los usuarios",users,"show");
          return false;
        }     

    this.modal.show(
      id,
      'warning',
    'Estatus',
    '¿Esta seguro que desea eliminar el grupo '+datos.name+'?',
    'show',
    id => {
      this.removeallgroupstations(id,usercreator);
    }
     );
    }
  }
  removeallgroupstations(id,usercreator){
    this.service.collection = `groups`;
    var action='/station-groups';
    this.service.update(id,'',action,'DELETE').subscribe(response => {
        if (response===null||!response.hasOwnProperty('success')) {
        this.modal.hide();
          this.removeusercreator(usercreator,id);
        }
      });
  }
  removeusercreator(iduser,id){
    this.service.collection = `user-groups`;
    this.service.remove(iduser).subscribe(response => {
      if (response===null||!response.hasOwnProperty('success')) {
        this.removegroup(id);
      }
    });
  }
  removegroup(id){
    this.service.collection = `groups`;
      this.service.remove(id).subscribe(response => {
          if (response===null||!response.hasOwnProperty('success')) {
            this.GetUsers();
          }
        });
  }
  listgroupstations(data){
    data=data.split(",");
    this.modallist.show("success","Listado de Estaciones del grupo",data,"show");
  }
}
