import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  msjuserb2c:string;
  private messageSource = new BehaviorSubject<any>({
    action: '',
    title: '',
    message: '',
    show: '',
    idSource: undefined,
    callback: null,
    userb2c:'',
  });

  actionSource = new BehaviorSubject<any>({ action: '' });

  currentMessage = this.messageSource.asObservable();

  currentAction = this.actionSource.asObservable();

  elementBody = document.getElementsByTagName('body')[0];

  constructor() { }

  show(idSource: string, action: string, title: string, message: string, show: string, handler: any,userb2c='false') {
    this.elementBody.className = 'open-modal';
    this.messageSource.next({
      idSource: idSource,
      action: action,
      title: title,
      message: message,
      show: show,
      handler: handler,
      userb2c: userb2c,
    });
  }
  getMessage(){
    return this.msjuserb2c;
  }
  setMessage(message){
    this.msjuserb2c=message;
  }
  hide(className = '') {
    this.elementBody.className = '';
    this.actionSource.next({ action: className, executing: false });
  }
}
