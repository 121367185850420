<h3 class="text-center tittle-group" *ngIf="showpage==true">Grupo: {{namegroup}}</h3>  
  <div class="filter" [ngClass]="{ show: status, close: close }" *ngIf="showpage==true">
    <form action="" [formGroup]="frmFilters" autocomplete="off" (ngSubmit)="searchvalue()">
      <fieldset>
        <legend>Búsqueda por Nombre</legend>
        <ul class="row">
          <li class="col-6">
            <label for="txtName">Nombre</label>
            <input type="text" name="name" id="txtName" formControlName="name">
          </li>
          <li class="col-6">
            <p>
              <a class="btn-default clear" (click)="clearsearch()">Limpiar</a>
              <span [class.loading]="sending">
                <button type="submit" class="btn-default">
                  Buscar
                </button>
              </span>
            </p>
          </li>
        </ul>
      </fieldset>
    </form>
  </div>
  <table #table *ngIf="showpage==true">
    <thead>
      <tr>
        <th class="text-center">Nombre</th>
        <th class="text-center">Correo</th>
        <th class="text-center">Fecha de alta</th>
        <th class="text-center">Acciones</th>
        <th class="text-center">Estatus</th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let item of userb2c; let indice=index;">
        <td class="text-center">{{item.name}}</td>
        <td class="text-center">{{item.email}}</td>
        <td class="text-center">{{item.registrationDate}}</td>
        <th class="text-center"> <div class="edit ">
            <a  class="icon-delete m-auto"  (click)="deleteStationGroup(item.id)">
            </a>
         </div></th>
        <td class="text-center">
          <div class="form-group">
            <input type="button" *ngIf="item.status==true; else elsestatus;" id="users-{{indice}}" readonly="readonly" (click)="chagestatus(item.id,item.status)" class="status-inactive" value="Desactivar">
            <ng-template #elsestatus><input type="button"  id="users-{{indice}}" readonly="readonly" (click)="chagestatus(item.id,item.status)" class="status-active" value="Activar"> </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  
  